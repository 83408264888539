import Down from "assets/icons/buttonDown.svg";
import Up from "assets/icons/buttonUp.svg";
import { FC } from "react";

import styles from "./upAndDownButtons.module.scss";

const UpAndDownButtons: FC<UpAndDownButtonsType> = ({ className, onUp, onDown, value }) => {
	return (
		<div className={[styles.upAndDownButtonsWrapper, className].join(" ")}>
			<span
				data-test-id="OhH7nrhKmPYXGuANI76p0"
				className={[styles.caret, value === "WALLETS_COUNT_ASC" ? styles.isActive : ""].join(" ")}
				onClick={onUp}
			>
				<Up />
			</span>
			<span
				data-test-id="23fUnnLDxnlV2l7RKvuz9"
				className={[styles.caret, value === "WALLETS_COUNT_DESC" ? styles.isActive : ""].join(" ")}
				onClick={onDown}
			>
				<Down />
			</span>
		</div>
	);
};

export default UpAndDownButtons;

type UpAndDownButtonsType = {
	readonly className?: string;
	readonly onUp?: () => void;
	readonly onDown?: () => void;
	readonly value?: "WALLETS_COUNT_DESC" | "WALLETS_COUNT_ASC";
};
