import { FC } from "react";

import styles from "./iDCol.module.scss";

type IDColType = {
	readonly id: string;
};

const IDCol: FC<IDColType> = ({ id }) => {
	return (
		<div data-test-id="9rMEmvb_hijpacIiqsQS9" className={styles.iDCol}>
			{id}
		</div>
	);
};

export default IDCol;
