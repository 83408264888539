import { WalletTypes } from "utils/enums";

const parsingPs = [
	WalletTypes.BKASH,
	WalletTypes.CLICK_UZS,
	WalletTypes.NAGAD,
	WalletTypes.ROCKET,
	WalletTypes.VIETCOM_BANK,
	WalletTypes.EASYPAISA,
	WalletTypes.UPI_HB,
	WalletTypes.UPI_IB,
	WalletTypes.UPI_W,
	WalletTypes.UPI_IDB,
	WalletTypes.UPI_IN,
	WalletTypes.IMPS_HB,
	WalletTypes.IMPS_IB,
	WalletTypes.IMPS_IDB,
	WalletTypes.PAY_TM,
];

export const isParsingPS = (walletType: WalletTypes) => {
	return parsingPs.includes(walletType);
}