import API from "api";
import { Counterparty, WalletItem } from "api/walletGroup";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import ActivatePayTM from "./ActivatePayTM";
import EditableCommentContainer from "./EditableComment";
import ProxiesView from "./ProxiesView";
import { convertIntoCurrency } from "helpers/convertIntoCurrency";
import { Button, Loader, ScrollBox } from "pay-kit";
import React, { useContext, useEffect, useState } from "react";
import Money from "components/Money";

import styles from "./sideModalContent.module.scss";
import { WalletContext } from "modules/Wallets/contexts/WalletContext";
import { ParserType, WalletState } from "modules/Wallets/components/modals/ChangeWalletModal/utils/enums";
import { Roles } from "contexts/AuthContext/utils/enums";
import { WalletCounterpartyContext } from "modules/Wallets/contexts/WalletCounterpartyContext";
import { useCounterparty } from "modules/Counterparty/hooks/useCounterparty";
import CaretIcon from "components/Icons/CaretIcon";
import StatusBadges from "modules/Wallets/components/StatusBadges";
import { priorityMapping } from "modules/Wallets/components/modals/Counterparties/EditCounterpartyModal";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { WalletTypes } from "utils/enums";
import { useAuthContext } from "contexts/AuthContext/AuthContext";

// TODO: refactor messy code

interface SideModalContentType {
	readonly walletId: string | null;
}

type CounterpartyInfoProps = {
	counterparty: Counterparty;
	currencyCode: string;
};

const CounterpartyInfo = ({ counterparty, currencyCode }: CounterpartyInfoProps) => {
	const { counterparties } = useCounterparty({});

	const identifier = counterparties?.find((c) => c.id === counterparty?.counterparty_uuid)?.identifier;

	const [expanded, setExpanded] = useState(true);

	const state: WalletState[] = counterparty?.counterparty_wallet_settings?.status || ([] as WalletState[]);

	const ROWS_MAP = [
		{
			id: "priority",
			title: "Приоритет",
			render: () => <>{priorityMapping[counterparty?.counterparty_wallet_settings?.priority]}</>,
		},
		{
			id: "is_active",
			title: "Состояние",
			render: () => <>{counterparty?.counterparty_wallet_settings?.is_active ? `Рабочий` : `Отключенный`}</>,
		},
		{
			id: "status",
			title: "Статус",
			render: () =>
				counterparty?.counterparty_wallet_settings?.status?.length > 0 ? (
					<div
						style={{
							width: "204px",
						}}
					>
						<StatusBadges state={state} />
					</div>
				) : (
					<>–</>
				),
		},
		{
			id: "plugin_status",
			title: "Видимость",
			render: () => <>{counterparty?.counterparty_wallet_settings?.plugin_status ? `Включена` : `Отключена`}</>,
		},
	];

	const LIMITS_ROWS = [
		{
			id: "wallet_off_load",
			title: "Суточный по сумме",
			render: (value) =>
				value ? <Money direction="ingoing" amount={value} currencyCode={currencyCode} withSign={false} /> : "–",
		},
		{
			id: "daily_transaction_limit",
			title: "Суточный по транзакциям",
		},
		// {
		// 	id: "monthly_turnover_limit",
		// 	title: "Месячный по сумме",
		// },
	];

	const caretIconStyle = !expanded ? styles.caret : [styles.caret, styles.caretUp].join(" ");

	const RowsRender = (MAP: any[]) =>
		MAP.map((row) => {
			const value =
				counterparty?.counterparty_wallet_settings[row.id as keyof Counterparty[`counterparty_wallet_settings`]];

			return (
				<div key={`${row.id}_${row.title}`} className={styles.row}>
					<div className={styles.title}>{row.title}</div>
					<div className={styles.value}>{row.render ? row.render(value) : `${value ? value : "–"}`}</div>
				</div>
			);
		});

	return (
		<div>
			<div className={styles.counterpartiesHeading}>
				<div>{identifier}</div>
				<Button variant="text-secondary" onClick={() => setExpanded((e) => !e)}>
					<CaretIcon className={caretIconStyle} />
				</Button>
			</div>
			{expanded && RowsRender(ROWS_MAP)}
			{expanded && (
				<div className={styles.counterpartylimitsBlock}>
					<h4>Лимиты</h4>
					{RowsRender(LIMITS_ROWS)}
				</div>
			)}
		</div>
	);
};

const SideModalContent: React.FC<SideModalContentType> = ({ walletId }) => {
	const [walletData, setWalletData] = useState<WalletItem | {}>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const state: WalletState[] = [
		...new Set(
			(walletData?.counterparties || [])
				.map((counterparty) => counterparty?.counterparty_wallet_settings?.status || [])
				.reduce((prev, curr) => [...prev, ...curr], [])
		),
	] as WalletState[];

	const sms_source = walletData?.wallet_settings?.sms_source || null;
	const parser_type = walletData?.wallet_settings?.parser_type || null;

	const isActive = (walletData?.counterparties || []).some(
		(counterparty) => counterparty?.counterparty_wallet_settings?.is_active
	);

	const { hasRole } = useAuthContext();

	const { getWallets, openSmsWalletModal, openClearCookieModal } = useContext(WalletContext);

	const { walletTypes } = useContext(WalletTypesContext);

	const { openListCounterpartyModal } = useContext(WalletCounterpartyContext);

	const updateAccess = hasRole(Roles.WALLET_UPDATE);
	const proxyAccess = hasRole(Roles.PROXY_LIST);
	const balanceAccess = hasRole(Roles.WALLET_BALANCE);

	const handleSMS = () => {
		openSmsWalletModal(walletId);
	};

	const handleCookieClear = () => {
		openClearCookieModal(walletId);
	};

	useEffect(() => {
		if (walletId) {
			getWalletData();
		}
	}, [walletId]);

	const getWalletData = () => {
		setIsLoading(true);

		API.wallet
			.getItem(walletId)
			.then((res) => {
				if (res?.status === "success") {
					setWalletData(res?.data);
					return;
				} else {
					throw new Error("Unexpected response");
				}
			})
			.catch((err) => {
				console.error(err);
				window.pushAlert({ description: "Не удалось получить данные о кошельке", type: "error" });
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const updateComment = (comment: string | null) => {
		setWalletData((prevData: any) => {
			return { ...prevData, comment };
		});
		getWallets();
	};

	if (isLoading) {
		return (
			<div className={styles.loader}>
				<Loader color="#A2A9B0" />
			</div>
		);
	}

	if (!walletData || walletId === null) {
		return <div className={styles.noWalletData}>Нет данных о кошельке</div>;
	}

	const ROWS_MAP = [
		{
			id: "type",
			title: "Тип кошелька",
			render: ({ wallet_type_code }: WalletItem) => <PaymentSystemLogo id={wallet_type_code} hideLabel={true} />,
		},
		{
			id: "wallet_type_code",
			title: "Название",
			render: () => <>{walletTypes.find((wt) => wt.code === walletData.wallet_type_code)?.name}</>,
		},
		{
			id: "identifier",
			title: "Номер кошелька",
		},
		{
			id: "hash_id",
			title: "Хеш",
		},
		...(balanceAccess
			? [
					{
						id: "balance",
						title: "Баланс",
						render: ({ balance, currency_code }: WalletItem) => {
							if (currency_code) {
								return (
									<div className={styles.balance}>{`${convertIntoCurrency({ amount: balance })} ${currency_code}`}</div>
								);
							}

							return null;
						},
					},
			  ]
			: []),
		...(proxyAccess
			? [
					{
						id: "proxies",
						title: "Прокси",
						render: ({ proxies }: WalletItem) => <ProxiesView proxies={proxies} />,
					},
			  ]
			: []),
		{
			id: "comment",
			title: "Комментарий",
			render: ({ comment, hash_id }: WalletItem) => (
				<EditableCommentContainer hash_id={hash_id} comment={comment} onSuccess={updateComment} />
			),
		},
		{
			id: "arhivate_date",
			title: "Дата архивации",
			render: ({ removed_at }: WalletItem) => (removed_at ? removed_at : "–"),
		},
	];

	const hasRoleToEditCounterparties = hasRole(Roles.WALLET_UPDATE) || hasRole(Roles.WALLET_COUNTERPARTY_MANAGE);
	const canEditCounterparties = walletData.removed_at === null && hasRoleToEditCounterparties;

	return (
		<div className={styles.wallet}>
			<ScrollBox>
				<div>
					{ROWS_MAP.map((row) => {
						const value = walletData[row.id as keyof WalletItem];

						return (
							<div key={`${row.id}_${row.title}`} className={styles.row}>
								<div className={styles.title}>{row.title}</div>
								<div className={styles.value}>{row.render ? row.render(walletData) : `${value}`}</div>
							</div>
						);
					})}
					{walletData?.counterparties?.length > 0 && (
						<div>
							<div className={styles.counterpartiesSection}>
								<div className={styles.counterpartiesLabel}>
									<div>Контрагенты</div>
									<div className={styles.counterpartiesCounter}>{walletData?.counterparties?.length}</div>
								</div>
								{canEditCounterparties && (
									<Button variant="text-primary" onClick={() => openListCounterpartyModal(walletId)}>
										Редактировать
									</Button>
								)}
							</div>
							{walletData.counterparties.map((c, i) => (
								<CounterpartyInfo counterparty={c} key={i} currencyCode={walletData.currency_code} />
							))}
						</div>
					)}
				</div>
			</ScrollBox>

			<div className={styles.actions}>
				{updateAccess &&
					walletData.wallet_type_code === WalletTypes.PAY_TM &&
					walletData.counterparties.some((c) => c.counterparty_wallet_settings.is_active) &&
					((state || []).includes(WalletState.PENDING_SMS_CONFIRMATION) || parser_type === ParserType.MOBILE_API) &&
					sms_source == "manual_input" && (
						<Button onClick={handleSMS} variant="color" color="orange" textColor="white">
							<div className={styles.label}>Ввести СМС</div>
						</Button>
					)}
				{updateAccess &&
					walletData.wallet_type_code === WalletTypes.PAY_TM &&
					((state || []).includes(WalletState.UPDATE_COOKIES) || (!isActive && walletData.cookie)) && (
						<Button variant="delete" onClick={handleCookieClear}>
							<div className={styles.label}>Сбросить куки</div>
						</Button>
					)}
				{updateAccess && walletData.type === "paytm" && <ActivatePayTM identifier={walletData.identifier} />}
			</div>
		</div>
	);
};

export default SideModalContent;
