import API from "api";
import { GetChangeLogListType } from "api/changeLogGroup";
import { OldResponse } from "api/types";
import { UpdateSettingData, WalletListItem, WalletsFilterForm } from "api/walletGroup";
import CommentModal from "modules/Wallets/components/modals/CommentModal";
import CreateWalletModal from "modules/Wallets/components/modals/CreateWalletModal";
import EditProxies from "modules/Wallets/components/modals/EditProxies";
import EditWalletModal from "modules/Wallets/components/modals/EditWalletModal";
import { ArchiveWalletModal } from "modules/Wallets/DisabledWallets/components/modals/ArchiveWalletModal";
import { ClearCookieModal } from "modules/Wallets/DisabledWallets/components/modals/ClearCookieModal";
import { MassArchiveWalletModal } from "modules/Wallets/DisabledWallets/components/modals/MassArchiveWalletModal";
import SetSMSModal from "modules/Wallets/ActiveWallets/components/modals/SetSMSModal";
import { Modal } from "pay-kit";
import React, { useEffect, useState } from "react";
import styles from "./Wallets.module.scss";

type WorkingWalletContextType = {
	readonly wallets: readonly WalletListItem[];
	readonly getWallets: (params?: Record<any, any>) => void;
	readonly isLoading: boolean;
	readonly updateSetting: (data: UpdateSettingData) => Promise<OldResponse<void>>;
	readonly openArchiveWalletModal: (id: WalletListItem[`hash_id`]) => void;
	readonly openCreateWalletModal: (disabled: boolean) => void;
	readonly openEditWalletModal: (walletId: number) => void;
	readonly openSmsWalletModal: (walletId: WalletListItem[`hash_id`]) => void;
	readonly openClearCookieModal: (walletId: WalletListItem[`hash_id`]) => void;
	readonly openCommentModal: (id: WalletListItem[`hash_id`], comment: string | null) => void;
	readonly openEditProxiesModal: (data: Omit<EditProxiesModalData, "open">) => void;
	readonly openMassArchiveWalletModal: () => void;
	readonly selectedIds: readonly (string | number)[];
	readonly setSelectedIds: React.Dispatch<React.SetStateAction<readonly (string | number)[]>>;
	readonly filter: WalletsFilterForm;
	readonly setFilter: React.Dispatch<React.SetStateAction<WalletsFilterForm>>;
	readonly paginationInfo: GetChangeLogListType["paginate"];
	readonly setWallets: (wallets: readonly WalletListItem[]) => void;
	readonly limit: number;
	readonly setLimit: (limit: number) => void;
	readonly page: number;
	readonly setCurrentPage: (page: number) => void;
	readonly refreshWalletByHashId: (
		walletId: WalletListItem[`hash_id`],
		onSuccess?: (data: WalletListItem) => void
	) => void;
};

const initialState: WorkingWalletContextType = {} as WorkingWalletContextType;

export type ModalData = {
	readonly open: boolean;
};

type WalletModalData = ModalData & {
	readonly id: WalletListItem["hash_id"];
};

type CreateModalData = ModalData & {
	readonly disabled: boolean;
};

type CommentModalData = ModalData & {
	readonly hash_id: WalletListItem["hash_id"];
	readonly comment: string | null;
	readonly plugin_status: boolean;
};

export type EditProxiesModalData = ModalData & {
	readonly id: WalletListItem["hash_id"];
	readonly identifier: WalletListItem["identifier"];
	readonly proxies: WalletListItem["proxies"];
};

export const WalletContext = React.createContext<WorkingWalletContextType>(initialState);
WalletContext.displayName = "WalletContext";

type WalletContextProps = {
	readonly children: React.ReactNode;
	readonly type: "active" | "disabled";
};

export default function WalletContextProvider(props: WalletContextProps) {
	const [wallets, setWallets] = useState<readonly WalletListItem[]>([]);
	const [limit, setLimit] = useState<number>(20);
	const [page, setCurrentPage] = useState<number>(1);
	const [selectedIds, setSelectedIds] = useState<readonly (string | number)[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [paginationInfo, setPaginationInfo] = useState<GetChangeLogListType["paginate"]>(
		{} as GetChangeLogListType["paginate"]
	);
	const [filter, setFilter] = useState<WalletsFilterForm>({});

	useEffect(() => {
		setCurrentPage(1);
	}, [filter, limit]);

	useEffect(() => {
		getWallets();
	}, [filter, limit, page]);

	const [archiveModalData, setArchiveModalData] = useState<WalletModalData>({
		open: false,
		id: `0`,
	});

	const [massArchiveModalData, setMassArchiveModalData] = useState<boolean>(false);

	const [createModalData, setCreateModalData] = useState<CreateModalData>({
		open: false,
		disabled: false,
	});

	const [editModalData, setEditModalData] = useState<WalletModalData>({
		open: false,
		id: ``,
	});

	const [smsModalData, setSmsModalData] = useState<WalletModalData>({
		open: false,
		id: ``,
	});

	const [cookieModalData, setCookieModalData] = useState<WalletModalData>({
		open: false,
		id: ``,
	});

	const [commentModalData, setCommentModalData] = useState<CommentModalData>({
		open: false,
		hash_id: ``,
		comment: null,
		plugin_status: false,
	});

	const [editProxiesModalData, setEditProxiesModalData] = useState<EditProxiesModalData>({
		open: false,
		id: ``,
		identifier: ``,
		proxies: [],
	});

	const getWallets = (params = {}): Promise<OldResponse<readonly WalletListItem[]>> => {
		setLoading(true);

		const walletsFilters = {
			removed: 0,
			is_active: props.type === "active" ? 1 : 0,
			limit,
			offset: (page - 1) * limit,
			...filter,
		};

		return API.wallet
			.getList({ ...walletsFilters, ...params })
			.then((resp) => {
				if (resp.status === "success") {
					setWallets(resp.data);
					setPaginationInfo(resp.paginate);
				}
				return resp;
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const refreshWalletByHashId = (
		walletHashId: WalletListItem["hash_id"],
		onSuccess?: (data: WalletListItem) => void
	) => {
		setLoading(true);

		API.wallet
			.getItem(walletHashId)
			.then((res) => {
				if (res?.status === "success") {
					onSuccess && onSuccess(res.data);

					setWallets((oldWallets: readonly WalletListItem[]) => {
						return oldWallets.map((wallet) => {
							if (wallet.hash_id === walletHashId) {
								return res.data;
							}

							return wallet;
						});
					});
					return;
				} else {
					throw new Error("Unexpected response");
				}
			})
			.catch((err) => {
				console.error(err);
				window.pushAlert({ description: `Не удалось получить данные кошелька ${walletHashId}`, type: "error" });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const attachProxies = (proxy_uuids: string[], wallet_id: string) => {
		return API.wallet.attachProxies(proxy_uuids, wallet_id);
	};

	const detachProxies = (proxy_uuids: string[], wallet_id: string) => {
		return API.wallet.detachProxies(proxy_uuids, wallet_id);
	};

	const updateSetting = (data: UpdateSettingData): Promise<OldResponse<void>> => {
		return API.wallet.updateSetting(data);
	};

	const archiveWallet = (id: WalletListItem["hash_id"], fetchWallets = true): Promise<OldResponse<void>> => {
		return API.wallet.archiveWallet(id).then(async (resp) => {
			if (resp.status === "success" && fetchWallets) {
				await setTimeout(() => {
					getWallets();
				}, 1000);
			}

			return resp;
		});
	};

	const createWallet = (form: any): Promise<OldResponse<void>> => {
		const data = { ...form };
		if (data.upi_addresses) {
			data.upi_addresses = data.upi_addresses.map((upi: any) => upi.upi_address);
		}
		return API.wallet.create(data).then(async (resp) => {
			if (resp.status === "success") {
				await getWallets();
			}

			return resp;
		});
	};

	const updateWallet = (form: any): Promise<OldResponse<void>> => {
		const data = { ...form };
		return API.wallet.update(editModalData.id, data).then(async (resp) => {
			if (resp.status === "success") {
				await setTimeout(() => {
					getWallets();
				}, 1000);
			}

			return resp;
		});
	};

	const setSmsCode = (form: any) => {
		return API.wallet.setSmsCode(smsModalData.id, form).then((resp) => {
			if (resp.status === "success") {
				getWallets();
			}

			return resp;
		});
	};

	const clearCookie = () => {
		return API.wallet.setCookie(cookieModalData.id).then((resp) => {
			if (resp.status === "success") {
				getWallets();
			}

			return resp;
		});
	};

	const setComment = (form: any) => {
		return API.wallet
			.updateSetting({ hash_id: commentModalData.hash_id, ...form, plugin_status: commentModalData.plugin_status })
			.then((resp) => {
				if (resp.status === "success") {
					setTimeout(() => {
						getWallets();
					}, 500);
				}

				return resp;
			});
	};

	const openArchiveWalletModal = (id: WalletListItem["hash_id"]) => {
		setArchiveModalData({
			open: true,
			id,
		});
	};

	const closeArchiveWalletModal = () => {
		setArchiveModalData({
			open: false,
			id: "",
		});
	};

	const openMassArchiveWalletModal = () => {
		setMassArchiveModalData(true);
	};

	const closeMassArchiveWalletModal = () => {
		setMassArchiveModalData(false);
	};

	const openCreateWalletModal = (disabled: boolean) => {
		setCreateModalData({
			open: true,
			disabled,
		});
	};

	const closeCreateWalletModal = () => {
		setCreateModalData({
			open: false,
			disabled: false,
		});
	};

	const openEditWalletModal = (id: WalletListItem["hash_id"]) => {
		setEditModalData({
			open: true,
			id,
		});
	};

	const closeEditWalletModal = () => {
		setEditModalData({
			open: false,
			id: "",
		});
	};

	const openSmsWalletModal = (id: WalletListItem["hash_id"]) => {
		setSmsModalData({
			open: true,
			id,
		});
	};

	const closeSmsWalletModal = () => {
		setSmsModalData({
			open: false,
			id: "",
		});
	};

	const openClearCookieModal = (id: WalletListItem["hash_id"]) => {
		setCookieModalData({
			open: true,
			id,
		});
	};

	const closeClearCookieModal = () => {
		setCookieModalData({
			open: false,
			id: "",
		});
	};

	const openCommentModal = (hash_id: WalletListItem["hash_id"], comment: string | null, plugin_status: boolean) => {
		setCommentModalData({
			open: true,
			hash_id,
			comment,
			plugin_status,
		});
	};

	const closeCommentModal = () => {
		setCommentModalData({
			open: false,
			hash_id: ``,
			comment: null,
			plugin_status: false,
		});
	};

	const openEditProxiesModal = (data: Omit<EditProxiesModalData, "open">) => {
		setEditProxiesModalData({
			...data,
			open: true,
		});
	};

	const closeEditProxiesModal = () => {
		setEditProxiesModalData({
			open: false,
			proxies: [],
			id: ``,
			identifier: ``,
		});
	};

	const value = {
		wallets,
		isLoading,
		updateSetting,
		openCreateWalletModal,
		openEditWalletModal,
		openArchiveWalletModal,
		openMassArchiveWalletModal,
		openSmsWalletModal,
		openClearCookieModal,
		openCommentModal,
		selectedIds,
		setSelectedIds,
		filter,
		setFilter,
		getWallets,
		paginationInfo,
		limit,
		setLimit,
		page,
		setCurrentPage,
		openEditProxiesModal,
		setWallets,
		refreshWalletByHashId,
	};

	return (
		<WalletContext.Provider value={value}>
			{props.children}
			<Modal title="Архивация кошелька" isOpen={archiveModalData.open} onClose={closeArchiveWalletModal}>
				<ArchiveWalletModal
					closeModal={closeArchiveWalletModal}
					id={archiveModalData.id}
					actions={{
						archiveWallet,
					}}
				/>
			</Modal>
			<Modal title="Архивация кошельков" isOpen={massArchiveModalData} onClose={closeMassArchiveWalletModal}>
				<MassArchiveWalletModal
					closeModal={closeMassArchiveWalletModal}
					isOpen={massArchiveModalData}
					ids={selectedIds as readonly WalletListItem[`id`][]}
					actions={{
						clearSelectedIds: () => setSelectedIds([]),
						archiveWallet,
						getWallets,
					}}
				/>
			</Modal>
			<Modal
				title="Создание кошелька"
				isOpen={createModalData.open}
				onClose={closeCreateWalletModal}
				className={styles.changeWalletModal}
			>
				<CreateWalletModal
					closeModal={closeCreateWalletModal}
					isOpen={createModalData.open}
					disabled={createModalData.disabled}
					actions={{
						createWallet,
					}}
				/>
			</Modal>
			<Modal
				title="Редактирование кошелька"
				isOpen={editModalData.open}
				onClose={closeEditWalletModal}
				className={styles.changeWalletModal}
			>
				<EditWalletModal
					closeModal={closeEditWalletModal}
					isOpen={editModalData.open}
					actions={{
						updateWallet,
					}}
					walletId={editModalData.id}
				/>
			</Modal>
			<Modal title="Подтверждение телефона" isOpen={smsModalData.open} onClose={closeSmsWalletModal}>
				<SetSMSModal
					closeModal={closeSmsWalletModal}
					isOpen={smsModalData.open}
					actions={{
						setSmsCode,
					}}
				/>
			</Modal>
			<Modal title="Сброс куки" isOpen={cookieModalData.open} onClose={closeClearCookieModal}>
				<ClearCookieModal
					closeModal={closeClearCookieModal}
					isOpen={cookieModalData.open}
					actions={{
						clearCookie,
					}}
					id={cookieModalData.id}
				/>
			</Modal>
			<Modal title="Комментарий" isOpen={commentModalData.open} onClose={closeCommentModal}>
				<CommentModal
					closeModal={closeCommentModal}
					actions={{
						setComment,
					}}
					comment={commentModalData.comment}
				/>
			</Modal>
			<Modal
				title="Настройка прокси"
				isOpen={editProxiesModalData.open}
				onClose={closeEditProxiesModal}
				className={styles.editProxiesModal}
			>
				<EditProxies
					close={closeEditProxiesModal}
					attach={attachProxies}
					detach={detachProxies}
					data={editProxiesModalData}
				/>
			</Modal>
		</WalletContext.Provider>
	);
}
