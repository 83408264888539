// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlusIcon__plusIcon-n3f{width:16px;height:16px;color:#fff;display:inline-block}.PlusIcon__plusIcon-n3f>svg{width:100%;height:100%;line-height:initial}`, "",{"version":3,"sources":["webpack://./src/components/Icons/PlusIcon/plusIcon.module.scss"],"names":[],"mappings":"AAAA,wBACC,UAAA,CACA,WAAA,CACA,UAAA,CACA,oBAAA,CAEA,4BACC,UAAA,CACA,WAAA,CACA,mBAAA","sourcesContent":[".plusIcon {\n\twidth: 16px;\n\theight: 16px;\n\tcolor: white;\n\tdisplay: inline-block;\n\n\t& > svg {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tline-height: initial;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plusIcon": `PlusIcon__plusIcon-n3f`
};
export default ___CSS_LOADER_EXPORT___;
