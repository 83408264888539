import { proxiesListElemType, useAddingProxiesContext } from "modules/Proxies/contexts/AddingProxiesContext";
import { constructMultiAddingProxiesList } from "modules/Proxies/helpers";
import { Alert, Button, Textarea } from "pay-kit";
import { FC, useState } from "react";

import styles from "./notWorkingProxiesWarning.module.scss";

const formProxiesList: formProxiesListType = (notWorkingProxiesList) => {
	let list = "";
	for (const elem of notWorkingProxiesList) {
		if (elem.inputValue) {
			list += elem.inputValue + "\n";
		}
	}

	return list;
};

const NotWorkingProxiesWarning: FC<NotWorkingProxiesWarning> = ({
	notWorkingProxiesList,
	allChangedDataSaved
}) => {
	const {
		proxiesList,
		initRecheckingMultiAddingProxies,
		isRecheckingMultiAddingProxiesLoading
	} = useAddingProxiesContext();

	const [value, setValue] = useState(formProxiesList(notWorkingProxiesList));
	const reconstructedList = constructMultiAddingProxiesList(value);

	const handleOnclick = () => {
		const validList = reconstructedList.filter((elem) => !elem.isFormatValid);
		const fixedProxies = reconstructedList.filter((elem) => elem.isFormatValid).map(({ proxy }) => {
			const data = {
				...proxy
			};

			data.port = +data.port;
			data.ip = data.ip_host;
			delete data.ip_host;

			return data
		});

		const proxies = proxiesList.filter((elem) => elem.isFormatValid).map(({ proxy }) => {
			const data = {
				...proxy
			};

			data.port = +data.port;
			data.ip = data.ip_host;
			delete data.ip_host;

			return data
		});

		if (fixedProxies.length > 0 || proxies.length > 0) {
			setValue(formProxiesList(validList));

			initRecheckingMultiAddingProxies([...proxies, ...fixedProxies], "Данные обновлены");
		}
	};

	return (
		<div className={styles.notWorkingProxiesWarningWrapper}>
			<div className={styles.proxiesListContentContainer}>
				<div className={styles.invalidFormatMessage}>
					Ошибка формата
				</div>

				<div className={styles.NoteOnLaterAction}>
					Данные которые не прошли проверку и содержат ошибки.
					При дальнейших действиях они не будут добавлены.
				</div>

				<Alert
					className={styles.infoAlertWrapper}
					withIcon={true}
					title="Формат строки - IP:Порт:Логин:Пароль:Тип:Код_страны"
					variant="info"
				/>

				<Textarea
					className={styles.notWorkingList}
					onChange={e =>
						setValue(e.target.value)
					}
					value={value}
					rows={10}
				/>

				<Button data-test-id="rNBurOBWJNISyvSSXvSCM"
					classname={styles.editButton}
					onClick={handleOnclick}
					disabled={isRecheckingMultiAddingProxiesLoading || allChangedDataSaved || !value}
				>
					Отправить на проверку
				</Button>

			</div>
		</div>
	);
};

export default NotWorkingProxiesWarning;

type NotWorkingProxiesWarning = {
	readonly notWorkingProxiesList: readonly proxiesListElemType[]
	readonly allChangedDataSaved: boolean
}

type formProxiesListType = (notWorkingProxiesList: readonly proxiesListElemType[]) => string
