// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filter__filter-Mt3{margin:36px 0 20px 0}.Filter__filter-Mt3 .Filter__field-hvz{flex:1}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/Filter/Filter.module.scss"],"names":[],"mappings":"AAAA,oBACI,oBAAA,CAEA,uCACI,MAAA","sourcesContent":[".filter {\n    margin: 36px 0 20px 0;\n\n    .field {\n        flex: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `Filter__filter-Mt3`,
	"field": `Filter__field-hvz`
};
export default ___CSS_LOADER_EXPORT___;
