import { Loader, Tooltip } from "pay-kit";
import React, { useEffect, useState } from "react";

import EyeIconSVG from "./eye.svg";
import styles from "./ProxiesView.module.scss";
import API from "api";
import { LoadProxyItemType } from "api/proxiesGroup";

interface IProxiesProps {
	// readonly proxies: CommonWalletData["proxies"];
	readonly proxies: Array<string>;
}

const ProxiesView: React.FC<IProxiesProps> = ({ proxies }) => {
	const [proxyLoading, setProxyLoading] = useState<boolean>(false);
	const [proxyData, setProxyData] = useState<Record<string, LoadProxyItemType>>({});

	useEffect(() => {
		setProxyLoading(true);

		if (proxies) {
			Promise.all(proxies.map((proxy) => API.proxy.loadProxyItem(proxy))).then((resp) => {
				const succeeded = resp.filter((proxy) => proxy.status === "success");
				const data = succeeded.map((proxy) => proxy.data);
				const obj: Record<string, LoadProxyItemType> = {};

				data.forEach((proxy) => {
					obj[proxy.id] = proxy;
				});

				setProxyData(obj);
				setProxyLoading(false);
			});
		}
	}, []);

	if (!proxies || proxies.length < 1) {
		return <>Нет</>;
	}

	const renderProxies = (
		<div className={styles.proxies}>
			{proxies.map((proxyId) => (
				<div key={proxyId} className={styles.proxy}>
					<div>
						<span>IP: </span>
						<span>{proxyData[proxyId]?.ip}</span>
					</div>
					<div>
						<span>Порт: </span>
						<span>{proxyData[proxyId]?.port}</span>
					</div>
					<div>
						<span>Тип: </span>
						<span>{proxyData[proxyId]?.type}</span>
					</div>
					<div>
						<span>Страна: </span>
						<span>{proxyData[proxyId]?.country_code}</span>
					</div>
				</div>
			))}
		</div>
	);

	return <Tooltip tip={renderProxies}>{proxyLoading ? <Loader /> : <EyeIconSVG />}</Tooltip>;
};

export default ProxiesView;
