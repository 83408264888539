import { ModalProps } from "utils/types";
import { StandardResponse } from "api/types";
import ChangeWalletModal from "modules/Wallets/components/modals/ChangeWalletModal";
import { useEffect, useState } from "react";
import API from "api";
import { screenPassword } from "utils/screening";
import prepareFormDataForRequest from "../helpers/prepareFormDataForRequest";
import parseAPIResponseErrorsFormat from "../helpers/parseAPIResponseErrorsFormat";

type UpdateWalletModalProps = ModalProps & {
	readonly isOpen: boolean;
	readonly actions: {
		readonly updateWallet: (form: any) => Promise<StandardResponse<void>>;
	};
	readonly walletId: number;
};

type Requisite = {
	readonly code: string;
	readonly name: string;
};

export const EditWalletModal = ({ closeModal, isOpen, actions, walletId }: UpdateWalletModalProps) => {
	const [IsLoading, setIsLoading] = useState<boolean>(false);

	const [initForm, setInitForm] = useState<Partial<any>>({});

	const [respErrors, setErrors] = useState<any | null>(null);

	const [requisites, setRequisites] = useState<Requisite[]>([]);

	const clearExternalErrors = () => {
		setErrors(null);
	};

	useEffect(() => {
		if (!isOpen) {
			clearExternalErrors();
		}
	}, []);

	const fetchRequisites = (wallet_type: string) => {
		setIsLoading(true);

		API.walletType
			.getManualPSRequisites(wallet_type)
			.then((resp) => {
				if (resp.status === "success") {
					setRequisites(resp.data.requisite_codes.map((rc) => ({ name: rc, code: rc })));
				}
			})
			.catch((err) => {
				console.error(err);
				window.pushAlert({ description: "Не удалось получить данные о реквизитах", type: "error" });
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		setIsLoading(true);

		API.wallet
			.getItem(walletId)
			.then((res) => {
				if (res.status === "success") {
					const data = res?.data;
					for (const key of Object.keys(data)) {
						if (data[key] === null) {
							delete data[key];
						}
					}
					const wallet_type = data.wallet_type_code;
					const currency_code = data.currency_code;
					const proxies = data.proxies.map(({ id }: any) => ({ id }));
					let cookie: string | undefined = JSON.stringify(data.cookie || {});
					if (Object.keys(cookie).length === 0) {
						cookie = undefined;
					}

					if (data.password) {
						data.password = screenPassword(data.password);
					}

					if (data.email_password) {
						data.email_password = screenPassword(data.email_password);
					}

					// TODO: A temporal crutch up to deleting after the fix on the backend
					if (typeof data?.wallet_settings?.codephrases === "string") {
						data.wallet_settings.codephrases = JSON.parse(data.wallet_settings.codephrases);
					}

					if (data?.wallet_settings?.upi_addresses) {
						data.upi_addresses = data.wallet_settings.upi_addresses.map((upi_address: string) => ({ upi_address }));
					}

					const initForm = {
						...data.wallet_settings,
						...data,
						...data.requisites,
						currency_code,
						wallet_type,
						proxies,
						cookie,
					};

					setInitForm(initForm);
					return;
				} else {
					throw new Error("Unexpected response");
				}
			})
			.catch((err) => {
				console.error(err);
				window.pushAlert({ description: "Не удалось получить данные о кошельке", type: "error" });
			})
			.finally(() => {
				// небольшой тюнинг, для того, чтобы не мигало состояние загрузки при подгрузке доп. реквизитов (fetchRequisites)
				setTimeout(() => setIsLoading(false), 0);
			});
	}, []);

	const onSuccess = () => {
		window.pushAlert({
			title: "Успех",
			description: `Кошелек ${walletId} обновлен`,
			type: "success",
		});
	};

	const onSubmit = (form: Partial<any>) => {
		return actions.updateWallet(prepareFormDataForRequest(form)).then((resp) => {
			if (resp.status === "error") {
				if (resp.error_message) {
					window.pushAlert({ description: resp.error_message, type: "error" });
				}
				
				if (resp.errors.length) {
					setErrors(parseAPIResponseErrorsFormat(resp.errors));
				}
			}

			return resp;
		});
	};

	return (
		<ChangeWalletModal
			adminPanelType="superadmin"
			loading={IsLoading}
			isOpen={isOpen}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			initForm={initForm}
			closeModal={closeModal}
			actions={actions}
			externalErrors={respErrors}
			clearExternalErrors={clearExternalErrors}
			fetchRequisites={fetchRequisites}
			requisites={requisites}
		/>
	);
};

export default EditWalletModal;
