import { ParserType, WalletState } from "modules/Wallets/components/modals/ChangeWalletModal/utils/enums";
import { WalletTypes } from "utils/enums";

import { fetchApi } from "./fetch";

export type Proxy = {
	readonly country_code: string;
	readonly id: string;
	readonly ip: string;
	readonly port: number;
	readonly status: number;
	readonly type: string;
};

export type CommonWalletData = {
	readonly account_login: string | null;
	readonly balance: number;
	readonly comment: string | null;
	readonly type: WalletTypes;
	readonly updated_at: string;
	readonly hash_id: string;
	readonly uuid: string;
	readonly identifier: string;
	readonly currency: {
		readonly code: string;
		readonly symbol: string;
	};
	readonly proxies: string[];
	readonly error: string | null;
	readonly is_active: boolean;
	readonly priority: WalletPriority;
};

export type ExtraBalance = {
	readonly currency: string;
	readonly balance: string;
};

export type Counterparty = {
	counterparty_uuid: string;
	counterparty_wallet_settings: {
		wallet_counterparty_settings_id: string;
		priority: WalletPriority;
		is_active: boolean;
		plugin_status: boolean;
		status: WalletState[];
		error: string | null;
		parsing_start_at: string;
		wallet_off_load: number;
		daily_transaction_limit: number | null;
	};
};

export type WalletListItem = CommonWalletData & {
	readonly wallet_type_code: string;
	readonly account_number: string | null;
	readonly code: string;
	readonly removed: boolean;
	readonly requisites: readonly any[];
	readonly plugin_status?: boolean;
	readonly upi_addresses?: readonly string[];
	readonly extra_balances?: readonly ExtraBalance[];
	readonly currency_code?: string;
	readonly wallet_settings?: {
		readonly sms_code: number;
		readonly sms_source: string;
		readonly parser_type: ParserType;
	};
	readonly counterparties: Counterparty[];
	readonly display_identifier?: string;
};

export enum WalletPriority {
	LOW = "0",
	BELOW_NORMAL = "25",
	NORMAL = "50",
	ABOVE_NORMAL = "75",
	HIGH = "100",
}

export type CommonWalletItem = CommonWalletData & {
	readonly activity_log: {
		readonly data: {
			readonly updated_at: string | null;
		};
		readonly updated_at: string | null;
		readonly last_ingoing: string | null;
		readonly last_outgoing: string | null;
	};
	readonly created_at: string;
	readonly email_parser_proxy: readonly Proxy[];
	readonly priority: WalletPriority;
	readonly vendor_code: string | null;
	readonly wallet_settings: Record<string, string>;
	readonly counterparties: Counterparty[];
};

export type WalletItem = CommonWalletItem & any;

export type UpdateSettingData = {
	readonly hash_id: CommonWalletData["hash_id"];
	readonly comment: WalletItem["comment"];
	readonly plugin_status?: WalletListItem["plugin_status"];
	readonly is_standard?: boolean;
};

export type SetSmsCodeParams = {
	readonly identifier: string;
	readonly sms_code: string;
};

export type LoadQueryParams = {
	readonly direction: "outgoing" | "ingoing";
	readonly load_object: "payment_systems" | "wallets";
	readonly wallet_type?: string;
	readonly load_type: "transactions_count" | "transactions_sum";
	readonly is_active?: boolean; // false = отключенные кошельки, true = рабочие кошельки
	readonly removed?: boolean; // true = удаленные кошельки
	readonly transaction_status?: 1 | 0; // Статус транзакций: 1: активированные
	// Дата создания транзакции
	readonly create_date_from?: string;
	readonly create_date_to?: string;
	// Дата активации транзакции
	readonly date_from?: string;
	readonly date_to?: string;
};

export type LoadQueryTransactionsSumListItem = {
	readonly key: string;
	readonly type: string;
	readonly transactions_usd_sum: string;
	readonly currency_transaction_sum: readonly [
		{
			readonly currency_code: string;
			readonly amount: string;
		}
	];
};

export type LoadQueryTransactionsCountListItem = {
	readonly id: number;
	readonly identifier: string;
	readonly transactions_count: number;
	readonly type: string;
};

export type LoadQueryListItem = LoadQueryTransactionsSumListItem | LoadQueryTransactionsCountListItem;

export type WalletsFilterForm = {
	readonly id?: string;
	readonly type?: string;
	readonly identifier?: string;
	readonly account_login?: string;
	readonly state?: string;
};

export type WalletStatusType = "worked" | "disabled" | "archived";

export type WalletStatus = {
	readonly id: number;
	readonly code: string;
	readonly text: string;
};

export type WalletStatusList = {
	readonly statuses: readonly WalletStatus[];
};

export type WalletType = {
	readonly account_login: string;
	readonly account_number: string;
	readonly balance: number;
	readonly code: string;
	readonly comment: string;
	readonly currency: { readonly code: string; readonly symbol: string };
	readonly error: string;
	readonly hash_id: number;
	readonly identifier: string;
	readonly is_active: boolean;
	readonly is_standard: boolean;
	readonly plugin_status: boolean;
	readonly proxies: readonly unknown[];
	readonly removed: boolean;
	readonly requisites: readonly unknown[];
	readonly sms_code: unknown;
	readonly state: readonly string[];
	readonly type: string;
	readonly updated_at: string;
	readonly status: number;
	readonly site_parser?: boolean;
	readonly email_parser?: boolean;
	readonly parser_type?: string | null;
};

//TODO: "Переделать после выпуска страницы 'Кошельки'"
type GetWalletsType = WalletType[];

const walletsGroup = {
	getList: (filter: WalletsFilterForm) =>
		fetchApi<readonly WalletListItem[]>({
			url: "/wallet/",
			params: {
				...filter,
			},
		}),

	getPossibleWalletStatuses: (type: WalletStatusType, language: string) =>
		fetchApi<WalletStatusList>({ url: `/wallet/status/`, params: { type, language } }),

	show: (hash_id: WalletListItem[`hash_id`]) => fetchApi({ url: `/wallet/show/${hash_id}` }),

	updateSetting: ({ hash_id, ...rest }: UpdateSettingData) =>
		fetchApi<void>({ url: `/wallet/settings/${hash_id}`, params: rest, method: "PUT" }),

	archiveWallet: (hash_id: WalletListItem[`hash_id`]) =>
		fetchApi<void>({ url: `/wallet/archive/${hash_id}`, method: "PUT" }),

	recoverWallet: (hash_id: WalletListItem[`hash_id`], params: { parsing_start_at?: string }) =>
		fetchApi<void>({ url: `/wallet/restore/${hash_id}`, method: "PUT", params }),

	setSmsCode: (hash_id: WalletListItem[`hash_id`], params: SetSmsCodeParams) =>
		fetchApi({ url: `/wallet/set-sms-code/${hash_id}`, params, method: "PUT" }),

	load: (params: LoadQueryParams) => fetchApi({ url: "/wallet/load", params }),

	getItem: (id: WalletListItem[`hash_id`]) => fetchApi<WalletItem>({ url: `/wallet/${id}` }),

	create: (form: any) => fetchApi({ url: "/wallet", params: form, method: "POST" }),

	update: (id: WalletListItem[`hash_id`], form: any) => fetchApi({ url: `/wallet/${id}`, params: form, method: "PUT" }),

	getWallets: (params) => fetchApi<GetWalletsType>({ url: `wallet`, params }),

	setCookie: (hash_id: WalletListItem[`hash_id`]) =>
		fetchApi<void>({ url: `/wallet/cookie/${hash_id}`, method: "DELETE" }),

	addCounterparty: (id: WalletListItem[`hash_id`], counterparty_id: Counterparty[`counterparty_uuid`]) =>
		fetchApi<void>({ url: `/wallet/counterparty/${id}`, params: { counterparty_id }, method: "POST" }),

	attachCounterparty: (walletHashId: WalletListItem[`hash_id`], counterparty_uuid: string) =>
		fetchApi<AttachCounterpartyResponseDataType>({
			url: `wallet/attach-counterparty/${walletHashId}`,
			params: { counterparty_uuid },
			method: "POST",
		}),

	detachCounterparty: (wallet_hash_id: WalletListItem[`hash_id`], counterparty_uuid: string) =>
		fetchApi<void>({
			url: `/wallet/detach-counterparty/${wallet_hash_id}`,
			params: { counterparty_uuid },
			method: "POST",
		}),

	deleteWallet: (hash_id: string) => fetchApi({ url: `/wallet/${hash_id}`, method: "DELETE" }),

	getWalletProxies: (hash_id: WalletListItem[`hash_id`]) =>
		fetchApi<GetWalletProxiesResp>({ url: `/wallet/proxies/${hash_id}` }),

	attachProxies: (proxy_uuids: string[], wallet_hash_id: string) =>
		fetchApi({ url: `wallet/attach-proxies`, params: { wallet_hash_id, proxy_uuids }, method: `POST` }),

	detachProxies: (proxy_uuids: string[], wallet_hash_id: string) =>
		fetchApi({ url: `wallet/detach-proxies`, params: { wallet_hash_id, proxy_uuids }, method: `POST` }),

	editCounterparty: (params: EditCounterpartyBody) =>
		fetchApi<void>({
			url: `wallet/counterparty-wallet-settings/${params.wallet_counterparty_settings_id}`,
			params,
			method: `PUT`,
		}),
};

type EditCounterpartyBody = {
	/** Этого поля нет в доке, но оно используется в коде */
	wallet_counterparty_settings_id: string;
	/**	Приоритет кошелька: */
	priority: number;
	/** Состояние кошелька: */
	is_active: boolean;
	/** Отображать на плагине: */
	plugin_status: boolean;
	/** Суточный лимит по сумме: */
	wallet_off_load: number;
	/** Дата начала парсинга транзакций:
	 *
	 * Формат: YYYY-MM-DD HH:MM:SS
	 *
	 */
	parsing_start_at?: string;
	daily_transaction_limit?: number;
};

export type WalletStatistic = {
	// Тип ПС
	readonly wallet_type: string;
	// Наименование ПС
	readonly wallet_type_name: string;
	// Валюта ПС
	readonly currency: string;
	// Общее количество рабочих кошельков с включенной видимостью
	readonly visible_counter: number;
	// Общее количество рабочих кошельков
	readonly worked_counter: number;
	// Общее количество кошельков
	readonly all_counter: number;
	// Текущий оборот видимых - сумма фактически совершенных транзакций за сегодняшний день (с 00:00 UTC) по видимым кошелькам
	readonly visible_daily_turnover: number;
	// Суммарное значение параметра "Отключать при балансе"
	readonly visible_wallet_off_limit: number;
	// Суммарное значение параметра "Баланс"
	readonly visible_balance: number;
	// Суммарное значение параметра "Суточный лимит по сумме"
	readonly visible_wallet_off_load: number;
	// Суммарное значение параметра "Баланс"
	readonly not_removed_balance: number;
	// 	Суммарное значение параметра "Отключать при балансе"
	readonly not_removed_wallet_off_limit: number;
	// Текущий оборот  - сумма фактически совершенных транзакций за сегодняшний день (с 00:00 UTC) по  кошелькам  (removed=NULL ) этой ПС
	readonly not_removed_daily_turnover: number;
	// Суммарное значение параметра "Суточный лимит по сумме"
	readonly not_removed_wallet_off_load: number;
	// Массив значений по дневному обороту за 7 дней
	readonly all_daily_turnover: ReadonlyArray<{
		// Дата, за которую был сформирован оборот по кошелькам
		readonly date: string;
		// Дневной оборот  - сумма фактически совершенных транзакций за день (с 00:00 UTC) по  кошелькам  этой ПС
		readonly value: number;
	}>;
};

export default walletsGroup;

export type AttachCounterpartyResponseDataType = {
	counterparty_wallet_settings_id: string;
	plugin_status: boolean;
	priority: number;
	is_active: boolean;
	wallet_off_load: null | number;
	parsing_start_at: string;
	status: WalletState[];
	error: null | string;
	daily_transaction_limit: null | number;
};

export type GetWalletProxiesResp = {
	wallet: WalletListItem["hash_id"];
	proxies: {
		country_code: string;
		ip: string;
		port: number;
		status: boolean;
		type: string;
		uuid: string;
	}[];
};
