import { fetchApi } from "./fetch";
import { infer as Infer, number, object, string } from "zod";

export type Pagination = Partial<{
	limit: number;
	offset: number;
	total: number;
}>;

const caseScheme = object({
	id: number(),
	details: string(),
	created_at: object({
		date: string(),
		timezone_type: number(),
		timezone: string(),
	}),
	updated_at: object({
		date: string(),
		timezone_type: number(),
		timezone: string(),
	}),
	credentials_count: number(),
	transactions_count: number(),
});

export type Case = Infer<typeof caseScheme>;

export type CaseParams = {
	id?: string;
	details?: string;
	created_from?: string;
	created_to?: string;
};

const credentialScheme = object({
	id: number(),
	fraud_cases_id: number(),
	value: string(),
	type: string(),
	created_at: object({
		date: string(),
		timezone_type: number(),
		timezone: string(),
	}),
});

export type Credential = Infer<typeof credentialScheme>;

const credentialTypeScheme = object({
	// account, email, phone, ip
	type: string(),
});

export type CredentialParams = {
	id?: string;
	fraud_cases_id?: string;
	value?: string;
	type?: string;
	created_from?: string;
	created_to?: string;
};

export type CredentialType = Infer<typeof credentialTypeScheme>;

const transactionScheme = object({
	id: number(),
	fraud_cases_id: number(),
	counterparty_uuid: string(),
	transactions_id: string(),
	created_at: object({
		date: string(),
		timezone_type: number(),
		timezone: string(),
	}),
});

export type TransactionParams = {
	id?: string;
	fraud_cases_id?: string;
	counterparty_uuid?: string;
	transactions_id?: string;
	created_from?: string;
	created_to?: string;
};

export type Transaction = Infer<typeof transactionScheme>;

export type CredentialByIdParams = Omit<CredentialParams, "fraud_cases_id">;

export type TransactionsByIdParams = Omit<TransactionParams, "fraud_cases_id">;

export const blackListGroup = {
	getCredentialTypes: () => fetchApi<Array<CredentialType>>({ url: "blacklist/credential-types", method: "GET" }),
	getCasesList: (params?: CaseParams) => fetchApi<Array<Case>>({ url: "blacklist/cases", method: "GET", params }),
	getCase: (id: string) => fetchApi({ url: `blacklist/cases/${id}`, method: "GET" }),
	createCase: (params: unknown) => fetchApi<{ id: number }>({ url: `blacklist/case`, method: "POST", params }),
	updateCase: (params: { id: string; details: string }) => fetchApi({ url: `blacklist/case`, params, method: "PUT" }),
	getCredentialsList: (params: CredentialParams) => fetchApi<Array<Credential>>({ url: "blacklist/credentials", method: "GET", params }),
	getCredentialsByCaseId: (caseId: string, params: CredentialByIdParams) => fetchApi<Array<Credential>>({ url: `blacklist/credentials/${caseId}`, method: "GET", params }),
	createCredential: (params: unknown) => fetchApi({ url: `blacklist/credential`, method: "POST", params }),
	deleteCredential: (id: string) => fetchApi({ url: `blacklist/credential/${id}`, method: "DELETE" }),
	updateCredential: (params: { type: string; value: string; fraud_cases_id: number }) => fetchApi({ url: `blacklist/credential/`, params, method: "PUT" }),
	updateCredentialPost: (params: { type: string; value: string; fraud_cases_id: number }) => fetchApi({ url: `blacklist/credential/`, params, method: "POST" }),
	getTransactionsList: (params: TransactionParams) => fetchApi<Array<Transaction>>({ url: "blacklist/transactions", method: "GET", params }),
	getTransactionsByCaseId: (caseId: string, params: TransactionsByIdParams) => fetchApi({ url: `blacklist/transactions/${caseId}`, method: "GET", params }),
	editIncident: (id: string, params: { details: string }) => fetchApi({ url: `fraud_cases/${id}`, params, method: "PUT" }),
	createIncident: (params: { details: string }) => fetchApi({ url: "fraud_cases", params, method: "POST" }),
	putRequisiteIntoBlackList: () => fetchApi({ url: "fraud_credentials", method: "PUT" }),
	putRequisiteToIncident: (id: string) => fetchApi({ url: `fraud_credentials/${id}`, method: "PUT" }),
	editRequisites: (id: string, params: { value: string; type: string }) => fetchApi({ url: `fraud_credentials/${id}`, params, method: "PUT" }),
	deleteRequisite: (id: string) => fetchApi({ url: `fraud_credentials/${id}`, method: "DELETE" }),
};
