import Filter from "components/Filter";
import RightModal from "components/Modals/RightModal";
import SideModalContent from "modules/Wallets/components/SideModalContent";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import ReloadButton from "components/ReloadButton";
import { getDateString } from "modules/LogsList/helpers";
import { LogsListColumns } from "modules/LogsList/components/LogsListColumns";
import LogsListContextProvider, { useLogsListContext } from "modules/LogsList/contexts/LogsListContext";
import useLoadLogList from "modules/LogsList/hooks/useLoadLogList";
import useLoadLogsCategoryList from "modules/LogsList/hooks/useLoadLogsCategoryList";
import { Table } from "pay-kit";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { rejectSettlement } from "utils/filterSettlement";
import useURLState from "utils/hooks/useURLState";

import styles from "./logsList.module.scss";
import PageTitle from "components/PageTitle";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useLazyLoadWallets } from "utils/hooks/useLazyLoadWallets";

const LogsList = () => {
	const { setWalletId, walletId } = useLogsListContext();
	const { walletTypes, isLoading } = useContext(WalletTypesContext);
	const logsCategoryAPI = useLoadLogsCategoryList();
	const logsAPI = useLoadLogList();
	const params = new URLSearchParams(window.location.search);
	const identifier = params.get("wallet_hash_id") || ``;
	const [isFetchedWithId, setFetched] = useState(false);
	const navigate = useNavigate();
	const [page, setCurrentPage] = useURLState<number>("page", logsAPI.data?.current_page || 1);
	const [limit, setLimit] = useURLState<number>("limit", logsAPI.data?.per_page || 10);
	const [filters, setFilters] = useState<FiltersType>({});
	const { hasRole } = useContext(AuthContext);

	const { isLoadingWallets, walletsNumbersOptions, onMenuScrollToBottom, loadOptions } = useLazyLoadWallets();

	const walletTypeOptions = rejectSettlement(walletTypes)?.map(({ name, code }) => ({ value: code, label: name }));

	const walletCategoryListOptions = logsCategoryAPI.list?.map(({ text, value }) => ({ value, label: text }));

	const handleShowAllTransactionClick = (wallet_id: number) => {
		navigate(`/transactions?walletId=${wallet_id}`);
	};

	const OnloadLogList = () => {
		const { dateRange, ...rest } = filters;
		const params: Record<any, any> = {
			...rest,
			date_from: getDateString({
				dateString: dateRange?.from,
			}),
			date_to: getDateString({
				dateString: dateRange?.to,
			}),
			category: [rest.category],
			page,
			limit,
		};

		logsAPI.load(params);
	};

	useEffect(() => {
		if (logsAPI.data?.per_page) setLimit(logsAPI.data.per_page);
		if (logsAPI.data?.current_page) setCurrentPage(logsAPI.data.current_page);
	}, [logsAPI.data?.per_page, logsAPI.data?.current_page]);

	useEffect(() => {
		if (
			identifier &&
			!isLoading &&
			(walletsNumbersOptions?.length || 0) > 0 &&
			!logsAPI.isLoading &&
			!isFetchedWithId
		) {
			setFilters((prev) => ({
				...prev,
				wallet_hash_id: walletsNumbersOptions?.find((option) => option.value === identifier)?.value,
			}));
			setFetched(true);
		}
	}, [identifier, walletsNumbersOptions?.length, isLoading, logsAPI.isLoading, isFetchedWithId]);

	useEffect(() => {
		if (Object.keys(filters).length) OnloadLogList();
		else logsAPI.load({ page, limit });
	}, [page, limit, filters]);

	const walletListAccess = hasRole(Roles.WALLET_LIST);

	const filtersFields = useMemo(() => {
		let fields: any[] = [
			{
				name: "wallet_type_code",
				placeholder: "Тип кошелька",
				component: "select",
				className: styles.selectField,
				options: walletTypeOptions,
				isLoading,
			},
		];

		if (walletListAccess) {
			fields.push({
				name: "wallet_hash_id",
				component: "lazySelect",
				className: styles.selectField,
				isLoading: isLoadingWallets,
				placeholder: "Кошелек",
				lazyProps: {
					onMenuScrollToBottom: onMenuScrollToBottom,
					defaultOptions: walletsNumbersOptions,
					loadOptions: loadOptions,
				}
			});
		}

		fields = [
			...fields,
			{
				name: "category",
				placeholder: "Категория",
				component: "select",
				// isMulti: true,
				className: styles.selectField,
				options: walletCategoryListOptions,
				isLoading: logsCategoryAPI.isLoading,
			},
			{
				name: "dateRange",
				toPlaceholder: "Дата до",
				fromPlaceholder: "Дата от",
				label: "Активация",
				component: "dateRange",
				dateTimeFormat: "DD-MM-YYYY",
			},
		];

		return fields;
	}, [
		walletListAccess,
		walletTypeOptions.length,
		logsCategoryAPI.isLoading,
		walletCategoryListOptions?.length,
		// walletsAPI.isLoading,
		walletsNumbersOptions?.length,
	]);

	return (
		<>
			<PageTitle title="Логи кошелька" />
			<Filter
				classNames={{ wrapper: styles.logsFilters }}
				values={filters}
				fields={filtersFields}
				onApply={setFilters}
			/>

			<div className={styles.logListTableWrapper}>
				<Table
					className={styles.logListTable}
					rowKey={"id"}
					data={logsAPI.data?.data || []}
					columns={LogsListColumns}
					isLoading={logsAPI.isLoading}
					paginator={{
						currentPage: page,
						setCurrentPage,
						limit,
						setLimit,
						lastAvailablePage: logsAPI.data?.last_available_page || 1,
						prefixElement: (
							<ReloadButton
								data-test-id="PPy7FPwz5A06ApnarXH8_"
								isLoading={logsAPI.isLoading}
								onClick={OnloadLogList}
							/>
						),
						className: styles.paginator,
						bottomPaginatorProps: {
							className: styles.bottomPaginator,
						},
					}}
				/>

				<RightModal
					bodyClassName={styles.logListModal}
					heading="Кошелёк"
					isVisible={!!walletId}
					onClose={() => setWalletId(null)}
					onShow={() => null}
				>
					<SideModalContent walletId={Number(walletId)} showAllTransactionsForWallet={handleShowAllTransactionClick} />
				</RightModal>
			</div>
		</>
	);
};

export default () => (
	<LogsListContextProvider>
		<LogsList />
	</LogsListContextProvider>
);

type FiltersType = {
	readonly wallet_type?: string;
	readonly wallet_id?: string;
	readonly category?: string;
	readonly dateRange?: { readonly from: string; readonly to: string };
};
