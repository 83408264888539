import UpAndDownButtons from "components/UpAndDownButtons";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";

import styles from "./walletAmountColTitle.module.scss";

const WalletAmountColTitle = () => {
	const { sortDirection, setSortDirection } = useProxyContext();

	return (
		<div className={styles.walletAmountColTitle}>
			<span>Количество<br /> кошельков</span>
			<UpAndDownButtons
				className={styles.UpAndDownButtons}
				value={sortDirection}
				onUp={() => setSortDirection("WALLETS_COUNT_ASC")}
				onDown={() => setSortDirection("WALLETS_COUNT_DESC")}
			/>
		</div>
	);
};

export default WalletAmountColTitle;
