import { FC, ReactNode, useMemo } from "react";
import Async from "react-select/async";
import styles from "./Select.module.scss";

// import ClearIndicator from "./ClearIndicator";
import LoadingIndicator from "./LoadingIndicator";
// import MultiValueRemove from "./MultiValueRemove";
import { useTranslation, HelpTip } from "@paykassma/pay-kit";
import { GroupBase, OptionProps, StylesConfig } from "react-select";

const ColorCyan10 = "#f1faff";
const ColorCyan70 = "#0072c3";
const ColorBlueGray40 = "#c5cbd1";
const ColorBlueGray50 = "#a2a9b0";
const ColorBlueGray70 = "#697077";
const ColorBlueGray90 = "#21272a";
const ColorRed60 = "#dc3545";


type Props = {
	/** Настройки для react-select-async */
	readonly selectProps: React.ComponentProps<typeof Async>;
	/** Имя инпута */
	readonly name: string;

	readonly isCompact?: boolean;
	readonly isRequired?: boolean;
	readonly help?: ReactNode;
	readonly label?: ReactNode;
	readonly className?: string;
	readonly withoutPlaceholder?: boolean;
	readonly error?: string;
	readonly optionsWithCheckbox?: boolean;
	readonly customOption?: FC<OptionProps<unknown, boolean, GroupBase<unknown>>>;
	readonly noOptionsMessage?: ReactNode;
	readonly noError?: boolean;
};

type Option = {
	readonly value: unknown;
	readonly label: ReactNode;
};

/**
 * LazySelect
 *
 * Закрывает два кейса:
 * 1. Загрузка с пагинацией опций по мере скролла
 * 2. Поиск на сервере значения, которое вводит пользователь
 *
 * Предназначен для работы с фильтрами. Хук для работы лежит в СА, useLazyLoadWallets.
 *
 * TODO:
 * - Стоит перенести хук для работы с компонентом из СА.
 * - Сейчас есть пропсы, скопированные из селекта. Часть из них не нужна.
 * - Есть опции в селекте, которые вообще, кажется, не работают.
 * - Есть много общих типов и вещей, между всеми селектами. Их стоит реорганизовать.
 * - Сейчас нужно задачу закрыть срочно. В дальнейшем тесты написать стоит, т.к. это будет точно развиваться.
 *
 * Подробнее про АПИ:
 *
 * ```
 * selectProps: {
 * 	isLoading - Флаг состояния загрузки
 * 	onMenuScrollToBottom - Коллбэк. Сработает, когда пользователь доскроллит до конца списка
 * 	defaultOptions - Список опций. Чтобы работал поиск, нужно пеердавть их именно в этот проп
 * 	loadOptions - Коллбэк, для поиска
 * }
 * ```
 *
 * @param props Опции стилей для селекта и настройки для async select
 * @returns AsyncSelect
 */
export const LazySelect: FC<Props> = (props) => {
	const {
		isCompact,
		isRequired,
		help,
		label,
		className,
		name,
		withoutPlaceholder,
		selectProps,
		customOption,
		error,
		optionsWithCheckbox,
		noOptionsMessage,
		noError,
		onChange,
	} = props;
	const compactClass = isCompact ? styles.compact : "";

	const { t } = useTranslation();

	const stylesConfig: StylesConfig<Option> = useMemo(
		() => ({
			container: (base) => ({
				...base,
				boxShadow: "none",
			}),

			control: (base, state) => {
				const getErrorStyles = () => ({
					border: `1px solid ${ColorRed60}`,
					boxShadow: "0px 0px 0px 2px rgba(255, 179, 184, 0.3)",

					"&:hover": {
						border: `1px solid ${ColorRed60}`,
						boxShadow: "0px 0px 0px 2px rgba(255, 179, 184, 0.3)",
					},
				});

				const getFocusStyles = () => ({
					border: `1px solid ${ColorCyan70}`,
					boxShadow: "0px 0px 0px 2px rgba(130, 207, 255, 0.3)",
					"&:hover": {
						border: `1px solid ${ColorCyan70}`,
						boxShadow: "0px 0px 0px 2px rgba(130, 207, 255, 0.3)",
					},
				});

				return {
					...base,
					borderRadius: "4px",
					borderColor: ColorBlueGray40,
					...(state.isFocused ? getFocusStyles() : {}),
					...(error ? getErrorStyles() : {}),
					minHeight: 34,
				};
			},

			singleValue: (base, state) => ({ ...base, color: state.isDisabled ? ColorBlueGray50 : ColorBlueGray90 }),

			dropdownIndicator: (base) => ({ ...base, color: ColorBlueGray70, padding: `0 6px` }),

			indicatorSeparator: () => ({ display: "none" }),

			input: (base) => ({
				...base,
				boxShadow: "none",
			}),

			placeholder: (base) => ({
				...base,

				display: withoutPlaceholder ? "none" : "initial",
			}),

			option: (base, state) => ({
				...base,
				color: state.isSelected ? ColorCyan70 : ColorBlueGray90,
				background: "none",
				whiteSpace: "break-spaces",

				"&:hover": {
					cursor: "pointer",
					background: ColorCyan10,
					color: ColorCyan70,
				},
			}),

			multiValue: (base) => ({
				...base,
				color: "red",
				backgroundColor: "none",
			}),

			multiValueRemove: (base) => ({
				...base,
				"&:hover": {
					cursor: "pointer",
					background: "none",
				},
			}),

			clearIndicator: (base) => ({
				...base,
				"&:hover": {
					cursor: "pointer",
				},
			}),
			valueContainer: (base) => ({
				...base,
				padding: "0 8px",
			}),
			menuList: (base) => ({
				...base,
				scrollbar: "thin",
				"&::-webkit-scrollbar": {
					width: "4px",
					height: "4px",
				},
				"&::-webkit-scrollbar-thumb": {
					backgroundColor: "#888",
					borderRadius: "4px",
				},
			}),
		}),
		[withoutPlaceholder, error]
	);

	const components = useMemo(
		() => ({
			LoadingIndicator,
			...(optionsWithCheckbox ? { Option } : {}),
			...(customOption ? { Option: customOption } : {}),
			...(noOptionsMessage ? { NotFoundOptions: noOptionsMessage } : {}),
		}),
		[optionsWithCheckbox, customOption, noOptionsMessage]
	);

	const asterisk = isRequired ? (
		<>
			&nbsp;<span className={`${styles.asterisk}`}>*</span>
		</>
	) : null;

	const helpIcon = help ? <HelpTip tip={help} className={styles.HelpTip} /> : null;

	return (
		<div className={`${styles.Select} ${compactClass} ${className}`}>
			{label && (
				<label className={`${styles.label}`} htmlFor={name}>
					{label} {asterisk} {helpIcon}
				</label>
			)}
			<div className={styles.selectAndErrorContainer}>
				<Async
					{...selectProps}
					{...props}
					name={name}
					components={components}
					styles={stylesConfig}
					onChange={(selectedOption: Option) => onChange(selectedOption)}
				/>
				{!noError && <div className={styles.errorMessage}>{error}</div>}
			</div>
		</div>
	);
};
