import { Loader } from "@paykassma/pay-kit";
import DefaultIcon from "assets/icons/defaultIcon.svg";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import React, { useContext } from "react";

type PaymentSystemLogoPropsType = {
	readonly id: string;
	readonly hideLabel?: boolean;
	readonly hideLogo?: boolean;
	readonly classNames?: {
		readonly wrapper?: string;
		readonly logo?: string;
		readonly caption?: string;
	};
};

import styles from "./PaymentSystemLogo.module.scss";

const PaymentSystemLogo: React.FC<PaymentSystemLogoPropsType> = ({ id, hideLabel, hideLogo, classNames }) => {
	const { walletTypes, isLoading } = useContext(WalletTypesContext);

	const currentWalletType = walletTypes.find((wt) => wt.code === id);

	const inlineLogoStyle = { backgroundImage: `url(${currentWalletType?.logo || ""})` };

	const logoStyle = currentWalletType ? styles.logo : `${styles.logo} ${styles.defaultLogo}`;

	return (
		<div className={[styles.PaymentSystemBadge, classNames?.wrapper].join(" ")}>
			{!hideLogo && (
				<div className={[logoStyle, classNames?.logo].join(" ")} style={inlineLogoStyle}>
					{!currentWalletType &&
						(isLoading ? (
							<Loader />
						) : (
							<>
								<DefaultIcon /> N/A
							</>
						))}
				</div>
			)}
			{!hideLabel && (
				<div className={[styles.caption, classNames?.caption].join(" ")}>{currentWalletType?.name || id}</div>
			)}
		</div>
	);
};

export default PaymentSystemLogo;
