import styles from "./Actions.module.scss";
import { WalletListItem } from "api/walletGroup";
import React, { useContext } from "react";
import EditIcon from "modules/Wallets/ActiveWallets/components/Actions/icons/EditIcon";
// import DebugIcon from "modules/Wallets/ActiveWallets/components/Actions/icons/DebugIcon";
// import ActivateIcon from "modules/Wallets/DisabledWallets/components/Actions/icons/ActivateIcon";
import ProxyIcon from "modules/Wallets/ActiveWallets/components/Actions/icons/ProxyIcon";
import { WalletContext } from "modules/Wallets/contexts/WalletContext";
import { useNavigate } from "react-router-dom";
import { Button } from "pay-kit";
import ArchiveIcon from "modules/Wallets/DisabledWallets/components/Actions/icons/ArchiveIcon";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { WalletTypes } from "utils/enums";

type ActionsProps = {
	readonly id: WalletListItem[`id`];
	readonly type: WalletListItem[`type`];
	readonly cookie_type: WalletListItem[`cookie_type`];
	readonly identifier: WalletListItem[`identifier`];
	readonly proxies: WalletListItem[`proxies`];
};

const Actions = ({ id, type, cookie_type, identifier, proxies }: ActionsProps) => {
	const { openArchiveWalletModal, openEditWalletModal, openClearCookieModal, openEditProxiesModal } =
		useContext(WalletContext);
	const { hasRole } = useContext(AuthContext);

	const updateAccess = hasRole(Roles.WALLET_UPDATE);
	const archiveAccess = hasRole(Roles.WALLET_DELETE);
	// const activeAccess = hasRole(Roles.WALLET_SET_ACTIVE);
	const logAccess = hasRole(Roles.LOGS);
	const proxyAccess = hasRole(Roles.PROXY_LIST);

	const navigate = useNavigate();

	const handleEdit = (e: React.MouseEvent) => {
		e.stopPropagation();
		openEditWalletModal(id);
	};

	const handleArchive = (e: React.MouseEvent) => {
		e.stopPropagation();
		openArchiveWalletModal(id);
	};

	//TODO: Поменять ссылку когда появится страница
	const handleDebug = (e: React.MouseEvent) => {
		e.stopPropagation();
		navigate(`/wallet-debug?id=${id}`);
	};

	const handleCookieClear = (e: React.MouseEvent) => {
		e.stopPropagation();
		openClearCookieModal(id);
	};

	const handleLogDebug = (e: React.MouseEvent) => {
		e.stopPropagation();
		navigate(`/logs?wallet_hash_id=${id}`);
	};

	const handleProxyEdit = () => {
		openEditProxiesModal({
			id,
			identifier,
			proxies,
		});
	};

	return (
		<div className={styles.wrapper}>
			{updateAccess && (
				<Button variant="secondary" onClick={handleEdit}>
					<EditIcon />
					<div className={styles.label}>Редактировать</div>
				</Button>
			)}
			{archiveAccess && (
				<Button variant="delete" onClick={handleArchive}>
					<ArchiveIcon />
					<div className={styles.label}>Архивировать</div>
				</Button>
			)}
			{updateAccess && type === WalletTypes.PAY_TM && cookie_type != "" && (
				<Button variant="delete" onClick={handleCookieClear}>
					<div className={styles.label}>Сбросить куки</div>
				</Button>
			)}
			{updateAccess && proxyAccess && (
				<Button onClick={handleProxyEdit} variant="secondary">
					<ProxyIcon />
					<div className={styles.label}>
						Прокси
					</div>
				</Button>
			)}
			{/* {logAccess && (
				<Button onClick={handleLogDebug} variant="secondary">
					<DebugIcon />
					<div className={styles.label}>Отладка</div>
				</Button>
			)} */}
		</div>
	);
};

export default Actions;
