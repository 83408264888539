import { Button, Tooltip } from "@paykassma/pay-kit";
import { PenIcon } from "components/Icons/PenIcon";
import { useContext } from "react";
import styles from "./Counterparties.module.scss";
import { WalletListItem } from "api/walletGroup";
import { useCounterparty } from "modules/Counterparty/hooks/useCounterparty";
import { WalletCounterpartyContext } from "modules/Wallets/contexts/WalletCounterpartyContext/WalletCounterpartyContext";

type CommentProps = {
	readonly id: WalletListItem[`hash_id`];
	readonly counterparties: WalletListItem[`counterparties`];
};

const Counterparties = ({ counterparties: walletCounterparties, id }: CommentProps) => {
	const { openAddCounterpartyModal, openListCounterpartyModal } = useContext(WalletCounterpartyContext);
	const { counterparties } = useCounterparty({});

	const handleAdd = () => {
		openAddCounterpartyModal(id);
	};

	const handleList = () => {
		openListCounterpartyModal(id);
	};

	return (
		<div className={styles.commentCell}>
			{walletCounterparties.length > 0 ? (
				<Tooltip tip={`Число контрагентов: ${walletCounterparties.length}`} preferredSide="bottom">
					<div className={styles.commentWrapper} onClick={handleList}>
						<div className={styles.identifiers}>
							{walletCounterparties
								.map((c) => c.counterparty_uuid)
								.map((uuid) => counterparties?.find((c) => c.id === uuid)?.identifier)
								.join(" ")}
						</div>

						<Button variant="secondary" classname={styles.editButton}>
							<PenIcon activeClass={styles.penIcon} />
						</Button>
					</div>
				</Tooltip>
			) : (
				<Button variant="link-primary" onClick={handleAdd} classname={styles.addCounterpartyButton}>
					Добавить контрагента
				</Button>
			)}
		</div>
	);
};

export default Counterparties;
