// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EllipsisTooltip__wrapper-WJN{display:flex;gap:4px}.EllipsisTooltip__wrapper-WJN .EllipsisTooltip__ellipsisContent-Wcg{overflow:hidden;line-break:anywhere}.EllipsisTooltip__wrapper-WJN .EllipsisTooltip__ellipsisContent-Wcg.EllipsisTooltip__interactive-yey{text-underline-position:under;text-decoration:underline dashed;text-decoration-color:rgba(105,112,119,.5);cursor:pointer}.EllipsisTooltip__wrapper-WJN .EllipsisTooltip__ellipsisContent-Wcg.EllipsisTooltip__clipped-la3{display:-webkit-box;-webkit-box-orient:vertical;text-overflow:ellipsis;white-space:normal;max-height:3em;line-break:anywhere}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/EllipsisTooltip/EllipsisTooltip.module.scss"],"names":[],"mappings":"AAAA,8BACI,YAAA,CACA,OAAA,CAEA,oEACI,eAAA,CACA,mBAAA,CAEA,qGACI,6BAAA,CACA,gCAAA,CACA,0CAAA,CACA,cAAA,CAGJ,iGACI,mBAAA,CACA,2BAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,mBAAA","sourcesContent":[".wrapper {\n    display: flex;\n    gap: 4px;\n\n    .ellipsisContent {\n        overflow: hidden;\n        line-break: anywhere;\n\n        &.interactive {\n            text-underline-position: under;\n            text-decoration: underline dashed;\n            text-decoration-color: rgba(105, 112, 119, 0.5);\n            cursor: pointer;\n        }\n\n        &.clipped {\n            display: -webkit-box;\n            -webkit-box-orient: vertical;\n            text-overflow: ellipsis;\n            white-space: normal;\n            max-height: 3em;\n            line-break: anywhere;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `EllipsisTooltip__wrapper-WJN`,
	"ellipsisContent": `EllipsisTooltip__ellipsisContent-Wcg`,
	"interactive": `EllipsisTooltip__interactive-yey`,
	"clipped": `EllipsisTooltip__clipped-la3`
};
export default ___CSS_LOADER_EXPORT___;
