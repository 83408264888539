import { Button } from "@paykassma/pay-kit";
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import dayjs from "dayjs";
import { useTranslation } from "pay-kit";
import { WalletTypes } from "utils/enums";

import styles from "../CreateWalletModal.module.scss";
import { AccountType, ParserType } from "../utils/enums";
import { getWalletParserTypes, handleUpiLFieldVisibleState } from "../utils/helpers";
import { walletTypeSchemeMapping } from "../utils/schemes";
import {
	alphaNumericWithSpec,
	isEmail,
	isRequired,
	lengthValidation,
	match,
	maxLengthValidation,
	onlyDigitsAllowed,
	optionalOnlyLatinSymbolsAllowed,
} from "../utils/validators";

export const useCommonWalletFields = () => {
	const { t } = useTranslation();
	const isSchemaContainPart =
		(part: string) =>
			({ wallet_type }: any) =>
				wallet_type && walletTypeSchemeMapping[wallet_type as WalletTypes]
					? !!walletTypeSchemeMapping[wallet_type as WalletTypes][part]
					: false;

	const isWalletType =
		(walletType: string) =>
			({ wallet_type }: any) =>
				wallet_type === walletType;

	return [
		{
			name: "parser_type",
			label: t("Parser"),
			validation: [isRequired],
			isRequired: true,
			placeholder: t("Select Dept"),
			type: "Select",
			options: ({ wallet_type, account_type }: FormType) => getWalletParserTypes(wallet_type, account_type),
			existsIf: (form) => {
				if (isWalletType(WalletTypes.UPI_L)(form)) {
					if (form.integration_type === "upi_l") {
						return true;
					}
				}

				return isSchemaContainPart("parser")(form);
			},
			dependsOn: ["account_type", "integration_type"],
		},
		{
			name: "integration_type",
			label: t("Type of integration"),
			type: "Select",
			existsIf: isSchemaContainPart("integrationType"),
			isRequired: true,
			validation: isRequired,
			options: (form) => {
				const upiSFields = [
					{
						label: "UPI S",
						value: "upi_s",
					},
					{
						label: "A-Pay",
						value: "a_pay",
					},
				];
				const upiLFields = [
					{
						label: "UPI L",
						value: "upi_l",
					},
					{
						label: "A-Pay",
						value: "a_pay",
					},
				];

				if (isWalletType(WalletTypes.UPI_S)(form)) {
					return upiSFields;
				}

				if (isWalletType(WalletTypes.UPI_L)(form)) {
					return upiLFields;
				}

				return undefined;
			},
		},
		{
			type: "Group",
			dependsOn: ["sms_source", "parser_type"],
			existsIf: (form: FormType) => {
				const simpleList = [
					WalletTypes.UPI_M,
					WalletTypes.CLICK_UZS,
					WalletTypes.WAVEPAY,
					WalletTypes.JAZZCASH,
					WalletTypes.ROCKET,
				];

				if (simpleList.includes(form.wallet_type) && form.sms_source === "email") {
					return true;
				}

				if (isWalletType(WalletTypes.PHONE_PE)(form)) {
					return (
						form.parser_type == ParserType.PARSER_EMAIL ||
						(form.parser_type == ParserType.PARSER_PHONE_PE_MOBILE_API && form.sms_source == "email")
					);
				}

				if (isWalletType(WalletTypes.UPI_L)(form)) {
					if (form.sms_source === "email") {
						return true;
					}
				}

				if (isWalletType(WalletTypes.BKASH)(form) || isWalletType(WalletTypes.NAGAD)(form)) {
					if (form.sms_source !== "email" && form.parser_type === ParserType.PARSER_EMAIL) {
						return false;
					}
					if (form.sms_source === "email" && form.parser_type === ParserType.PARSER_EMAIL) {
						return true;
					}
				}

				return (
					[ParserType.PARSER_EMAIL, ParserType.PARSER_APP].includes(form.parser_type) ||
					form.sms_source === "email" ||
					(isSchemaContainPart("email")(form) &&
						![ParserType.PARSER_HDFC, ParserType.PARSER_INDUSIN].includes(form.parser_type))
				);
			},
			elements: [
				{
					label: t("Email"),
					name: "email_username",
					type: "TextInput",
					isRequired: true,
					validation: [isRequired, maxLengthValidation(40), isEmail],
					withAutocomplete: false, // TODO: Autocomplete doesn't work properly yet
				},
				{
					name: "email_password",
					label: t("Email password"),
					htmlType: "password",
					type: "TextInput",
					isRequired: true,
					validation: [
						isRequired,
						(value, form) => {
							if ([
								WalletTypes.BKASH,
								WalletTypes.NAGAD,
								WalletTypes.EASYPAISA,
								WalletTypes.UPI_W,
								WalletTypes.UPI_M,
								WalletTypes.ROCKET,
								WalletTypes.UPI_AB,
								WalletTypes.VIETCOM_BANK,
								WalletTypes.IMPS_AB
							].includes(form.wallet_type)) {
								return maxLengthValidation(40)(value);
							}
						},
						(value, form) => {
							if ([
								WalletTypes.JAZZCASH,
								WalletTypes.PHONE_PE,
							].includes(form.wallet_type)) {
								return alphaNumericWithSpec(value);
							}
						},
						(value, form) => {
							if ([
								WalletTypes.JAZZCASH,
								WalletTypes.PHONE_PE,
							].includes(form.wallet_type)) {
								return maxLengthValidation(80)(value);
							}
						},
					],
					withAutocomplete: false, // TODO: Autocomplete doesn't work properly yet
				},
			],
		},
		{
			name: "sms_source",
			label: t("Method of receiving SMS"),
			type: "Select",
			options: ({ wallet_type }: FormType) => {
				let options = [
					{
						value: "email",
						label: t("Email"),
					},
				];

				if (wallet_type === WalletTypes.PAY_TM) {
					options = [
						...options,
						{
							value: `sms_box`,
							label: t("SMS box"),
						},
						{
							value: `manual_input`,
							label: t("Manual input"),
						},
					];
				}
				return [
					...options,
					{
						value: "mobile_data",
						label: t("Mobile Data"),
					},
				];
			},
			validation: [isRequired],
			isRequired: (form) => {
				const simpleList = [
					WalletTypes.UPI_M,
					WalletTypes.CLICK_UZS,
					WalletTypes.WAVEPAY,
					WalletTypes.JAZZCASH,
					WalletTypes.ROCKET,
				];

				if (simpleList.includes(form.wallet_type)) {
					return true;
				}

				if (isWalletType(WalletTypes.UPI_L)(form)) {
					if (form.integration_type === "upi_l" && form.parser_type === "freecharge") {
						return true;
					}
				}

				if (isWalletType(WalletTypes.BKASH)(form) || isWalletType(WalletTypes.NAGAD)(form)) {
					return form.parser_type === ParserType.PARSER_EMAIL;
				}

				return isWalletType(WalletTypes.PHONE_PE)(form) && form.parser_type === ParserType.PARSER_PHONE_PE_MOBILE_API;
			},
			dependsOn: ["integration_type", "parser_type"],
			existsIf: (form) => {
				if (isWalletType(WalletTypes.PHONE_PE)(form)) {
					return form.parser_type === ParserType.MOBILE_API;
				}

				if (isWalletType(WalletTypes.PAY_TM)(form)) {
					return true;
				}

				if (isWalletType(WalletTypes.UPI_L)(form)) {
					if (form.integration_type === "upi_l" && form.parser_type === "freecharge") {
						return true;
					}

					return false;
				}

				if (isWalletType(WalletTypes.BKASH)(form) || isWalletType(WalletTypes.NAGAD)(form)) {
					return form.parser_type === ParserType.PARSER_EMAIL;
				}

				const simpleList = [
					WalletTypes.UPI_M,
					WalletTypes.CLICK_UZS,
					WalletTypes.WAVEPAY,
					WalletTypes.JAZZCASH,
					WalletTypes.ROCKET,
				];

				if (simpleList.includes(form.wallet_type)) {
					return true;
				}

				return false;
			},
		},
		{
			name: "username",
			label: t("Site login"),
			type: "TextInput",
			isRequired: true,
			validation: [
				isRequired,
				(value, form) => {
					if ([
						WalletTypes.IMPS_IB,
						WalletTypes.UPI_HB,
						WalletTypes.UPI_IB,
						WalletTypes.UPI_IN,
						WalletTypes.IPAY,
						WalletTypes.PHONE_PE
					].includes(form.wallet_type)) {
						return maxLengthValidation(40)(value);
					}
				},
				(value, form) => {
					if ([
						WalletTypes.IMPS_HB,
						WalletTypes.IMPS_AB,
						WalletTypes.IMPS_IDB,
						WalletTypes.UPI_IDB,
						WalletTypes.UPI_W,
					].includes(form.wallet_type)) {
						return maxLengthValidation(32)(value);
					}
				},
				(value, form) => {
					if (isWalletType(WalletTypes.BKASH)(form)) {
						return match(/\d{11}/)(value);
					}
				},
				(value, form) => {
					if (isWalletType(WalletTypes.IPAY)(form)) {
						return alphaNumericWithSpec(value);
					}
				},
				(value, form) => {
					if (isWalletType(WalletTypes.UPI_L)(form)) {
						const parser = form.parser_type;
						if (parser === ParserType.PARSER_FREE_CHARGE) {
							return match(/\d{10}/)(value); 
						} else {
							return maxLengthValidation(40)(value); 
						}
					}
				},
				(value, form) => {
					if (isWalletType(WalletTypes.PHONE_PE)(form) && form.parser_type === ParserType.MOBILE_API) {
						return match(/\d{10}/)(value); 
					}
				},
				(value, form) => {
					if (isWalletType(WalletTypes.UPI_M)(form)) {
						return match(/\d{10}/)(value); 
					}
				},
				(value, form) => {
					if (isWalletType(WalletTypes.CLICK_UZS)(form)) {
						return match(/\d{9,12}/)(value); 
					}
				},
			],
			dependsOn: ["integration_type", "parser_type"],
			existsIf: (form: any) => {
				if (isWalletType(WalletTypes.UPI_L)(form)) {
					return handleUpiLFieldVisibleState(form, "username");
				}

				if ([WalletTypes.BKASH, WalletTypes.NAGAD].includes(form.wallet_type)) {
					return [ParserType.PARSER_SITE, ParserType.PARSER_APP].includes(form.parser_type);
				}

				if (form.wallet_type === WalletTypes.IMPS_AB) {
					return form.parser_type === ParserType.PARSER_AXIS;
				}

				if (form.wallet_type === WalletTypes.PHONE_PE) {
					return [ParserType.PARSER_INDUSIN, ParserType.PARSER_PHONE_PE_MOBILE_API, ParserType.PARSER_HDFC].includes(
						form.parser_type
					);
				}

				return isSchemaContainPart("defaultLogin")(form) || isSchemaContainPart("username")(form);
			},
		},
		{
			name: "password",
			// TODO: UI-kit сделать св-во label вычисляемым
			label: (form) => {
				const merchantPasswordList = [
					WalletTypes.EASYPAISA_API,
					WalletTypes.BKASH_API_CEBALRAI,
					WalletTypes.BKASH_API,
				];

				if (merchantPasswordList.includes(form.wallet_type)) {
					return t("Merchant password");
				}

				if (isWalletType(WalletTypes.PAY_TM)(form)) {
					return t("Wallet password");
				}

				return t("Site password");
			},
			type: "TextInput",
			htmlType: "password",
			isRequired: true,
			validation: [
				isRequired,
				(value, form) => {
					if ([
						WalletTypes.IMPS_AB,
						WalletTypes.IMPS_HB,
						WalletTypes.IMPS_IDB,
						WalletTypes.UPI_HB,
						WalletTypes.IMPS_IB,
						WalletTypes.UPI_IB,
					].includes(form.wallet_type)) {
						return maxLengthValidation(20)(value);
					}
				},
				(value, form) => {
					if ([
						WalletTypes.UPI_IN,
						WalletTypes.UPI_L,
						WalletTypes.PAY_TM,
					].includes(form.wallet_type)) {
						return maxLengthValidation(40)(value);
					}
				},
				(value, form) => {
					if (isWalletType(WalletTypes.IPAY)(form)) return maxLengthValidation(80)(value);
				},
				(value, form) => {
					if (isWalletType(WalletTypes.IPAY)(form)) return alphaNumericWithSpec(value);
				},
			],
			dependsOn: ["integration_type", "parser_type"],
			existsIf: (form: any) => {
				if (isWalletType(WalletTypes.UPI_L)(form)) {
					return handleUpiLFieldVisibleState(form, "password");
				}

				return (
					[
						ParserType.PARSER_SITE,
						ParserType.PARSER_APP,
						ParserType.PARSER_AXIS,
						ParserType.PARSER_HDFC,
						ParserType.PARSER_INDUSIN,
					].includes(form.parser_type) ||
					isSchemaContainPart("defaultLogin")(form) ||
					isSchemaContainPart("password")(form)
				);
			},
			withAutocomplete: false,
		},
		{
			name: "account_type",
			label: t("Account type"),
			isRequired: true,
			validation: [isRequired],
			type: "Select",
			options: [
				{
					label: t("Agent"),
					value: AccountType.AGENT,
				},
				{
					label: t("Personal"),
					value: AccountType.PERSONAL,
				},
				{
					label: t("Merchant"),
					value: AccountType.MERCHANT,
				},
			],
			existsIf: isSchemaContainPart("accountType"),
		},
		{
			label: t("Secret key"),
			name: "api_private_key",
			type: "TextInput",
			validation: [isRequired],
			isRequired: true,
			dependsOn: ["integration_type", "parser_type"],
			existsIf: (form) => {
				if (isWalletType(WalletTypes.UPI_S)(form)) {
					return form.integration_type === "a_pay";
				}

				if (isWalletType(WalletTypes.UPI_L)(form)) {
					return handleUpiLFieldVisibleState(form, "api_private_key");
				}

				return isSchemaContainPart("privateApiKey")(form);
			},
		},
		{
			label: (form) => {
				if (isWalletType(WalletTypes.EASYPAISA_API)(form)) {
					return t("Easypaisa public key");
				}

				if (isWalletType(WalletTypes.NAGAD_API)(form)) {
					return t("Nagad public key");
				}
				return t("API key");
			},
			name: "api_public_key",
			type: "TextInput",
			validation: [isRequired],
			isRequired: true,
			dependsOn: ["integration_type", "parser_type"],
			existsIf: (form) => {
				if (isWalletType(WalletTypes.UPI_L)(form)) {
					return handleUpiLFieldVisibleState(form, "api_public_key");
				}

				if (
					isWalletType(WalletTypes.UPI_S)(form) &&
					(form.integration_type === "upi_s" || form.integration_type === "a_pay")
				) {
					return true;
				} else {
					return isSchemaContainPart("publicApiKey")(form);
				}
			},
			// Костыль, анализ и тестеры считают это поле специфичным для ПС, а не для группы ПС
			specific: true,
		},
		{
			label: (form) => {
				return isWalletType(WalletTypes.EASYPAISA_API)(form) ? t("Store ID") : t("Merchant ID");
			},
			name: "merchant_id",
			type: "TextInput",
			isRequired: true,
			validation: [
				isRequired,
				(value, form) => {
					if (isWalletType(WalletTypes.NAGAD_API)(form)) {
						return lengthValidation(15)(value);
					}
				},
				(value, form) => {
					if (isWalletType(WalletTypes.MPESA)(form)) {
						return match(/\d{5,7}/)(value);
					}
				}
			],
			dependsOn: ["integration_type"],
			existsIf: (form) => {
				if (form?.wallet_type === WalletTypes.UPI_S) {
					if (form.integration_type === "upi_s") {
						return true;
					} else {
						return false;
					}
				} else {
					return isSchemaContainPart("merchantId")(form);
				}
			},
		},
		{
			label: t("Token"),
			name: "token",
			type: "TextInput",
			isRequired: true,
			validation: [isRequired],
			existsIf: isSchemaContainPart("token"),
		},
		{
			label: t("Account holder name"),
			name: "account_name",
			type: "TextInput",
			isRequired: true,
			validation: [
				isRequired,
				(value, form) => {
					if (form.wallet_type === WalletTypes.UPI_IB) return maxLengthValidation(20)(String(value));

					return undefined;
				},
			],
			dependsOn: ["integration_type"],
			existsIf: (form) =>
				isWalletType(WalletTypes.UPI_S)(form)
					? form.integration_type === "upi_s"
					: isSchemaContainPart("accountName")(form),
		},
		{
			label: t("IFSC code"),
			name: "ifsc_code",
			type: "TextInput",
			isRequired: true,
			validation: [isRequired],
			existsIf: isSchemaContainPart("ifscCode"),
		},
		{
			label: t("Account Number"),
			name: "account_number",
			type: "TextInput",
			isRequired: (form) => {
				if (form.wallet_type === WalletTypes.PAY_TM) {
					return false;
				}

				return true;
			},
			validation: [
				(value, form) => {
					if ([
						WalletTypes.UPI_L, 
						WalletTypes.PHONE_PE,
					].includes(form.wallet_type)) {
						return optionalOnlyLatinSymbolsAllowed(value);
					}
				},
				(value, form) => {
					if ([
						WalletTypes.UPI_L,
						WalletTypes.PHONE_PE,
						WalletTypes.IPAY,
					].includes(form.wallet_type)) {
						return maxLengthValidation(40)(String(value));
					}
				},
				(value, form) => {
					if ([
						WalletTypes.NAGAD_API,
					].includes(form.wallet_type)) return onlyDigitsAllowed(String(value));
				},
				(value, form) => {
					if (isWalletType(WalletTypes.NAGAD_API)(form)) return lengthValidation(11)(String(value));

					return undefined;
				},
				(value, form) => {
					if (isWalletType(WalletTypes.IPAY)(form)) return match(/[a-z\d@-_.]+/)(String(value));
				},
				(value, form) => {
					if (form.wallet_type === WalletTypes.PAY_TM) {
						return undefined;
					}

					return isRequired(String(value));
				},
			],
			dependsOn: ["integration_type", "parser_type"],
			order: 1,
			existsIf: (form) => {
				if (form.wallet_type === WalletTypes.PAY_TM && form.parser_type === ParserType.MOBILE_API) {
					return true;
				}

				if (form.wallet_type === WalletTypes.UPI_L) {
					return handleUpiLFieldVisibleState(form, "account_number");
				}

				return isSchemaContainPart("accountNumber")(form);
			},
		},
		{
			label: t("Object parsing"),
			name: "parsing_object",
			type: "Select",
			isRequired: true,
			validation: [isRequired],
			options: [
				{
					label: t("Web-page"),
					value: "webpage",
				},
				{
					label: t("Statement"),
					value: "statement",
				},
			],
			dependsOn: ["parser_type"],
			existsIf: (form: any) => {
				if (form.wallet_type === WalletTypes.PHONE_PE) {
					return form.parser_type === ParserType.PARSER_HDFC;
				}
				return isSchemaContainPart("parsingObject")(form);
			},
		},
		{
			label: t("Cookie"),
			name: "cookie",
			type: "TextInput",
			validation: [
				(value) => {
					try {
						if (!value) return undefined;

						const cookieObj = JSON.parse(value || ``);

						const dateStr = cookieObj.date;

						let error: string | undefined = undefined;

						if (!cookieObj.payload) {
							error = t("Incorrect payload");
						} else if (![`work`, `middle`].includes(cookieObj.type)) {
							error = t("Incorrect type");
						} else if (!dayjs(dateStr, `YYYY-MM-DD hh:mm:ss`).isValid()) {
							error = t("Incorrect date");
						}

						return error;
					} catch (e) {
						return t("Incorrect cookie");
					}
				},
			],
			existsIf: isSchemaContainPart("cookie"),
		},
		{
			label: t("UPI Address"),
			name: "upi_addresses",
			type: "Repeat",
			dependsOn: ["integration_type"],
			existsIf: (form) => {
				if (isWalletType(WalletTypes.UPI_S)(form)) {
					return form.integration_type === "upi_s";
				}

				return isSchemaContainPart("upi")(form);
			},
			isRequired: true,
			validation: [isRequired],
			render: (children, { insertItem, error, formDataAPI }) => (
				<>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<h4>{t("UPI Address")}</h4>
						<Button
							data-test-id="GTvp9DGDJThDbt-YzUSuF"
							disabled={formDataAPI.formState?.upi_addresses?.length >= 1}
							data-testid="add-address"
							variant="text-primary"
							onClick={() => insertItem({ upi_address: "" }, "insertAfter")}
						>
							+ {t("Add")}
						</Button>
					</div>
					{error && <div className={styles.upiErrorMessage}>{error}</div>}
					{children}
				</>
			),
			elements: [
				{
					type: "Group",
					render: (children, { removeCurrentItem }) => (
						<div
							style={{
								display: "flex",
								gap: "8px",
							}}
						>
							{children}
							<Button
								data-test-id="D03nzl1BYgCFFofqBfiS7"
								data-testid="removeAddress"
								variant="text-secondary"
								onClick={removeCurrentItem}
							>
								<TrashIcon />
							</Button>
						</div>
					),
					elements: [
						{
							type: "Group",
							render: (children) => (
								<div
									style={{
										flexGrow: 1,
									}}
								>
									{children}
								</div>
							),
							elements: [
								{
									placeholder: t("UPI address"),
									name: "upi_address",
									type: "TextInput",
									validation: [isRequired],
								},
							],
						},
					],
				},
			],
		},
		{
			label: t("Code phrase"),
			name: "codephrases",
			type: "Repeat",
			isRequired: true,
			validation: [isRequired, (val) => (val.length === 3 ? undefined : t("Specify at least 3 phrases"))],
			dependsOn: ["integration_type", "parser_type"],
			existsIf: (form: any) => {
				if (isWalletType(WalletTypes.UPI_L)(form)) {
					return handleUpiLFieldVisibleState(form, "codephrases");
				}

				return (
					isSchemaContainPart("codephrases")(form) &&
					![
						ParserType.PARSER_EMAIL,
						ParserType.PARSER_HDFC,
						ParserType.PARSER_PHONE_PE_MOBILE_API,
						ParserType.PARSER_FREE_CHARGE,
					].includes(form.parser_type)
				);
			},
			render: (children, { insertItem, error, formDataAPI }) => (
				<>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<h4>{t("Code phrase")}</h4>
						<Button
							data-test-id="vk0uaWQ3dAOJR0lu_bpQ6"
							data-testid="add-address"
							variant="text-primary"
							disabled={formDataAPI.formState?.codephrases?.length >= 3}
							onClick={() => insertItem({ question: "", answer: "" }, "insertAfter")}
						>
							+ {t("Add")}
						</Button>
					</div>
					{error && <div style={{ color: "#dc3545", fontSize: "12px", marginBottom: "8px" }}>{error}</div>}
					{children}
				</>
			),
			elements: [
				{
					type: "Group",
					render: (children, { removeCurrentItem }) => (
						<div
							style={{
								display: "flex",
								gap: "8px",
							}}
						>
							{children}
							<Button
								data-test-id="CZuqmjaw2RQ1nb73j3S23"
								data-testid="removePhrase"
								variant="text-secondary"
								onClick={removeCurrentItem}
							>
								<TrashIcon />
							</Button>
						</div>
					),
					elements: [
						{
							type: "Group",
							render: (children) => <div className={styles.codephrases}>{children}</div>,
							elements: [
								{
									name: "question",
									placeholder: t("Question"),
									type: "TextInput",
									validation: [isRequired],
								},
								{
									name: "answer",
									placeholder: t("Response"),
									type: "TextInput",
									validation: [isRequired],
								},
							],
						},
					],
				},
			],
		},
		{
			name: "webhook_id",
			label: t("Webhook ID"),
			type: "TextInput",
			isRequired: true,
			validation: [isRequired, onlyDigitsAllowed],
			dependsOn: ["integration_type"],
			existsIf: (form) => {
				if ([WalletTypes.UPI_S, WalletTypes.UPI_L].includes(form.wallet_type)) {
					return form.integration_type === "a_pay";
				}

				return isSchemaContainPart("webhook")(form);
			},
		},
		{
			name: "project_id",
			label: t("Project ID"),
			type: "TextInput",
			isRequired: true,
			transform: (v) => v,
			validation: [isRequired, onlyDigitsAllowed],
			dependsOn: ["integration_type", "parser_type"],
			existsIf: (form) => {
				if (isWalletType(WalletTypes.UPI_S)(form)) {
					return form.integration_type == "a_pay";
				}

				if (isWalletType(WalletTypes.PAY_OP)(form)) {
					return true;
				}

				if (isWalletType(WalletTypes.UPI_L)(form)) {
					return handleUpiLFieldVisibleState(form, "project_id");
				}

				return isSchemaContainPart("projectId")(form);
			},
		},
	];
};

type FormType = {
	readonly parser_type:
		| ParserType.PARSER_FREE_CHARGE
		| ParserType.PARSER_INDUSIN
		| ParserType.PARSER_EMAIL
		| ParserType.PARSER_PHONE_PE_MOBILE_API;
	readonly wallet_type: string;
	readonly account_type: AccountType;
};
