import { TableSelectedRowIds } from "@paykassma/pay-kit/lib/elements/Table/types";
import { RefreshBlock } from "components/RefreshBlock/RefreshBlock";
// import { proxiesTableColumns } from "modules/Proxies/components/ProxiesTableColumns";
import { useProxiesTableColumns } from "modules/Proxies/components/ProxiesTableColumns";
import TableManipulateButtonsGroup from "modules/Proxies/components/TableManipulateButtonsGroup";
import AddingProxiesContextProvider, { useAddingProxiesContext } from "modules/Proxies/contexts/AddingProxiesContext";
import ProxyContextProvider, { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { Table } from "pay-kit";
import { useEffect, useState } from "react";

import styles from "./proxies.module.scss";
import ProxyFilters from "./components/Filter";
import ReloadButton from "components/ReloadButton";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";

const Proxies = () => {
	const { proxies, isGetCheckedLoading, isProxyLoading, isLoading, getProxies, paginationInfo, filters } =
		useProxyContext();
	const { openAddProxyModal, setIsProxiesOpen } = useAddingProxiesContext();
	const [proxyIDs, setProxyIDs] = useState<TableSelectedRowIds>([]);
	const { hasRole } = useAuthContext();
	const { proxiesTableColumns } = useProxiesTableColumns();

	const handleAddProxy = () => {
		openAddProxyModal(true);
		setIsProxiesOpen((proxiesOpen) => ({ ...proxiesOpen, isAddProxyOpen: true }));
	};

	const multipleActionsRenderer = (idList: ReadonlyArray<number>) => (
		<TableManipulateButtonsGroup idList={idList} setProxyIDs={setProxyIDs} />
	);
	const isTableLoading = () => isLoading || isGetCheckedLoading || isProxyLoading;

	const [limit, setLimit] = useState<number>(20);
	const [page, setCurrentPage] = useState<number>(1);

	const loadProxyList = () => {
		getProxies({ limit, offset: (page - 1) * limit });
	};

	useEffect(loadProxyList, [page, limit, filters]);

	const tableMultiSelect = hasRole(Roles.PROXY_DELETE)
		? {
				multiselect: true,
				onRowsSelect: setProxyIDs,
				selectedIds: proxyIDs,
				renderMultipleActions: multipleActionsRenderer,
		  }
		: undefined;

	return (
		<div>
			<div className={styles.addDebugButtons}>
				<RefreshBlock addBtnLabel="Добавить прокси" onAddClick={handleAddProxy} />
			</div>
			<div
				style={{
					marginBottom: "32px",
				}}
			>
				<ProxyFilters />
			</div>
			<Table
				rowKey={"id"}
				data={proxies}
				columns={proxiesTableColumns}
				select={{
					multiselect: true,
					onRowsSelect: setProxyIDs,
					selectedIds: proxyIDs,
					renderMultipleActions: multipleActionsRenderer
				}}
				isLoading={isTableLoading()}
				paginator={
					paginationInfo
						? {
								currentPage: paginationInfo?.offset / limit + 1,
								setCurrentPage: (page: any) => setCurrentPage(page as number),
								limit,
								setLimit: (newLimit) => {
									setCurrentPage(1);
									setLimit(newLimit);
								},
								lastAvailablePage: Math.floor(paginationInfo?.total / paginationInfo.limit) + 1 || 1,
								className: styles.paginator,
								bottomPaginatorProps: {
									className: styles.bottomPaginator,
								},
								prefixElement: <ReloadButton data-test-id="" isLoading={false} onClick={loadProxyList} />,
						  }
						: undefined
				}
			/>
		</div>
	);
};

const ProxiesModule = () => (
	<ProxyContextProvider>
		<AddingProxiesContextProvider>
			<Proxies />
		</AddingProxiesContextProvider>
	</ProxyContextProvider>
);

export default ProxiesModule;
