// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletIdentifier__walletID-Evb{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/modules/LogsList/components/WalletIdentifier/walletID.module.scss"],"names":[],"mappings":"AAAA,gCACC,cAAA","sourcesContent":[".walletID {\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletID": `WalletIdentifier__walletID-Evb`
};
export default ___CSS_LOADER_EXPORT___;
