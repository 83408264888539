import React from "react";

import styles from "./ActiveFilter.module.scss";
import { OptionType } from "components/Filter/Field";

type ActiveFilterPropsType = {
	readonly label: React.ReactNode;
	readonly value: OptionType | string;
	readonly onClick: () => void;
};

const ActiveFilter: React.FC<ActiveFilterPropsType> = ({ label, value, onClick }) => {
	return (
		<div className={styles.activeFilter}>
			<span className={styles.label}>{label}:</span>
			<span className={styles.value}>{value?.value ? value.value : value}</span>
			<button data-test-id="activeFilter-clear" onClick={onClick}>
				<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect
						x="12.9492"
						y="3.06982"
						width="1"
						height="15"
						rx="0.5"
						transform="rotate(45 12.9492 3.06982)"
						fill="#697077"
					/>
					<rect
						x="13.6562"
						y="13.6763"
						width="1"
						height="15"
						rx="0.5"
						transform="rotate(135 13.6562 13.6763)"
						fill="#697077"
					/>
				</svg>
			</button>
		</div>
	);
};

export default ActiveFilter;
