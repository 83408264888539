// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletContext__changeWalletModal-u8P{width:560px;max-width:none !important;padding-right:0 !important}.WalletContext__changeWalletModal-u8P [class^=Modal__content],.WalletContext__changeWalletModal-u8P [class^=Modal__header]{padding-right:32px}.WalletContext__editProxiesModal-Kpr{width:560px}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/contexts/WalletContext/Wallets.module.scss"],"names":[],"mappings":"AAAA,sCACC,WAAA,CACA,yBAAA,CACA,0BAAA,CAEA,2HACC,kBAAA,CAIF,qCACC,WAAA","sourcesContent":[".changeWalletModal {\n\twidth: 560px;\n\tmax-width: none!important;\n\tpadding-right: 0!important;\n\n\t[class^=\"Modal__content\"], [class^=\"Modal__header\"] {\n\t\tpadding-right: 32px;\n\t}\n}\n\n.editProxiesModal {\n\twidth: 560px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changeWalletModal": `WalletContext__changeWalletModal-u8P`,
	"editProxiesModal": `WalletContext__editProxiesModal-Kpr`
};
export default ___CSS_LOADER_EXPORT___;
