import { WalletListItem } from "api/walletGroup";
import Money from "components/Money";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import StatusBadges from "modules/Wallets/components/StatusBadges";
import Comment from "../../../components/Comments/index";
import Actions from "../Actions";
import styles from "./Columns.module.scss";
import { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext";
import Counterparties from "modules/Wallets/components/Counterparties";
import { WalletState } from "modules/Wallets/components/modals/ChangeWalletModal/utils/enums";
import { prepareAdditionalInfoString } from "../../../utils";
import EllipsisTooltip from "../../../components/EllipsisTooltip";

export type WalletColumnProps = {
	setWalletId: (waletId: string) => void;
	privateAccess: boolean;
	balanceAccess: boolean;
	counterpartyAccess: boolean;
};

const idCol = {
	title: "Хеш",
	dataIndex: "hash_id",
	key: "hash_id",
};

const walletTypeCol = {
	title: "Тип",
	dataIndex: "type",
	key: "type",
	render: ({ wallet_type_code }: WalletListItem) => <PaymentSystemLogo id={wallet_type_code} />,
};

const identifierCol = (setWalletId: (hash_id: string) => void) => ({
	title: "Номер",
	dataIndex: "identifier",
	key: "identifier",
	render: ({ identifier, hash_id }: WalletListItem) => (
		<EllipsisTooltip
			text={identifier}
			dataTestId="identifier"
			onClick={() => setWalletId(hash_id)}
			canCopy
			maxCharLength={26}
		/>
	),
});

const balanceCol = {
	title: "Баланс",
	dataIndex: "balance",
	key: "balance",
	render: ({ balance, currency_code, extra_balances }: WalletListItem) => {
		if (extra_balances && extra_balances.length > 0) {
			return extra_balances.map(({ currency: balanceCurrency, balance: balanceAmount }, key) => {
				return (
					<div key={key}>
						{balanceCurrency}: {balanceAmount}
					</div>
				);
			});
		}

		return <Money direction="ingoing" amount={balance} currencyCode={currency_code} withSign={false} />;
	},
};

const extraInfoCol = {
	title: "Доп. сведения",
	dataIndex: "upi_addresses",
	key: "upi_addresses",
	render: ({ upi_addresses, display_identifier }: WalletListItem) => {
		return (
			<EllipsisTooltip
				text={prepareAdditionalInfoString(upi_addresses, display_identifier)}
				canCopy
				maxLines={2}
				maxCharLength={20}
			/>
		);
	},
};

const counterpartyCol = {
	title: "Контрагенты",
	dataIndex: "counterparties",
	key: "counterparties",
	render: ({ counterparties, hash_id }: WalletListItem) => (
		<Counterparties counterparties={counterparties} id={hash_id} />
	),
};

const commentCol = {
	title: "Комментарий",
	dataIndex: "comment",
	key: "comment",
	render: ({ comment, hash_id }: WalletListItem) => (
		<Comment comment={comment} hash_id={hash_id} ctx={ArchivedWalletContext} />
	),
};

const archivingCol = {
	title: "Дата архивации",
	dataIndex: "removed_at",
	key: "removed_at",
};

export const subRow = ({ hash_id, counterparties, identifier, proxies }: WalletListItem) => {
	const state: WalletState[] = [
		...new Set(
			(counterparties || [])
				.map((counterparty) => counterparty?.counterparty_wallet_settings?.status)
				.reduce((prev, curr) => [...prev, ...curr], [])
		),
	] as WalletState[];

	return (
		<>
			<div className={styles.subRow}>
				<StatusBadges state={state} />
				<Actions id={hash_id} identifier={identifier} proxies={proxies} />
			</div>
		</>
	);
};

const archivedWalletsColumns = (props: WalletColumnProps) => {
	const arr = [idCol, walletTypeCol, identifierCol(props.setWalletId)];

	if (props.balanceAccess) {
		arr.push(balanceCol);
	}

	arr.push(...[extraInfoCol, archivingCol, commentCol]);

	if (props.counterpartyAccess) {
		arr.push(counterpartyCol);
	}

	return arr;
};

export default archivedWalletsColumns;
