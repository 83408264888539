import PageTitle from "components/PageTitle";
import Proxies from "modules/Proxies";

const ProxiesPage = () => {

	return (
		<>
			<PageTitle title="Прокси" />
			<Proxies />
		</>
	);
};

export default ProxiesPage;
