import { ProxyCheckingStatusType } from "modules/Proxies/components/AddProxyModal";
import { checkedStatuses } from "modules/Proxies/data";
import { Alert } from "pay-kit";
import { FC, ReactElement } from "react";

import styles from "./inputFormatHint.module.scss";

const InputFormatHint: FC<InputFormatHintType> = ({ children, proxyCheckingStatus }) => {

	const stringFormat = (
		<span>Формат строки -
			<span className={styles.alertDescription}>
				IP:Порт:Логин:Пароль:Тип:Код_страны
			</span>
		</span>
	);

	return (
		<div>
			<Alert
				className={styles.infoAlertWrapper}
				withIcon={true}
				title="Вставьте список прокси"
				variant="info"
				description={stringFormat}
			/>
			{children}
			{proxyCheckingStatus === checkedStatuses.NOT_WORKING &&
				<Alert
					className={styles.warningAlertWrapper}
					title="Добавьте хотя бы один рабочий прокси"
					variant="warning"
					withIcon={true}
				/>
			}
		</div>
	);
};

export default InputFormatHint;


type InputFormatHintType = {
	readonly children: ReactElement
	readonly proxyCheckingStatus: ProxyCheckingStatusType
}