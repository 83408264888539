export const getDateString: GetDateStringType = ({
	dateString,
	delimiter = "-"
}) => dateString?.replace(/(\d+)-(\d+)-(\d+)/, `$3${delimiter}$2${delimiter}$1`);

type GetDateStringType = (params: {
	readonly dateString: string | undefined,
	readonly delimiter?: "-" | "."
}) => string | undefined

