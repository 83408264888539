import { useLogsListContext } from "modules/LogsList/contexts/LogsListContext";
import { FC } from "react";

import styles from "./walletID.module.scss";

const WalletIdentifier: FC<WalletIdentifierType> = ({ wallet_identifier, wallet_id, dataTestId }) => {
	const { setWalletId } = useLogsListContext();

	return (
		<span
			className={styles.walletID}
			onClick={() => setWalletId(wallet_id)}
			data-test-id={dataTestId}
		>
			{wallet_identifier}
		</span>
	);
};

export default WalletIdentifier;

type WalletIdentifierType = {
	readonly wallet_identifier: string
	readonly wallet_id: number
	readonly dataTestId?: string
}
