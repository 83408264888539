// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CounterpartyItem__row-kqw{width:100%}.CounterpartyItem__row-kqw .CounterpartyItem__firstLine-j65{display:flex;height:36px;width:100%;justify-content:start;align-items:center;font-size:14px}.CounterpartyItem__row-kqw .CounterpartyItem__firstLine-j65 .CounterpartyItem__priority-WRA{margin-left:10px}.CounterpartyItem__row-kqw .CounterpartyItem__firstLine-j65 .CounterpartyItem__visibility-GNj{margin-left:10px}.CounterpartyItem__row-kqw .CounterpartyItem__firstLine-j65 .CounterpartyItem__editButton-fRo{margin-left:auto}.CounterpartyItem__row-kqw .CounterpartyItem__firstLine-j65 .CounterpartyItem__editButton-fRo .CounterpartyItem__penIcon-sAq{fill:#697077}.CounterpartyItem__emptyPlaceholder-bRc{text-align:center;padding:20px 0;width:100%;color:#ccc}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/modals/Counterparties/ViewCounterpartyModal/CounterpartyItem/CounterpartyItem.module.scss"],"names":[],"mappings":"AAAA,2BACI,UAAA,CAEA,4DACI,YAAA,CACA,WAAA,CACA,UAAA,CACA,qBAAA,CACA,kBAAA,CACA,cAAA,CAEA,4FACI,gBAAA,CAGJ,8FACI,gBAAA,CAGJ,8FACI,gBAAA,CAEA,6HACI,YAAA,CAMhB,wCACI,iBAAA,CACA,cAAA,CACA,UAAA,CACA,UAAA","sourcesContent":[".row {\n    width: 100%;\n\n    .firstLine {\n        display: flex;\n        height: 36px;\n        width: 100%;\n        justify-content: start;\n        align-items: center;\n        font-size: 14px;\n\n        .priority {\n            margin-left: 10px;\n        }\n    \n        .visibility {\n            margin-left: 10px;\n        }\n    \n        .editButton {\n            margin-left: auto;\n\n            .penIcon {\n                fill: #697077;\n            }\n        }\n    }\n}\n\n.emptyPlaceholder {\n    text-align: center;\n    padding: 20px 0;\n    width: 100%;\n    color: #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `CounterpartyItem__row-kqw`,
	"firstLine": `CounterpartyItem__firstLine-j65`,
	"priority": `CounterpartyItem__priority-WRA`,
	"visibility": `CounterpartyItem__visibility-GNj`,
	"editButton": `CounterpartyItem__editButton-fRo`,
	"penIcon": `CounterpartyItem__penIcon-sAq`,
	"emptyPlaceholder": `CounterpartyItem__emptyPlaceholder-bRc`
};
export default ___CSS_LOADER_EXPORT___;
