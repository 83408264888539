// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecoverWalletModal__successBtn-HYH{background-color:#24a148;color:#fff}.RecoverWalletModal__content-SL7{margin-bottom:36px}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/ArchivedWallets/components/modals/RecoverWalletModal/RecoverWalletModal.module.scss"],"names":[],"mappings":"AAAA,oCACC,wBAAA,CACA,UAAA,CAGD,iCACC,kBAAA","sourcesContent":[".successBtn {\n\tbackground-color: #24a148;\n\tcolor: white;\n}\n\n.content {\n\tmargin-bottom: 36px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successBtn": `RecoverWalletModal__successBtn-HYH`,
	"content": `RecoverWalletModal__content-SL7`
};
export default ___CSS_LOADER_EXPORT___;
