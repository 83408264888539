import { Badge, Loader } from "pay-kit";
import { FC } from "react";

import styles from "./checkingStatus.module.scss";

const CheckingStatus: FC<CheckingStatusType> = ({
	status,
	in_system,
	isChecking
}) => {

	const statusesText = status ? "Работает" : "Не работает";

	const statusesVariants  = status ? "green" : "red";

	const statusType = (
		isChecking ?
			<Loader color="#A2A9B0" /> : (
				<Badge variant={statusesVariants}>
					<div>{statusesText}</div>
				</Badge>
			)
	)

	return (
		<>
			<div className={styles.badgeMaxContent}>
				{statusType}
			</div>
			{in_system && !isChecking &&
				<div className={[styles.badgeMaxContent, styles.inSystemBadge].join(" ")}>
					<Badge variant="orange">
						<div>Существует в системе</div>
					</Badge>
				</div>
			}
		</>
	);
};

export default CheckingStatus;

type CheckingStatusType = {
	readonly status: boolean
	readonly in_system: boolean
	readonly isChecking: boolean

}