// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.columns__id-SiZ{cursor:pointer;width:max-content}.columns__account_number-SOu{cursor:pointer;border-bottom:1px dashed #697077}.columns__account_login-Dk8{color:#009d9a;background-color:#e9fbfb;border-radius:6px;padding:0 4px;overflow:hidden;text-overflow:ellipsis;width:fit-content;max-width:100%}.columns__subRow-UGE{display:flex;justify-content:flex-start;align-items:center;gap:8px}.columns__overflow-Rpq{overflow:hidden;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/ActiveWallets/components/columns/Columns.module.scss"],"names":[],"mappings":"AAAA,iBACC,cAAA,CACA,iBAAA,CAGD,6BACC,cAAA,CACA,gCAAA,CAGD,4BACC,aAAA,CACA,wBAAA,CACA,iBAAA,CACA,aAAA,CACA,eAAA,CACA,sBAAA,CACA,iBAAA,CACA,cAAA,CAGD,qBACC,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,OAAA,CAGD,uBACC,eAAA,CACA,sBAAA","sourcesContent":[".id {\n\tcursor: pointer;\n\twidth: max-content;\n}\n\n.account_number {\n\tcursor: pointer;\n\tborder-bottom: 1px dashed #697077;\n}\n\n.account_login {\n\tcolor: #009D9A;\n\tbackground-color: #E9FBFB;\n\tborder-radius: 6px;\n\tpadding: 0 4px;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\twidth: fit-content;\n\tmax-width: 100%;\n}\n\n.subRow {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\talign-items: center;\n\tgap: 8px;\n}\n\n.overflow {\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"id": `columns__id-SiZ`,
	"account_number": `columns__account_number-SOu`,
	"account_login": `columns__account_login-Dk8`,
	"subRow": `columns__subRow-UGE`,
	"overflow": `columns__overflow-Rpq`
};
export default ___CSS_LOADER_EXPORT___;
