import { Button } from "@paykassma/pay-kit";
import { useTranslation } from "pay-kit";
import { WalletTypes } from "utils/enums";

import styles from "../CreateWalletModal.module.scss";
import { ParserType } from "../utils/enums";
import {
	isEmail,
	isRequired,
	match,
	maxLengthValidation,
	maxValue,
	minLengthValidation,
	minValue,
	onlyDigitsAllowed,
	onlyLatinSymbolsAllowed,
} from "../utils/validators";

export const useSpecificWalletFields = (isCreating: boolean) => {
	const { t } = useTranslation();
	const isWalletType =
		(walletType: string) =>
		({ wallet_type }: any) =>
			wallet_type === walletType;

	return [
		{
			name: "qr_data",
			label: "QR",
			type: "QRReader",
			validation: [isRequired, (value?: string) => (value?.startsWith("0002") ? undefined : t("Incorrect QR"))],
			isRequired: true,
			existsIf: (form) => isCreating && isWalletType(WalletTypes.JAZZCASH_BUSINESS)(form),
			className: styles.qr,
		},
		{
			name: "public_key",
			label: t("Public key"),
			type: "Textarea",
			validation: [isRequired],
			isRequired: true,
			existsIf: isWalletType(WalletTypes.PAYZEN),
		},
		{
			label: t("Bank code"),
			name: "bankcode",
			type: "Repeat",
			existsIf: isWalletType(WalletTypes.SUREPAY),
			isRequired: true,
			validation: [isRequired],
			render: (children, { insertItem, error }) => (
				<>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<h4>{t("Bank codes")}</h4>
						<Button
							data-test-id="R-bmh9d_nYkyYm4KTtX_f"
							data-testid="add-code"
							variant="text-primary"
							onClick={() =>
								insertItem(
									{
										bankcode: "",
										depositIban: "",
									},
									"insertAfter"
								)
							}
						>
							+ {t("Add")}
						</Button>
					</div>
					{error && <div style={{ color: "#dc3545", fontSize: "12px", marginBottom: "8px" }}>{error}</div>}
					{children}
				</>
			),
			elements: [
				{
					type: "Group",
					render: (children, { removeCurrentItem }) => (
						<div
							style={{
								display: "flex",
								gap: "8px",
							}}
						>
							{children}
							<Button
								data-test-id="Bbi-quTx4sUrLnoPCDA_o"
								data-testid="removeCode"
								variant="delete"
								onClick={removeCurrentItem}
							>
								{t("Delete")}
							</Button>
						</div>
					),
					elements: [
						{
							type: "Group",
							render: (children) => (
								<div
									style={{
										flexGrow: "1",
									}}
								>
									{children}
								</div>
							),
							elements: [
								{
									placeholder: t("Bank code"),
									name: "bankcode",
									type: "TextInput",
									validation: [isRequired],
								},
								{
									placeholder: t("IBAN Deposit"),
									name: "depositIban",
									type: "TextInput",
									validation: [isRequired],
								},
							],
						},
					],
				},
			],
		},
		{
			name: "bank_name",
			label: t("Bank name"),
			type: "TextInput",
			validation: [isRequired],
			isRequired: true,
			existsIf: isWalletType(WalletTypes.VIETCOM_BANK),
		},
		{
			type: "Group",
			name: "cashmaal",
			existsIf: isWalletType(WalletTypes.CASHMAAL),
			elements: [
				{
					name: "pin",
					label: "PIN",
					type: "TextInput",
					validation: [isRequired],
					isRequired: true,
				},
				{
					name: "web_id",
					label: "Web ID",
					type: "TextInput",
					isRequired: true,
					validation: [isRequired],
				},
				{
					name: "ipn_key",
					label: t("IPN Key"),
					type: "TextInput",
					isRequired: true,
					validation: [isRequired],
				},
			],
		},
		{
			name: "port",
			label: t("Port"),
			type: "TextInput",
			dependsOn: ["sms_source"],
			isRequired: true,
			existsIf: (form) => isWalletType(WalletTypes.PAY_TM)(form) && form.sms_source === ParserType.SMS_BOX,
			validation: [isRequired],
		},
		{
			name: "merchant_private_key",
			label: t("Merchant secret key"),
			type: "TextInput",
			isRequired: true,
			existsIf: isWalletType(WalletTypes.NAGAD_API),
			validation: [isRequired],
		},
		{
			name: "phrase",
			label: t("Code phrase"),
			type: "TextInput",
			isRequired: true,
			existsIf: isWalletType(WalletTypes.UZCARD_API),
			validation: [isRequired],
		},
		{
			name: "email_sender",
			label: t("Email sender"),
			type: "TextInput",
			isRequired: true,
			existsIf: isWalletType(WalletTypes.UPI_AB),
			validation: [isRequired, isEmail],
		},
		{
			type: "Group",
			dependsOn: ["parser_type"],
			existsIf: (form: any) =>
				isWalletType(WalletTypes.PHONE_PE)(form) && form.parser_type === ParserType.PARSER_PHONE_PE_MOBILE_API,
			elements: [
				{
					name: "mnc",
					label: "MNC",
					type: "TextInput",
					validation: [isRequired, maxLengthValidation(6), minLengthValidation(2), onlyDigitsAllowed],
					isRequired: true,
				},
				{
					name: "network_operator_name",
					label: t("Network Operator Name"),
					type: "TextInput",
					isRequired: true,
					validation: [isRequired, onlyLatinSymbolsAllowed],
				},
				{
					name: "android_id",
					label: "Android ID",
					type: "TextInput",
					isRequired: true,
					validation: [isRequired, onlyLatinSymbolsAllowed],
				},
				{
					name: "android_api_version",
					label: t("Android API Version"),
					type: "TextInput",
					isRequired: true,
					validation: [isRequired],
				},
				{
					name: "android_board",
					label: t("Android board"),
					type: "TextInput",
					isRequired: true,
					validation: [isRequired, onlyLatinSymbolsAllowed],
				},
				{
					name: "android_hardware",
					label: t("Android hardware"),
					type: "TextInput",
					isRequired: true,
					validation: [isRequired, onlyLatinSymbolsAllowed],
				},
				{
					name: "android_serial",
					label: t("Android Serial"),
					type: "TextInput",
					isRequired: true,
					validation: [isRequired, onlyLatinSymbolsAllowed],
				},
				{
					name: "android_manufacturer",
					label: t("Android manufacturer"),
					isRequired: true,
					type: "TextInput",
					validation: [isRequired, match(/^[A-Z]+$/i)],
				},
				{
					name: "wifi_bssid",
					label: "WiFi MAC address",
					type: "TextInput",
					isRequired: true,
					validation: [isRequired, match(/^([A-F\d]{2}:){5}[A-F\d]{2}$/i)],
					placeholder: "00:00:00:00:00:00",
				},
				{
					name: "wifi_ssid",
					label: "WiFi SSID",
					type: "TextInput",
					isRequired: true,
					validation: [isRequired, maxLengthValidation(32)],
				},
				{
					name: "wifi_frequency",
					label: t("WiFi frequency (MHz)"),
					type: "TextInput",
					isRequired: true,
					validation: [isRequired, match(/^(0|[1-9]\d{0,4})$/)],
				},
				{
					name: "latitude",
					label: t("Latitude (degrees)"),
					type: "TextInput",
					isRequired: true,
					validation: [isRequired, match(/^(?:-?(0|[1-9]+0*)(\.\d{1,15})?)?$/), minValue(-90), maxValue(90)],
				},
				{
					name: "longitude",
					label: t("Longitude (degrees)"),
					type: "TextInput",
					isRequired: true,
					validation: [isRequired, match(/^(?:-?(0|[1-9]+0*)(\.\d{1,15})?)?$/), minValue(-180), maxValue(180)],
				},
			],
		},
		{
			name: "callback_source",
			label: t("Callback source"),
			type: "Select",
			options: [
				{
					label: t("Directly from PS"),
					value: "payment_system",
				},
				{
					label: t("Via service gateway"),
					value: "callback_service",
				},
			],
			isRequired: true,
			existsIf: (form) => isWalletType(WalletTypes.NAGAD_API)(form) || isWalletType(WalletTypes.IPAY_API)(form),
			validation: [isRequired],
		},
		{
			name: "callback_url",
			label: t("Callback URL"),
			type: "TextInput",
			isRequired: true,
			existsIf: (form) => form.callback_source === "callback_service" && isWalletType(WalletTypes.NAGAD_API)(form),
			validation: [isRequired, minLengthValidation(10), maxLengthValidation(255)],
		},
		{
			name: "phone_number",
			label: t("Phone number"),
			type: "TextInput",
			isRequired: true,
			validation: [isRequired, match(/^91\d{10}$/)],
			dependsOn: ["integration_type", "parser_type"],
			existsIf: (form) =>
				isWalletType(WalletTypes.UPI_L)(form) &&
				form.integration_type === "upi_l" &&
				form.parser_type === ParserType.FEDNET,
		},
	];
};
