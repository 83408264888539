import { CheckAllMultiAddingProxyType } from "api/proxiesGroup";
import { RowStatesType } from "modules/Proxies/components/UploadedProxiesListTable";
import { useAddingProxiesContext } from "modules/Proxies/contexts/AddingProxiesContext";
import { multiAddingProxiesHelper } from "modules/Proxies/helpers";
import { TextInput } from "pay-kit";
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import * as yup from "yup";

const ProxiesEditableTextField: FC<ProxiesEditableTextFieldType> = ({
	name,
	id,
	classname,
	editableColContent,
	tableEditableRow,
	setIsValid = () => {
	},
	validation
}) => {
	const { setMultiAddingProxies } = useAddingProxiesContext();
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {

		if (validation) {
			let yupShape = yup.string().required("Field is required!");

			if (validation.pattern) yupShape = yupShape.matches(validation.pattern, validation.message);
			if (validation.max) yupShape = yupShape.max(validation.max, validation.message);

			const schema = yup.object().shape({
				[name]: yupShape
			});
			schema
				.validate({ [name]: editableColContent })
				.then((valid) => {
					setIsValid(prevState => ({ ...prevState, [id]: true }));
					setErrorMessage("");

					if (validation?.maxAmount || validation?.minAmount) {
						const amount = validation.maxAmount || validation.minAmount;

						if (parseInt(editableColContent) > Number(amount)) {
							setIsValid(prevState => ({ ...prevState, [id]: false }));
							setErrorMessage(`Значение должно быть числом и не превышать ${amount}!`);

						} else {
							setIsValid(prevState => ({ ...prevState, [id]: true }));
							setErrorMessage("");
						}
					}
				})
				.catch(err => {
					setIsValid(prevState => ({ ...prevState, [id]: false }));
					setErrorMessage(err.message);
				});
		}

	}, [editableColContent]);


	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		setMultiAddingProxies(prevState =>
			multiAddingProxiesHelper({ state: prevState, id, params: { key: name, value } })
		);
	};

	return (
		<div>
			{tableEditableRow[id] ?
				<TextInput
					name={name}
					className={classname}
					value={editableColContent}
					onChange={handleOnChange}
					error={errorMessage}
				/> : editableColContent
			}
		</div>
	);
};

export default ProxiesEditableTextField;

type ProxiesEditableTextFieldType = {
	readonly name: keyof CheckAllMultiAddingProxyType
	readonly id: string
	readonly classname?: string
	readonly editableColContent: string
	readonly setTableEditableRow: Dispatch<SetStateAction<RowStatesType>>
	readonly tableEditableRow: RowStatesType
	readonly setIsValid?: Dispatch<SetStateAction<RowStatesType>>
	readonly validation?: {
		readonly message: string
		readonly pattern?: RegExp,
		readonly max?: number
		readonly maxAmount?: number
		readonly minAmount?: number
	}
}
