import API from "api";
import { SetSmsCodeParams } from "api/walletGroup";
import { Modal } from "pay-kit";
import { Button, TextInput } from "pay-kit";
import React, { useState } from "react";

import styles from "./ActivatePayTM.module.scss";

interface IActivatePayTMProps {
	readonly identifier: SetSmsCodeParams["identifier"];
}

const ActivatePayTM: React.FC<IActivatePayTMProps> = ({ identifier }) => {
	const [sms_code, setSmsCode] = useState<SetSmsCodeParams["sms_code"]>("");
	const [modalIsVisible, setModalVisibility] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const setSmsCodeRequest = () => {
		setIsLoading(true);

		API.wallet
			.setSmsCode({ sms_code, identifier })
			.then((res) => {
				if (res?.status === "ok") {
					setIsLoading(false);
					window.pushAlert({ description: "Телефон подтверждён", type: "success" });
					setModalVisibility(false);
					return;
				} else {
					throw new Error("Unexpected response");
				}
			})
			.catch((err) => {
				setIsLoading(false);
				window.pushAlert({ description: "Не удалось подтвердить телефон", type: "error" });
			});
	};

	return (
		<>
			<Modal isOpen={modalIsVisible} title="Подтверждение телефона" onClose={() => setModalVisibility(false)}>
				<form
					className={styles.form}
					onSubmit={(e) => {
						e.preventDefault();
						setSmsCodeRequest();
					}}
				>
					<div className={styles.inputRow}>
						<div className={styles.label}>Введите код</div>
						<TextInput
							name="sms_code"
							placeholder="Код"
							type="text"
							value={sms_code}
							onChange={(e) => setSmsCode(e.target.value)}
							data-test-id="ActivatePayTM_sms_code"
						/>
					</div>

					<div className={styles.actions}>
						<Button data-test-id="EI47Kf58khL2l41__2Xer" type="submit" onClick={() => {
						}} isLoading={isLoading}>
							Подтвердить
						</Button>
					</div>
				</form>
			</Modal>
			<Button data-test-id="N6frHHFgqLSISOtxoPbQN" onClick={() => setModalVisibility(true)}
				variant="secondary">Активировать</Button>
		</>
	);
};

export default ActivatePayTM;
