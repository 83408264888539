import { ModalProps } from "utils/types";
import { WalletListItem } from "api/walletGroup";
import { StandardResponse } from "api/types";
import { DeleteModal } from "components/Modals/DeleteModal/DeleteModal";

type DeleteWalletModalProps = ModalProps & {
	readonly id: WalletListItem[`id`];
	readonly actions: {
		readonly deleteWallet: (id: WalletListItem[`id`]) => Promise<StandardResponse<void>>;
	};
};

export const DeleteWalletModal = ({ closeModal, id, actions }: DeleteWalletModalProps) => {
	return (
		<DeleteModal
			closeModal={closeModal}
			text={
				<>
					Вы уверены, что хотите удалить кошелек <b>{id}</b>?
				</>
			}
			deleteLabel="Удалить"
			actions={{
				onDelete: () => {
					return actions.deleteWallet(id).then((resp) => {
						if (resp.status === "error") {
							window.pushAlert({
								type: "error",
								title: resp.error_message,
								description: resp.errors.map((e) => e.message).join("\n"),
							});
						} else {
							window.pushAlert({
								type: "success",
								title: "Успех",
								description: `Кошелек ${id} успешно удален`,
							});
						}
					});
				},
			}}
		/>
	);
};
