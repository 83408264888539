// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangeLog__logsFiltersWrapper-aUM{margin-top:4px}.ChangeLog__logsFiltersWrapper-aUM .ChangeLog__logsField-q80{min-width:175px}.ChangeLog__changeLogsTableWrapper-ZAV{margin-top:38px;margin-bottom:38px}.ChangeLog__changeLogsTableWrapper-ZAV table td{vertical-align:top}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_{margin-top:26px;margin-bottom:26px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(1){width:140px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(2){width:121px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(3){width:120px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(4){width:200px}.ChangeLog__changeLogsTableWrapper-ZAV .ChangeLog__changeLogsTable-ax_ col:nth-child(5){width:120px}`, "",{"version":3,"sources":["webpack://./src/modules/ChangeLog/changeLog.module.scss"],"names":[],"mappings":"AAAA,mCACI,cAAA,CAEA,6DACI,eAAA,CAIR,uCACI,eAAA,CACA,kBAAA,CAGF,gDACC,kBAAA,CAIC,uEACI,eAAA,CACA,kBAAA,CAEA,wFACI,WAAA,CAGJ,wFACI,WAAA,CAGJ,wFACI,WAAA,CAGJ,wFACI,WAAA,CAGJ,wFACI,WAAA","sourcesContent":[".logsFiltersWrapper {\n    margin-top: 4px;\n\n    .logsField {\n        min-width: 175px;\n    }\n}\n\n.changeLogsTableWrapper {\n    margin-top: 38px;\n    margin-bottom: 38px;\n\n\ttable {\n\t\ttd {\n\t\t\tvertical-align: top;\n\t\t}\n\t}\n\n    .changeLogsTable {\n        margin-top: 26px;\n        margin-bottom: 26px;\n\n        col:nth-child(1) {\n            width: 140px;\n        }\n\n        col:nth-child(2) {\n            width: 121px;\n        }\n\n        col:nth-child(3) {\n            width: 120px;\n        }\n\n        col:nth-child(4) {\n            width: 200px;\n        }\n\n        col:nth-child(5) {\n            width: 120px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logsFiltersWrapper": `ChangeLog__logsFiltersWrapper-aUM`,
	"logsField": `ChangeLog__logsField-q80`,
	"changeLogsTableWrapper": `ChangeLog__changeLogsTableWrapper-ZAV`,
	"changeLogsTable": `ChangeLog__changeLogsTable-ax_`
};
export default ___CSS_LOADER_EXPORT___;
