import API from "api";
import { DeleteModal } from "components/Modals/DeleteModal/DeleteModal";
import { ModalProps } from "utils/types";
import { WalletListItem } from "api/walletGroup";

type DeleteCounterpartyModalProps = ModalProps & {
	wallet?: WalletListItem;
	counterparty_identifier: string;
	counterpartyId: string;
	onSuccess: () => void;
	closeModal: () => void;
};

export const DeleteCounterpartyModal = ({
	closeModal,
	wallet,
	counterparty_identifier,
	counterpartyId,
	onSuccess,
}: DeleteCounterpartyModalProps) => {
	const onDelete = () => {
		return API.wallet
			.detachCounterparty(wallet?.hash_id as WalletListItem["hash_id"], counterpartyId)
			.then((resp) => {
				if (resp.status === "success") {
					window.pushAlert({
						type: "success",
						description: "Связь кошелька с контрагентом удалена",
					});

					onSuccess();
				}

				return resp;
			})
			.catch((e) => {
				console.error(e);
				window.pushAlert({
					type: "error",
					description: "Ошибка при удалении связи кошелька с контрагентом",
				});
			});
	};

	return (
		<DeleteModal
			closeModal={closeModal}
			text={
				<>
					Вы действительно хотите удалить связь кошелька <b>{wallet?.identifier}</b> с контрагентом <b>{counterparty_identifier}</b>?
				</>
			}
			deleteLabel="Удалить"
			closeLabel="Отмена"
			actions={{
				onDelete,
			}}
		/>
	);
};
