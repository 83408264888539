import { useContext } from "react";
import { Button, Tooltip } from "pay-kit";
import { PenIcon } from "components/Icons/PenIcon";
import { WalletListItem } from "api/walletGroup";
import { WalletContext } from "modules/Wallets/contexts/WalletContext";
import { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import styles from "./Comments.module.scss";

type CommentProps = {
	readonly hash_id: WalletListItem[`hash_id`];
	readonly comment: string | null;
	readonly ctx?: typeof WalletContext | typeof ArchivedWalletContext;
};

const Comment = ({ comment, hash_id, ctx = WalletContext }: CommentProps) => {
	const { openCommentModal } = useContext(ctx);
	const { hasRole } = useAuthContext();
	const commentAccess = hasRole(Roles.WALLETS_COMMENT);

	const handleOpen = () => {
		if (commentAccess) {
			openCommentModal(hash_id, comment);
		}
	};

	return (
		<div onClick={handleOpen} className={styles.commentCell}>
			{comment ? (
				<div className={styles.commentWrapper}>
					<Tooltip tip={comment} preferredSide="bottom">
						<div className={styles.comment}>{comment}</div>
					</Tooltip>
					{commentAccess && (
						<Button variant="secondary" classname={styles.editButton}>
							<PenIcon activeClass={styles.penIcon} />
						</Button>
					)}
				</div>
			) : (
				commentAccess && (
					<Button onClick={handleOpen} variant="link-primary">
						Добавить комментарий
					</Button>
				)
			)}
		</div>
	);
};

export default Comment;
