import { Loader } from "@paykassma/pay-kit";
import API from "api";
import { StandardResponse } from "api/types";
import { useContext, useEffect, useState } from "react";
import ActiveProxy from "./ActiveProxy";
import SelectProxy from "./SelectProxy";
import { EditProxiesModalData, WalletContext } from "modules/Wallets/contexts/WalletContext/WalletContext";
import { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext";

type EditProxiesProps = {
	data: EditProxiesModalData;
	close: () => void;
	attach: (proxy_uuids: string[], wallet_id: string) => Promise<StandardResponse<unknown>>;
	detach: (proxy_uuids: string[], wallet_id: string) => Promise<StandardResponse<unknown>>;
	ctx?: typeof WalletContext | typeof ArchivedWalletContext;
};

type ProxyType = {
	readonly id: string;
	readonly ip: string;
	readonly port: number;
	readonly username: string;
	readonly password: string;
	readonly status: true;
	readonly type: string;
	readonly country_code: string;
	readonly is_checking: boolean;
	readonly created_at: string;
	readonly updates_at: string;
	readonly last_checked_at: null | string;
	readonly wallets: Record<string, string[]>;
};

const EditProxies = (props: EditProxiesProps) => {
	const [proxyLoading, setProxyLoading] = useState<boolean>(false);
	const [allProxies, setAllProxies] = useState<ProxyType[]>([]);
	const [initAttachedProxies, setInitAttachedProxies] = useState<string[]>([]);
	const [attachedProxies, setAttachedProxies] = useState<string[]>([]);
	const [mode, setMode] = useState<"view" | "select">("view");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const canSubmit =
		initAttachedProxies.length !== attachedProxies.length ||
		initAttachedProxies.filter((p) => !attachedProxies.includes(p)).length > 0;

	//TODO: Костыль, вынести фетч кошей в общий контекст
	//@ts-ignore
	const { getWallets } = useContext(props.ctx || WalletContext);

	useEffect(() => {
		setProxyLoading(true);
		API.proxy
			.getProxies({ limit: 0 })
			.then((resp) => {
				if (resp.status === "success") {
					setAllProxies(resp.data as unknown as ProxyType[]);

					return API.wallet.getWalletProxies(props.data.id).then((res) => {
						if (res.status === "success") {
							const attachedProxies = res.data.proxies.map(({ uuid }) => uuid);
							setInitAttachedProxies(attachedProxies);
							setAttachedProxies(attachedProxies);
						} else {
							throw new Error("Unexpected response");
						}
					});
				} else {
					throw new Error("Unexpected response");
				}
			})
			.finally(() => {
				setProxyLoading(false);
			});
	}, []);

	const onSubmit = () => {
		const itemsToAttach = attachedProxies.filter((uuid: string) => !props.data.proxies.includes(uuid));
		const itemsToDetach = props.data.proxies.filter((uuid: string) => !attachedProxies.includes(uuid));

		const promises = [];

		if (itemsToAttach.length > 0) {
			promises.push(props.attach(itemsToAttach, props.data.id));
		}

		if (itemsToDetach.length > 0) {
			promises.push(props.detach(itemsToDetach, props.data.id));
		}

		setIsLoading(true);

		Promise.all(promises)
			.then((resp) => {
				const errResps = resp.filter((r) => r.status === "error");
				if (errResps.length === 0) {
					props.close();

					window.pushAlert({
						description: "Изменения сохранены",
						type: "success",
					});

					setTimeout(() => {
						getWallets();
					}, 1000);
				} else {
					const errs = errResps.map((r) => r.error_message);
					window.pushAlert({
						title: "При выполнения действия произошла ошибка.",
						description: errs[0],
						type: "error",
					});
				}
			})
			.finally(() => setIsLoading(false));
	};

	if (proxyLoading) {
		return (
			<div>
				<Loader color="#A2A9B0" />
			</div>
		);
	}

	const activeProxies = attachedProxies
		.map((attachedId) => allProxies.find((p) => p.id === attachedId))
		.filter((p) => !!p);

	const proxyDeleteHandler = (proxyId: string) =>
		setAttachedProxies((prevState) => prevState.filter((id) => id !== proxyId));

	const proxiesAvailableToSelect = allProxies.filter((proxy) => {
		return !attachedProxies.includes(proxy.id) && proxy.status;
	});

	const proxySelectHandler = (proxies: string[]) => {
		setAttachedProxies((prevState) => [...prevState, ...proxies]);
		setMode("view");
	};

	switch (mode) {
		case "view":
			return (
				<ActiveProxy
					walletIdentifier={props.data.identifier}
					activeProxies={activeProxies}
					goSelectProxy={() => setMode("select")}
					onProxyDelete={proxyDeleteHandler}
					onSubmit={canSubmit ? onSubmit : undefined}
					isLoading={isLoading}
					onCancel={props.close}
				/>
			);
		case "select":
			return (
				<SelectProxy
					proxies={proxiesAvailableToSelect}
					onSelect={proxySelectHandler}
					onCancel={() => setMode("view")}
				/>
			);
		default:
			return null;
	}
};

export default EditProxies;
