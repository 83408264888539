import Api from "api";
import { InitCheckingMultiAddingProxiesType } from "api/proxiesGroup";
import { useState } from "react";


const useInitCheckingMultiAddingProxy = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);

	const create = (params: InitCheckingMultiAddingProxiesType) => {
		setIsLoading(true);
		setError(null);

		const items = [params].map((param) => ({...param, port: +param.port}))

		Api.proxy.initCheckingMultiAddingProxies({ items }).then(res => {
			if (res.status === "success") {
				window.pushAlert({
					description: "Все прокси успешно проверены!",
					type: "success"
				});
			}
			if (res.status === "error") {
				window.pushAlert({
					description: res.error_message,
					type: "error"
				});
			}
		}).catch((err) => {
			console.log(err);
			setError(err);
		}
		).finally(() => {
			setIsLoading(false);
		});
	};

	return ({
		create,
		isLoading,
		error
	});
};

export default useInitCheckingMultiAddingProxy;