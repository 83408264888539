import { ModalProps } from "utils/types";
import { StandardResponse } from "api/types";
import { useEffect, useState } from "react";
import ChangeWalletModal from "modules/Wallets/components/modals/ChangeWalletModal";
import API from "api";
import prepareFormDataForRequest from "../helpers/prepareFormDataForRequest";
import parseAPIResponseErrorsFormat from "../helpers/parseAPIResponseErrorsFormat";

type CreateWalletModalProps = ModalProps & {
	readonly isOpen: boolean;
	readonly actions: {
		readonly createWallet: (form: any) => Promise<StandardResponse<void>>;
	};
	readonly disabled: boolean;
};

type Requisite = {
	readonly code: string;
	readonly name: string;
};

export const CreateWalletModal = ({ closeModal, isOpen, actions }: CreateWalletModalProps) => {
	const [respErrors, setErrors] = useState<any | null>(null);
	const [requisites, setRequisites] = useState<Requisite[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const clearExternalErrors = () => {
		setErrors(null);
	};

	useEffect(() => {
		if (!isOpen) {
			clearExternalErrors();
		}
	}, []);

	const initForm = {
		is_standard: false,
	};

	const onSuccess = () => {
		window.pushAlert({
			title: "Успех",
			description: "Кошелек создан",
			type: "success",
		});
	};

	const onSubmit = (form: Partial<any>) => {
		return actions.createWallet(prepareFormDataForRequest(form)).then((resp) => {
			if (resp.status === "error") {
				if (resp.error_message) {
					window.pushAlert({ description: resp.error_message, type: "error" });
				}

				if (resp.errors.length) {
					setErrors(parseAPIResponseErrorsFormat(resp.errors));
				}
			}

			return resp;
		});
	};

	const fetchRequisites = (wallet_type: string) => {
		setIsLoading(true);

		API.walletType
			.getManualPSRequisites(wallet_type)
			.then((resp) => {
				if (resp.status === "success") {
					setRequisites(resp.data.requisite_codes.map((rc) => ({ name: rc, code: rc })));
				}
			})
			.catch((err) => console.error(err))
			.finally(() => setIsLoading(false));
	};

	return (
		<ChangeWalletModal
			adminPanelType="superadmin"
			loading={isLoading}
			isOpen={isOpen}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			initForm={initForm}
			closeModal={closeModal}
			actions={actions}
			isCreating
			externalErrors={respErrors}
			clearExternalErrors={clearExternalErrors}
			requisites={requisites}
			fetchRequisites={fetchRequisites}
		/>
	);
};

export default CreateWalletModal;
