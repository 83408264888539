import React, { useCallback, useContext, useState } from "react";
import { ModalData } from "../WalletContext/WalletContext";
import { WalletListItem } from "api/walletGroup";
import AddCounterpartyModal from "modules/Wallets/components/modals/Counterparties/AddCounterpartyModal";
import { Counterparty } from "api/counterpartyGroup";
import { Modal } from "@paykassma/pay-kit";
import EditCounterpartyModal from "modules/Wallets/components/modals/Counterparties/EditCounterpartyModal";
import ViewCounterpartyModal from "modules/Wallets/components/modals/Counterparties/ViewCounterpartyModal";
import { useCounterparty } from "modules/Counterparty/hooks/useCounterparty";
import { DeleteCounterpartyModal } from "modules/Wallets/components/modals/Counterparties/DeleteCounterpartyModal";
import { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext/ArchivedWalletContext";
import { WalletContext } from "modules/Wallets/contexts/WalletContext";
import styles from "./WalletCounterpartyContext.module.scss";

type WalletCounterpartyContextType = {
	openAddCounterpartyModal: (wallet_id: WalletListItem[`hash_id`]) => void;
	openListCounterpartyModal: (wallet_id: WalletListItem[`hash_id`]) => void;
	openDeleteCounterpartyModal: (wallet_id: WalletListItem[`hash_id`], counterparty_id: Counterparty[`id`]) => void;
	openEditCounterpartyModal: (wallet_id: WalletListItem[`hash_id`], counterparty_id: Counterparty[`id`]) => void;
};

const initialState: WalletCounterpartyContextType = {} as WalletCounterpartyContextType;

export const WalletCounterpartyContext = React.createContext<WalletCounterpartyContextType>(initialState);
WalletCounterpartyContext.displayName = "WalletCounterpartyContext";

type CounterpartyAddModalData = ModalData & {
	wallet_id: WalletListItem[`hash_id`];
};

type CounterpartyChangeModalData = CounterpartyAddModalData & {
	counterparty_id: Counterparty[`id`];
};

type WalletCounterpartyContextProps = {
	children: React.ReactNode;
	ctx: typeof ArchivedWalletContext | typeof WalletContext;
	walletTypes: "active" | "archived" | "disabled";
};

export default function WalletCounterpartyContextProvider(props: WalletCounterpartyContextProps) {
	const { counterparties } = useCounterparty({});

	const getIdentifierById = (uuid: string) => {
		return counterparties?.find((c) => c.id === uuid)?.identifier || ``;
	};

	const { wallets, refreshWalletByHashId } = useContext(props.ctx);

	const [listCounterpartyModalData, setListCounterpartyModalData] = useState<CounterpartyAddModalData>({
		open: false,
		wallet_id: ``,
	});

	const [addCounterpartyModalData, setAddCounterpartyModalData] = useState<CounterpartyAddModalData>({
		open: false,
		wallet_id: ``,
	});

	const [editCounterpartyModalData, setEditCounterpartyModalData] = useState<CounterpartyChangeModalData>({
		open: false,
		wallet_id: ``,
		counterparty_id: ``,
	});

	const [deleteCounterpartyModalData, setDeleteCounterpartyModalData] = useState<CounterpartyChangeModalData>({
		open: false,
		wallet_id: ``,
		counterparty_id: ``,
	});

	const addCounterpartySuccessHandler = (wallet_id: WalletListItem["hash_id"], counterpartyId: Counterparty["id"]) => {
		closeAddCounterpartyModal();

		refreshWalletByHashId(wallet_id, () => {
			openEditCounterpartyModal(addCounterpartyModalData.wallet_id, counterpartyId);
		});
	};

	const detachCounterpartySuccessHandler = () => {
		refreshWalletByHashId(deleteCounterpartyModalData.wallet_id, () => {
			closeDeleteCounterpartyModal();
		});
	};

	const openAddCounterpartyModal = (wallet_id: WalletListItem[`hash_id`]) => {
		setAddCounterpartyModalData({
			open: true,
			wallet_id,
		});
	};

	const closeAddCounterpartyModal = () => {
		setAddCounterpartyModalData({
			open: false,
			wallet_id: ``,
		});
	};

	const openEditCounterpartyModal = (wallet_id: WalletListItem[`hash_id`], counterparty_id: Counterparty[`id`]) => {
		setEditCounterpartyModalData({
			open: true,
			wallet_id,
			counterparty_id,
		});
	};

	const closeEditCounterpartyModal = () => {
		setEditCounterpartyModalData({
			open: false,
			wallet_id: ``,
			counterparty_id: ``,
		});
	};

	const openDeleteCounterpartyModal = (wallet_id: WalletListItem[`hash_id`], counterparty_id: Counterparty[`id`]) => {
		setDeleteCounterpartyModalData({
			open: true,
			wallet_id,
			counterparty_id,
		});
	};

	const closeDeleteCounterpartyModal = () => {
		setDeleteCounterpartyModalData({
			open: false,
			wallet_id: ``,
			counterparty_id: ``,
		});
	};

	const openListCounterpartyModal = (wallet_id: WalletListItem[`hash_id`]) => {
		setListCounterpartyModalData({
			open: true,
			wallet_id,
		});
	};

	const closeListCounterpartyModal = () => {
		setListCounterpartyModalData({
			open: false,
			wallet_id: ``,
		});
	};

	const getWalletById = useCallback(
		(wallet_id: string) => {
			return wallets.find((w) => w.hash_id === wallet_id);
		},
		[wallets]
	);

	const value: WalletCounterpartyContextType = {
		openAddCounterpartyModal,
		openDeleteCounterpartyModal,
		openEditCounterpartyModal,
		openListCounterpartyModal,
	};

	return (
		<WalletCounterpartyContext.Provider value={value}>
			<Modal title="Добавление контрагента" isOpen={addCounterpartyModalData.open} onClose={closeAddCounterpartyModal}>
				<AddCounterpartyModal
					wallet={getWalletById(addCounterpartyModalData.wallet_id)}
					onSuccess={addCounterpartySuccessHandler}
				/>
			</Modal>
			<Modal
				title={getIdentifierById(editCounterpartyModalData.counterparty_id)}
				isOpen={editCounterpartyModalData.open}
				onClose={closeEditCounterpartyModal}
			>
				<EditCounterpartyModal
					wallet={getWalletById(editCounterpartyModalData.wallet_id)}
					counterparty_id={editCounterpartyModalData.counterparty_id}
					onSuccess={() => {
						refreshWalletByHashId(editCounterpartyModalData.wallet_id);
						closeEditCounterpartyModal();
					}}
				/>
			</Modal>
			<Modal
				title="Редактирование контрагентов"
				isOpen={listCounterpartyModalData.open}
				onClose={closeListCounterpartyModal}
				className={styles.listCounterpartyModal}
			>
				<ViewCounterpartyModal
					wallet={getWalletById(listCounterpartyModalData.wallet_id)}
					defaultTab={props.walletTypes === "active" ? "active" : "disabled"}
				/>
			</Modal>
			<Modal
				title="Удаление контрагента"
				isOpen={deleteCounterpartyModalData.open}
				onClose={closeDeleteCounterpartyModal}
			>
				<DeleteCounterpartyModal
					counterpartyId={deleteCounterpartyModalData.counterparty_id}
					counterparty_identifier={getIdentifierById(deleteCounterpartyModalData.counterparty_id)}
					wallet={getWalletById(deleteCounterpartyModalData.wallet_id)}
					onSuccess={detachCounterpartySuccessHandler}
					closeModal={closeDeleteCounterpartyModal}
				/>
			</Modal>
			{props.children}
		</WalletCounterpartyContext.Provider>
	);
}
