// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArchivedWalletContext__editProxiesModal-ApG{width:560px}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/contexts/ArchivedWalletContext/ArchivedWallet.module.scss"],"names":[],"mappings":"AAAA,6CACC,WAAA","sourcesContent":[".editProxiesModal {\n\twidth: 560px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editProxiesModal": `ArchivedWalletContext__editProxiesModal-ApG`
};
export default ___CSS_LOADER_EXPORT___;
