import { Loader } from "pay-kit";

import styles from "./EditableComment.module.scss";
import CheckMarkSVG from "./icons/Checkmark.svg";
import CloseSVG from "./icons/Close.svg";
import DeleteSVG from "./icons/Delete.svg";
import EditSVG from "./icons/Edit.svg";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";

interface IEditableCommentProps {
	readonly isLoading: boolean,
	readonly editingModeOn: boolean,
	readonly setEditingModeOn: (isOn: boolean) => void,
	readonly initialState: string | null,
	readonly value: string | null,
	readonly onChange: (value: string) => void,
	readonly onSubmit: (value: string | null) => void;
	readonly maxLength?: number
	readonly className?: string
}

const EditableComment = ({
	className,
	isLoading,
	editingModeOn,
	setEditingModeOn,
	initialState,
	value,
	maxLength,
	onChange,
	onSubmit
}: IEditableCommentProps) => {
	const { hasRole } = useAuthContext();
	const commentAccess = hasRole(Roles.WALLETS_COMMENT);

	if (isLoading) {
		return <Loader />;
	}

	if (editingModeOn === false) {
		if (initialState === null && commentAccess) {
			return (
				<div className={styles.updateComment}>
					<button data-test-id="sZxbXK_iPcYkDQFPbbrpD" className={styles.addComment}
						onClick={() => setEditingModeOn(true)}>
						+ Добавить
					</button>
				</div>
			);
		}

		return (
			<div className={[styles.updateComment, className].join(" ")}>
				<div className={styles.comment}>{initialState}</div>
				{commentAccess && (
					<div className={styles.actions}>
						<button data-test-id="KrAGI0TQIc-o9yDd5XJNF" className={styles.iconButton}
							onClick={() => setEditingModeOn(true)}>
							<EditSVG />
						</button>
						<button data-test-id="PvHzeLURD-nRQiK4KDUmc" className={styles.iconButton} onClick={() => onSubmit(null)}>
							<DeleteSVG />
						</button>
					</div>
				)}
			</div>
		);
	}

	const isChanged = initialState !== value;

	return (
		<form
			className={styles.updateComment}
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit(value);
			}}
		>
			<textarea
				defaultValue={value === null ? "" : value}
				onChange={(e) => onChange(e.target.value)}
				name="comment"
				rows={2}
				data-test-id="UpdateComment_comment"
				maxLength={maxLength}
			/>
			<div className={styles.actions}>
				<button className={styles.iconButton} type="submit" disabled={!isChanged} data-test-id="UpdateComment_submit">
					<CheckMarkSVG />
				</button>
				<button data-test-id="rebDKkC9Be1N_S5SJXbpb" className={styles.iconButton}
					onClick={() => setEditingModeOn(false)}>
					<CloseSVG />
				</button>
			</div>
		</form>
	);
};

export default EditableComment;
