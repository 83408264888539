/* eslint-disable react/prop-types */
import { Button } from "pay-kit";
import styles from "./ActiveProxy.module.scss";
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import ProxyListItem from "../ProxyListItem";
import type { ProxyType } from "../types";

type ActiveProxyPropsType = {
	walletIdentifier: string;
	goSelectProxy: () => void;
	activeProxies: ProxyType[];
	onProxyDelete: (id: string) => void;
	onSubmit?: () => void;
	isLoading: boolean;
	onCancel: () => void;
};

const ActiveProxy: React.FC<ActiveProxyPropsType> = ({
	walletIdentifier,
	goSelectProxy,
	activeProxies,
	onProxyDelete,
	onSubmit,
	onCancel,
	isLoading,
}) => {
	return (
		<div className={styles.activeProxy}>
			<div className={styles.walletNumber}>
				<div className={styles.label}>Номер кошелька</div>
				<div className={styles.value}>{walletIdentifier}</div>
			</div>
			<div className={styles.heading}>
				<span>Прокси</span>
				<Button onClick={goSelectProxy} variant="text-primary">
					<PlusIcon className={styles.plusIcon} />
					Добавить
				</Button>
			</div>
			<div className={styles.rows}>
				{activeProxies.map((proxy) => (
					<ProxyListItem data={proxy} key={proxy.id} onDelete={() => onProxyDelete(proxy.id)} />
				))}
			</div>

			<div className={styles.actions}>
				<Button variant="secondary" onClick={onCancel}>
					Отмена
				</Button>
				<Button variant="primary" onClick={onSubmit} disabled={!onSubmit} isLoading={isLoading}>
					Сохранить
				</Button>
			</div>
		</div>
	);
};

export default ActiveProxy;
