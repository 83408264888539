import API from "api";
import { DeleteHintsParams, DeleteHintsType, HintType } from "api/defaultSettingsGroup";
import { StandardResponse } from "api/types";
import { useCallback, useState } from "react";

const useRemoveHints = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const remove: Remove = useCallback((id, params, onSuccess) => {
		setIsLoading(true);
		setError(null);

		API.defaultSetting
			.deleteHints(id, params)
			.then((res) => {
				if (res.status === "success") {
					onSuccess && onSuccess(res);
					window.pushAlert({
						title: "Успех",
						type: "success",
						description: `Текстовые подсказки успешно обновлены`,
					});
				} else if (res.error_message) {
					window.pushAlert({
						title: "Ошибка",
						type: "error",
						description: res.error_message,
					});
				} else {
					throw new Error("Unexpected response in useCreateHints");
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		remove,
		isLoading,
		error,
	};
};

export default useRemoveHints;

type Remove = (
	id: HintType["uuid"],
	params: DeleteHintsParams,
	onSuccess?: (res: StandardResponse<DeleteHintsType>) => void
) => void;
