import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { isIPHOSTRequired, isIPHOSTValid, isPortValid, isRequired, proxyTypes } from "modules/Proxies/data";
import { Loader, PayKitForm, ScrollBox } from "pay-kit";
import { ReactElement } from "react";

import styles from "./updateProxyModal.module.scss";

const UpdateProxy = () => {
	const { updateProxy, countryList, proxyItem, proxyID, setIsProxyLoading, isWalletListLoading, isProxyLoading } =
		useProxyContext();

	const countryOptions = countryList?.map((elem) => ({ label: elem.code, value: elem.code })) || [];
	const typeOptions = proxyTypes.map((elem) => ({ label: elem, value: elem }));

	const onSubmit = (formState: formStateType) => {
		setIsProxyLoading(true);
		return updateProxy(
			{
				...formState,
				port: +formState.port,
			},
			proxyID
		);
	};

	const isLoading = isProxyLoading || (!!proxyItem?.wallet_hash_ids?.length && isWalletListLoading);

	const SCHEMA: any = [
		{
			type: "Group",
			render: (element: ReactElement) => (
				<ScrollBox scrollDirection={"vertical"} className={styles.scrollBox}>
					{element}
				</ScrollBox>
			),
			elements: [
				{
					type: "TextInput",
					name: "ip",
					label: "IP|HOST",
					isRequired: true,
					validation: [isIPHOSTRequired, isIPHOSTValid],
					className: styles.ere,
				},
				{
					type: "TextInput",
					name: "port",
					label: "Порт",
					isRequired: true,
					validation: [isRequired, isPortValid],
				},
				{
					type: "TextInput",
					name: "username",
					label: "Логин",
					isRequired: true,
					validation: [isRequired],
				},
				{
					type: "TextInput",
					name: "password",
					label: "Пароль",
					isRequired: true,
					htmlType: "password",
					validation: [isRequired],
				},
				{
					type: "Select",
					name: "type",
					label: "Тип",
					options: typeOptions,
					isRequired: true,
					validation: [isRequired],
				},
				{
					type: "Select",
					name: "country_code",
					label: "Страна",
					options: countryOptions,
					isRequired: true,
					validation: [isRequired],
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => <div className={styles.submitButton}>{element}</div>,
			elements: [
				{
					name: "submit",
					type: "SubmitButton",
					label: "Обновить",
					onSubmit: onSubmit,
					isLoading: isLoading,
				},
			],
		},
	];

	const INITIAL_STATE = {
		ip: proxyItem?.ip,
		password: proxyItem?.password,
		port: proxyItem?.port,
		type: proxyItem?.type,
		country_code: proxyItem?.country_code,
		username: proxyItem?.username,
	};

	if (!Object.keys(proxyItem || {}).length || isWalletListLoading)
		return (
			<div className={styles.updateLoader}>
				<Loader color="#A2A9B0" />
			</div>
		);

	const updateProxyModalWrapperStyle = isLoading
		? [styles.UpdateProxyModalWrapper, styles.disableContent].join(" ")
		: styles.UpdateProxyModalWrapper;

	return (
		<div className={updateProxyModalWrapperStyle}>
			<PayKitForm.Builder schema={SCHEMA} initialState={INITIAL_STATE} />
		</div>
	);
};

export default UpdateProxy;

export type formStateType = {
	readonly ip: string;
	port: string;
	readonly username: string;
	readonly password: string;
	readonly type: typeof proxyTypes;
	readonly country_code: string;
	readonly wallet_hash_ids?: readonly {
		readonly type: string;
	}[];
};
