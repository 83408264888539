import { Table, Button } from "pay-kit";
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import RightModal from "components/Modals/RightModal";
import SideModalContent from "modules/Wallets/components/SideModalContent";
import { WalletContext } from "modules/Wallets/contexts/WalletContext";
import WalletsFilter from "modules/Wallets/components/Filter";
import { useContext, useState } from "react";
import activeWalletsColumns, { subRow } from "./components/columns";
import ReloadButton from "components/ReloadButton";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import TabNav from "../components/TabNav";
import styles from "./ActiveWallets.module.scss";

export const ActiveWallets = () => {
	const { wallets, isLoading, openCreateWalletModal, getWallets, paginationInfo, limit, setLimit, setCurrentPage } =
		useContext(WalletContext);
	const [walletId, setWalletId] = useState<string | null>(null);
	const { hasRole } = useContext(AuthContext);
	const createAccess = hasRole(Roles.WALLET_CREATE);
	const privateAccess = hasRole(Roles.WALLET_NUMBER);
	const balanceAccess = hasRole(Roles.WALLET_BALANCE);
	const counterpartyAccess = hasRole(Roles.COUNTERPARTY_LIST);

	const handleCreate = () => {
		openCreateWalletModal(false);
	};

	const paginator =
		(paginationInfo && {
			currentPage: paginationInfo?.offset / limit + 1,
			setCurrentPage: (page: number | string) => setCurrentPage(page as number),
			limit,
			setLimit: (newLimit: number) => setLimit(newLimit),
			lastAvailablePage: Math.ceil(paginationInfo?.total / limit) || 1,
			className: styles.paginator,
			bottomPaginatorProps: {
				className: styles.bottomPaginator,
			},
			prefixElement: <ReloadButton data-test-id="" isLoading={false} onClick={() => getWallets()} />,
		}) ||
		undefined;

	const columns = activeWalletsColumns({
		setWalletId,
		privateAccess,
		balanceAccess,
		counterpartyAccess,
	});

	return (
		<>
			<div className={styles.topNav}>
				<TabNav />

				{createAccess && (
					<Button data-test-id="utPFmhErEvdNo1tRc9xHo" onClick={handleCreate} variant="primary">
						<PlusIcon />
						Создать кошелек
					</Button>
				)}
			</div>

			<WalletsFilter walletsStatus="worked" />

			<RightModal
				bodyClassName={styles.detailedViewWrapper}
				heading="Кошелёк"
				onShow={() => null}
				isVisible={walletId !== null}
				onClose={() => setWalletId(null)}
			>
				<SideModalContent walletId={walletId} />
			</RightModal>

			<Table
				rowKey="hash_id"
				data={wallets}
				columns={columns}
				isLoading={isLoading}
				skeleton={{ rowsCount: limit }}
				className={styles.table}
				subRowRender={subRow}
				paginator={paginator}
			/>
		</>
	);
};

export default ActiveWallets;
