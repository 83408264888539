// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form__formRow-Ww0{display:flex;justify-content:space-between;gap:20px}.form__deleteSelect-y9l{width:22px;margin-top:-20px}.form__removeButton-_E1{background:initial;height:initial;min-width:initial;min-height:max-content;padding:initial;line-height:initial}.form__field-Q9B{flex-grow:1}.form__scrollbox-VMf{height:550px}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/EditWallets/components/form/styles.module.scss"],"names":[],"mappings":"AAAA,mBACC,YAAA,CACA,6BAAA,CACA,QAAA,CAGD,wBACC,UAAA,CACA,gBAAA,CAGD,wBACC,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,sBAAA,CACA,eAAA,CACA,mBAAA,CAGD,iBACC,WAAA,CAGD,qBACC,YAAA","sourcesContent":[".formRow {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tgap: 20px;\n}\n\n.deleteSelect {\n\twidth: 22px;\n\tmargin-top: -20px;\n}\n\n.removeButton {\n\tbackground: initial;\n\theight: initial;\n\tmin-width: initial;\n\tmin-height: max-content;\n\tpadding: initial;\n\tline-height: initial;\n}\n\n.field {\n\tflex-grow: 1;\n}\n\n.scrollbox {\n\theight: 550px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formRow": `form__formRow-Ww0`,
	"deleteSelect": `form__deleteSelect-y9l`,
	"removeButton": `form__removeButton-_E1`,
	"field": `form__field-Q9B`,
	"scrollbox": `form__scrollbox-VMf`
};
export default ___CSS_LOADER_EXPORT___;
