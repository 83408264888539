/* eslint-disable react/prop-types */
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import type { ProxyType } from "../types";
import styles from "./ProxyListItem.module.scss";
import WalletsList from "./WalletsList";
import { Badge } from "pay-kit";

type ProxyListItemPropsType = {
	readonly data: ProxyType;
	readonly onSelect?: () => void;
	readonly onDelete?: () => void;
	readonly isSelected?: boolean;
	readonly className?: string;
};

const ProxyListItem: React.FC<ProxyListItemPropsType> = ({ isSelected, onSelect, onDelete, data, className }) => {
	const doesNotWork = !data.status;

	const cls = [
		styles.proxyListItem,
		isSelected ? styles.isSelected : "",
		doesNotWork ? styles.doesNotWork : "",
		className ? className : "",
	].join(" ");

	const adaptToWalletsList = (wallets: Array<{ hash_id: string; identifier: string; wallet_type_code: string }>) => {
		const obj: Record<string, string[]> = {};

		wallets.forEach((wallet) => {
			obj[wallet.wallet_type_code] = [wallet.hash_id];
		});

		return obj;
	};

	return (
		<div className={cls} onClick={onSelect}>
			<div className={styles.mainData}>
				<div className={styles.country}>{data.country_code}</div>
				<div className={styles.type}>{data.type}</div>
				<div className={styles.ip}>
					{data.ip}:{data.port}
					{doesNotWork && <Badge variant="red">Не работает</Badge>}
				</div>
			</div>
			<WalletsList wallets={Array.isArray(data.wallets) ? adaptToWalletsList(data.wallets) : data.wallets} />
			{isSelected && <CheckIcon />}
			{onDelete && (
				<button className={styles.removeButton} onClick={onDelete}>
					<TrashIcon />
				</button>
			)}
		</div>
	);
};

export default ProxyListItem;

const CheckIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={styles.checkIcon}
		>
			<path
				d="M16.0458 5.4856C16.3299 5.78703 16.3158 6.26169 16.0144 6.54579L8.08456 14.0197C7.74829 14.3366 7.22042 14.3269 6.89609 13.9977L4.21576 11.2774C3.92504 10.9823 3.92856 10.5075 4.22361 10.2167C4.51867 9.92602 4.99353 9.92954 5.28424 10.2246L7.51839 12.4921L14.9856 5.45421C15.287 5.17011 15.7617 5.18416 16.0458 5.4856Z"
				fill="#0072C3"
			/>
		</svg>
	);
};
