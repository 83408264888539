import { WalletListItem } from "api/walletGroup";
import Money from "components/Money";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import { Tooltip } from "pay-kit";
import StatusBadges from "modules/Wallets/components/StatusBadges";
import Actions from "../Actions";
import styles from "./Columns.module.scss";
import Comment from "../../../components/Comments/index";
import Counterparties from "modules/Wallets/components/Counterparties";
import he from "he";
import { WalletColumnProps } from "modules/Wallets/ArchivedWallets/components/columns";
import { WalletState } from "modules/Wallets/components/modals/ChangeWalletModal/utils/enums";
import CopyTextButton from "components/CopyTextButton";
import { prepareAdditionalInfoString } from "../../../utils";
import EllipsisTooltip from "../../../components/EllipsisTooltip";

const idCol = {
	title: "Хеш",
	dataIndex: "hash_id",
	key: "hash_id",
	render: ({ hash_id }: WalletListItem) => (
		<div data-test-id="-lIkmZj6QhYFZC_jXZILB" className={styles.id}>
			{hash_id}
		</div>
	),
};

const walletTypeCol = {
	title: "Тип",
	dataIndex: "type",
	key: "type",
	render: ({ wallet_type_code }: WalletListItem) => <PaymentSystemLogo id={wallet_type_code} />,
};

const identifierCol = (setWalletId: (hash_id: string) => void) => ({
	title: "Номер",
	dataIndex: "identifier",
	key: "identifier",
	render: ({ identifier, hash_id }: WalletListItem) => (
		<EllipsisTooltip
			text={identifier}
			dataTestId="identifier"
			onClick={() => setWalletId(hash_id)}
			canCopy
			maxCharLength={26}
		/>
	),
});

const balanceCol = {
	title: "Баланс",
	dataIndex: "balance",
	key: "balance",
	render: ({ balance, currency_code, extra_balances }: WalletListItem) => {
		if (extra_balances && extra_balances.length > 0) {
			return extra_balances.map(({ currency: balanceCurrency, balance: balanceAmount }, key) => {
				return (
					<div key={key}>
						{balanceCurrency}: {balanceAmount}
					</div>
				);
			});
		}

		return <Money direction="ingoing" amount={balance} currencyCode={currency_code} withSign={false} />;
	},
};

const extraInfoCol = {
	title: "Доп. сведения",
	dataIndex: "upi_addresses",
	key: "upi_addresses",
	render: ({ upi_addresses, display_identifier }: WalletListItem) => {
		return (
			<EllipsisTooltip
				text={prepareAdditionalInfoString(upi_addresses, display_identifier)}
				canCopy
				maxLines={2}
				maxCharLength={20}
			/>
		);
	},
};

const commentCol = {
	title: "Комментарий",
	dataIndex: "comment",
	key: "comment",
	render: ({ comment, hash_id }: WalletListItem) => <Comment comment={comment} hash_id={hash_id} />,
};

const counterpartyCol = {
	title: "Контрагенты",
	dataIndex: "counterparties",
	key: "counterparties",
	render: ({ counterparties, hash_id }: WalletListItem) => (
		<Counterparties counterparties={counterparties} id={hash_id} />
	),
};

export const subRow = ({ hash_id, type, cookie_type, counterparties, identifier, proxies }: WalletListItem) => {
	const state: WalletState[] = [
		...new Set(
			(counterparties || [])
				.map((counterparty) => counterparty?.counterparty_wallet_settings?.status)
				.reduce((prev, curr) => [...prev, ...curr], [])
		),
	] as WalletState[];

	return (
		<div className={styles.subRow}>
			<StatusBadges state={state} />
			<Actions id={hash_id} proxies={proxies} identifier={identifier} type={type} cookie_type={cookie_type} />
		</div>
	);
};

const disabledWalletsColumns = (props: WalletColumnProps) => {
	const arr = [idCol, walletTypeCol, identifierCol(props.setWalletId)];

	if (props.balanceAccess) {
		arr.push(balanceCol);
	}

	arr.push(...[extraInfoCol, commentCol]);

	if (props.counterpartyAccess) {
		arr.push(counterpartyCol);
	}

	return arr;
};

export default disabledWalletsColumns;
