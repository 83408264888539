import { useEffect } from "react";
import useURLState from "utils/hooks/useURLState";
import { redirect } from './utils';
export { EXTERNAL_DASHBOARD_REDIRECTOR_PATH } from './constants';

const ExternalDashboardRedirector = () => {
	const [link] = useURLState("link", "");
	const [domain] = useURLState("domain", "");
	const [id] = useURLState("id", "");

	useEffect(() => {
		redirect(link, id);
	}, []);

	return <div>Redirecting to: {domain}</div>;
};

export default ExternalDashboardRedirector;