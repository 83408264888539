/* eslint-disable ternary/no-unreachable */
import { Button } from "pay-kit";
import React from "react";

import { FieldPropsType } from "../Field";
import ActiveFilter from "./ActiveFilter";
import styles from "./ActiveFilters.module.scss";

export type ActiveFiltersPropsType = {
	readonly fields: readonly Omit<FieldPropsType, "value" | "onChange" | "onClear">[];
	readonly values: any;
	readonly onFilterClear: (name: string) => void;
	readonly onClearAll: () => void;
};

const ActiveFilters: React.FC<ActiveFiltersPropsType> = ({ fields, values, onFilterClear, onClearAll }) => {
	return (
		<div className={styles.activeFilters}>
			<Button
				data-test-id="JAoApFvDhmWb8LRbbieSz"
				onClick={onClearAll}
				type="button"
				variant="delete"
				classname={styles.clearAll}
			>
				Очистить фильтр
			</Button>

			{fields.map((field) => {
				const value = values[field.name];

				if (value !== undefined) {
					switch (field.component) {
						case "textInput":
							return (
								<ActiveFilter
									data-test-id="useless-data-test-id"
									key={field.name}
									label={field.label || field.placeholder}
									value={value}
									onClick={() => onFilterClear(field.name)}
								/>
							);
						case "select":
							return (
								<ActiveFilter
									data-test-id="useless-data-test-id"
									key={field.name}
									label={field.label || field.placeholder}
									value={
										field.options
											? field.options.find((option) => option.value === value)?.["label"] || value
											: undefined
									}
									onClick={() => onFilterClear(field.name)}
								/>
							);
						case "lazySelect":
							return (
								<ActiveFilter
									data-test-id="useless-data-test-id"
									key={field.name}
									label={field.placeholder}
									value={
										field.lazyProps.defaultOptions
											? field.lazyProps.defaultOptions.find((option) => option.value === value)?.["label"] || value.label
											: undefined
									}
									onClick={() => onFilterClear(field.name)}
								/>
							);
						case "checkboxGroup":
							return (
								<ActiveFilter
									data-test-id="useless-data-test-id"
									key={field.name}
									label={field.label || field.placeholder}
									value={
										field.options
											? limitItems(
													field.options.filter((option) => value.includes(option.value)).map(({ label }) => label),
													3,
													(rest) => `и еще +${rest}`
											  ).join(", ")
											: undefined
									}
									onClick={() => onFilterClear(field.name)}
								/>
							);
						case "dateRange":
							return (
								<ActiveFilter
									data-test-id="useless-data-test-id"
									key={field.name}
									label={field.label || field.placeholder}
									value={value ? `${value.from} – ${value.to}` : undefined}
									onClick={() => onFilterClear(field.name)}
								/>
							);
						case "date":
							return (
								<ActiveFilter
									data-test-id="useless-data-test-id"
									key={field.name}
									label={field.label || field.placeholder}
									value={value ? value : undefined}
									onClick={() => onFilterClear(field.name)}
								/>
							);
						default:
							return null;
					}
				}

				return null;
			})}
		</div>
	);
};

export default ActiveFilters;

const limitItems = (items: readonly string[], maxCount: number, renderRest: (num: number) => void) => {
	if (maxCount && items.length > maxCount) {
		return [...items.slice(0, 3), renderRest(items.length - maxCount)];
	}

	return items;
};
