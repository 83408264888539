import { Button, ScrollBox } from "@paykassma/pay-kit";
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import { useProxyContext, WalletsEditData } from "modules/Proxies/contexts/ProxyContext";
import { FC } from "react";
import { uniqueID } from "utils/uniqueID";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useFormik } from "formik";
import SelectBuilderButton from "modules/Proxies/components/SelectBuilderButton";
import styles from "./styles.module.scss";
import { useLazyLoadWallets } from "utils/hooks/useLazyLoadWallets";
import { createIdsCollection, filterSelectedOptions } from "../../utils";
import { StandardResponse } from "api/types";
import LazySelect from "components/LazySelect";

type Props = {
	data: WalletsEditData;
	attach: (uuids: any[], id: any) => Promise<StandardResponse<any>>;
	detach: (uuids: any[], id: any) => Promise<StandardResponse<any>>;
	close: () => void;
};

const formSchema = z.object({
	wallet_hash_ids: z.array(
		z.object({
			value: z.string({ required_error: "Поле обязательно для заполнения" }),
			label: z.string(),
			id: z.string(),
		})
	),
});

export type Form = z.infer<typeof formSchema>;

export const EditWalletsForm: FC<Props> = ({ data, attach, detach, close }) => {
	const { getProxies } = useProxyContext();

	const onSubmit = (form: Form) => {
		const walletsIdsCollection = createIdsCollection(form, data);
		const itemsToAttach = walletsIdsCollection.findTheAddedWallets();
		const itemsToDetach = walletsIdsCollection.findDeletedWallets();

		const promises = [];

		if (itemsToAttach.length > 0) {
			promises.push(attach(itemsToAttach, data.id));
		}

		if (itemsToDetach?.length > 0) {
			promises.push(detach(itemsToDetach, data.id));
		}

		Promise.all(promises).then((resp) => {
			const errResps = resp.filter((r) => r.status === "error");

			if (errResps.length === 0) {
				window.pushAlert({
					title: "Успех",
					description: "Данные успешно сохранены",
					type: "success",
				});

				setTimeout(() => {
					getProxies();
					close();
				}, 1000);
			} else {
				const errs = errResps.map((r) => r.error_message);
				window.pushAlert({
					title: "Ошибка",
					description: errs[0],
					type: "error",
				});
			}
		});
	};

	const { values, setFieldValue, handleSubmit, errors } = useFormik<Form>({
		initialValues: {
			wallet_hash_ids: data.wallets?.map((w) => ({ value: w.hash_id, label: w.identifier, id: uniqueID() })),
		},
		validationSchema: toFormikValidationSchema(formSchema),
		onSubmit: onSubmit,
		validateOnChange: false,
		validateOnBlur: false,
	});

	const { isLoadingWallets, walletsNumbersOptions, onMenuScrollToBottom, loadOptions } = useLazyLoadWallets({ removed: 0 });

	const selectedWalletIds = values.wallet_hash_ids.map((w) => w.value);
	const filteredOptions = filterSelectedOptions(walletsNumbersOptions, selectedWalletIds);

	return (
		<form onSubmit={handleSubmit}>
			<SelectBuilderButton
				data-test-id="selectBuilderButton"
				insertItem={(value) =>
					setFieldValue("wallet_hash_ids", [...values.wallet_hash_ids, { ...value, id: uniqueID() }])
				}
			/>

			<ScrollBox className={styles.scrollbox}>
				{values.wallet_hash_ids.map((w, i) => (
					<div key={w.id} className={styles.formRow}>
						<LazySelect
							className={styles.field}
							defaultOptions={filteredOptions}
							isLoading={!values?.wallet_hash_ids[i].value && isLoadingWallets}
							defaultValue={{ label: w.label, value: w.value }}
							placeholder="Кошелек"
							onMenuScrollToBottom={onMenuScrollToBottom}
							loadOptions={loadOptions}
							onChange={(v) => {
								setFieldValue(`wallet_hash_ids[${i}]`, { ...v, id: w.id });
							}}
							error={errors?.wallet_hash_ids?.[i]?.value}
						/>
						<Button
							data-test-id="removeButton"
							onClick={() => {
								setFieldValue(
									"wallet_hash_ids",
									values.wallet_hash_ids.filter(({ id }) => id !== w.id)
								);
							}}
							classname={styles.removeButton}
						>
							<TrashIcon activeClass={styles.deleteSelect} />
						</Button>
					</div>
				))}
			</ScrollBox>
			<Button type="submit">Сохранить</Button>
		</form>
	);
};
