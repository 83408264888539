// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentModal__wrapper-fAO{display:flex;width:380px;flex-direction:column}.CommentModal__wrapper-fAO>button{margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/modals/CommentModal/CommentModal.module.scss"],"names":[],"mappings":"AAAA,2BACI,YAAA,CACA,WAAA,CACA,qBAAA,CAEA,kCACI,gBAAA","sourcesContent":[".wrapper {\n    display: flex;\n    width: 380px;\n    flex-direction: column;\n\n    > button {\n        margin-left: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CommentModal__wrapper-fAO`
};
export default ___CSS_LOADER_EXPORT___;
