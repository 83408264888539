import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useTranslation } from "pay-kit";
import DisabledWallets from "modules/Wallets/DisabledWallets/DisabledWallets";
import React, {useEffect} from "react";
import WalletContextProvider, { WalletContext } from "modules/Wallets/contexts/WalletContext";
import WalletCounterpartyContextProvider from "modules/Wallets/contexts/WalletCounterpartyContext/WalletCounterpartyContext";

const DisabledWalletsPage: React.FC = () => {
	const { t } = useTranslation();
	const { hasRole } = useAuthContext();

	useEffect(() => {
		document.title = t("Disabled wallets");
		hasRole(Roles.WALLETS_DISABLED, { redirectToErrorPage: true });
	}, [t, hasRole]);

	return (
		<WalletContextProvider type="disabled">
			<WalletCounterpartyContextProvider ctx={WalletContext} walletTypes="disabled">
				<DisabledWallets/>
			</WalletCounterpartyContextProvider>
		</WalletContextProvider>
	);
};

export default DisabledWalletsPage;
