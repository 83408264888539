import LogsList from "modules/LogsList";
import { useEffect } from "react";


const LogsListPage = () => {
	useEffect(() => {
		document.title = "Логи кошелька";
	}, []);

	return <LogsList />;
};


export default LogsListPage;
