import { DeleteModal } from "components/Modals/DeleteModal/DeleteModal";
import { ModalProps } from "utils/types";
import { WalletListItem } from "api/walletGroup";
import { OldResponse } from "api/types";

type ArchiveWalletModalProps = ModalProps & {
	readonly id: WalletListItem[`id`];
	readonly actions: {
		readonly archiveWallet: (id: WalletListItem[`id`]) => Promise<OldResponse<void>>;
	};
};

export const ArchiveWalletModal = ({ closeModal, id, actions }: ArchiveWalletModalProps) => {
	return (
		<DeleteModal
			closeModal={closeModal}
			text={
				<>
					Вы уверены, что хотите архивировать кошелек <b>{id}</b>?
				</>
			}
			deleteLabel="Архивировать"
			actions={{
				onDelete: () => {
					return actions.archiveWallet(id).then(() => {
						window.pushAlert({
							type: "success",
							title: "Успех",
							description: `Кошелек ${id} успешно архивирован`,
						});
					});
				},
			}}
		/>
	);
};
