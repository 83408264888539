import API from "api";
import { useCallback, useState } from "react";
import { errorsMap } from "utils/enums";

import type { ProxyType } from "../utils/types";

const useGetProxies = () => {
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [list, setList] = useState<readonly ProxyType[]>([]);

	const load = useCallback(() => {
		setError(null);
		setIsLoading(true);

		// eslint-disable-next-line promise/catch-or-return
		API.proxy
			.getProxies()
			.then((resp) => {
				if (resp.status === "ok") {
					return setList(resp.proxies);
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				console.error(err);
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return {
		load,
		list,
		isLoading,
		error,
	};
};

export default useGetProxies;
