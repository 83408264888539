import "./RefreshBlock.sass";

import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import { RefreshIcon } from "components/Icons/RefreshIcon";
import React from "react";
import { Button } from "@paykassma/pay-kit";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";

export type RefreshBlockProps = {
	readonly addBtnLabel: string;
	readonly onAddClick: () => void;
	readonly onRefreshClick?: () => void;
};

export const RefreshBlock = ({ addBtnLabel, onAddClick, onRefreshClick }: RefreshBlockProps) => {
	const { hasRole } = useAuthContext();

	return (
		<div className="refresh__wrapper">
			{hasRole(Roles.PROXY_CREATE) && (
				<Button data-test-id="utPFmhErEvdNo1tRc9xHo" onClick={onAddClick} variant="primary">
					<PlusIcon />
					<span
						style={{
							marginLeft: "8px",
						}}
					>
						{addBtnLabel}
					</span>
				</Button>
			)}
			{onRefreshClick && (
				<Button data-test-id="dWuq5cY_1HvD4SHE3PAGq" variant="text-primary" onClick={onRefreshClick}>
					<RefreshIcon />
					<span
						style={{
							marginLeft: "8px",
						}}
					>
						Обновить
					</span>
				</Button>
			)}
		</div>
	);
};
