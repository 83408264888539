import { Button, Loader, Tabs } from "@paykassma/pay-kit";
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import { WalletListItem } from "api/walletGroup";
import { WalletCounterpartyContext } from "modules/Wallets/contexts/WalletCounterpartyContext";
import { useCounterparty } from "modules/Counterparty/hooks/useCounterparty";
import { useContext, useState } from "react";
import CounterpartyItem from "./CounterpartyItem";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import styles from "./ViewCounterpartyModal.module.scss";

type ViewCounterpartyModalProps = {
	wallet?: WalletListItem;
	defaultTab: "active" | "disabled";
};

const ViewCounterpartyModal = ({ wallet, defaultTab }: ViewCounterpartyModalProps) => {
	const [activeTab, setActiveTab] = useState<"disabled" | "active">(defaultTab);

	const { openAddCounterpartyModal, openDeleteCounterpartyModal, openEditCounterpartyModal } =
		useContext(WalletCounterpartyContext);

	const { hasRole } = useAuthContext();

	const hasRoleToEditCounterparties = hasRole(Roles.WALLET_UPDATE) || hasRole(Roles.WALLET_SETTINGS);

	const { isListLoading, counterparties } = useCounterparty({});

	if (!wallet || isListLoading) return <Loader />;

	const activeCounterparties = wallet?.counterparties.filter((c) => c.counterparty_wallet_settings.is_active) || [];
	const disabledCounterparties = wallet?.counterparties.filter((c) => !c.counterparty_wallet_settings.is_active) || [];

	const renderCounterpartiesList = (list: WalletListItem["counterparties"]) => {
		if (list.length === 0) {
			return <div className={styles.emptyPlaceholder}>Список пуст</div>;
		}

		return list.map((item) => {
			const counterpartyData = counterparties?.find((c) => c.id === item.counterparty_uuid);
			const onEditHandler = hasRoleToEditCounterparties
				? () => openEditCounterpartyModal(wallet.hash_id, item.counterparty_uuid)
				: undefined;
			const isVisible = activeTab === "active" && item.counterparty_wallet_settings.plugin_status;

			return (
				<CounterpartyItem
					key={item.counterparty_uuid}
					identifier={counterpartyData?.identifier as string}
					onDelete={() => openDeleteCounterpartyModal(wallet.hash_id, item.counterparty_uuid)}
					onEdit={onEditHandler}
					priority={item.counterparty_wallet_settings.priority}
					status={item.counterparty_wallet_settings.status}
					isVisible={isVisible}
					displayVisibilityStatus={activeTab === "active"}
				/>
			);
		});
	};

	return (
		<>
			<Tabs
				value={activeTab}
				onChange={(v) => setActiveTab(v)}
				className={styles.tabs}
				tabs={[
					{
						value: "active",
						label: (
							<>
								Рабочие <span className={styles.badge}>{activeCounterparties.length}</span>
							</>
						),
					},
					{
						value: "disabled",
						label: (
							<>
								Отключенные <span className={styles.badge}>{disabledCounterparties.length}</span>
							</>
						),
					},
				]}
			/>

			<div className={styles.items}>
				{
					{
						active: renderCounterpartiesList(activeCounterparties),
						disabled: renderCounterpartiesList(disabledCounterparties),
					}[activeTab]
				}
			</div>

			<div className={styles.actions}>
				{hasRole(Roles.WALLET_COUNTERPARTY_MANAGE) && (
					<Button variant="primary" classname={styles.add} onClick={() => openAddCounterpartyModal(wallet.hash_id)}>
						<PlusIcon /> Добавить контрагента
					</Button>
				)}
			</div>
		</>
	);
};

export default ViewCounterpartyModal;
