import { ProxiesType } from "api/proxiesGroup";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { Badge, Loader } from "pay-kit";
import { FC } from "react";

import styles from "./proxyStatus.module.scss";

const ProxyStatus: FC<ProxyStatusType> = ({ id, status }) => {
	const { proxyCheckingState } = useProxyContext();

	const statusesText = {
		false: "Не работает",
		true: "работает"
	};

	const statusesVariants = {
		false: "red",
		true: "green"
	} as const;

	let statusKey: ProxiesType["status"];

	switch (proxyCheckingState && proxyCheckingState[id]?.workingStatus) {
		case "FAILED":
			statusKey = 0;
			break;
		case "SUCCEEDED":
			statusKey = 1;
			break;
		default:
			statusKey = status;
	}

	if (proxyCheckingState && proxyCheckingState[id]?.isLoading) return <Loader color="#A2A9B0" />;

	return (
		<div className={styles.statusBadge}>
			<Badge variant={statusesVariants[statusKey]}>
				{statusesText[statusKey]}
			</Badge>
		</div>
	);
};

export default ProxyStatus;

type ProxyStatusType = {
	readonly id: number
	readonly status: 0 | 1
}
