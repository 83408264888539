
import React, {useEffect} from "react";
import HiddenSettings from "modules/Hidden/Settings";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";

const ActiveWalletsPage: React.FC = () => {
	const { hasRole } = useAuthContext();

	hasRole(Roles.HIDDEN_SETTINGS_UPDATE, { redirectToErrorPage: true });
	useEffect(() => {
		document.title = "Скрытые настройки";
	}, []);

	return (
		<>
			<HiddenSettings/>
		</>
	);
};

export default ActiveWalletsPage;