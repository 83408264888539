// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddingProxiesContext__proxyLoaderModal-IL8.AddingProxiesContext__proxyLoaderModal-IL8{max-width:1340px}.AddingProxiesContext__proxyLoaderModal-IL8.AddingProxiesContext__proxyLoaderModal-IL8 .AddingProxiesContext__proxyLoader-IZQ{width:100%;display:flex;justify-content:center;align-items:center;overflow:hidden;font-weight:400;font-size:14px;color:#697077}.AddingProxiesContext__proxyLoaderModal-IL8.AddingProxiesContext__proxyLoaderModal-IL8 .AddingProxiesContext__proxyLoader-IZQ .AddingProxiesContext__proxyLoaderText-uEs{margin-left:8px}.AddingProxiesContext__UploadedProxiesListTableModal-jlu.AddingProxiesContext__UploadedProxiesListTableModal-jlu{max-width:1340px}.AddingProxiesContext__multiAddedProxyModal-Srv{max-width:1200px}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/contexts/AddingProxiesContext/addingProxiesContext.module.scss"],"names":[],"mappings":"AAAA,uFACC,gBAAA,CAEA,8HACC,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CACA,aAAA,CAEA,yKACC,eAAA,CAKH,iHACC,gBAAA,CAGD,gDACC,gBAAA","sourcesContent":[".proxyLoaderModal.proxyLoaderModal {\n\tmax-width: 1340px;\n\n\t.proxyLoader {\n\t\twidth: 100%;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\toverflow: hidden;\n\t\tfont-weight: 400;\n\t\tfont-size: 14px;\n\t\tcolor: #697077;\n\n\t\t.proxyLoaderText {\n\t\t\tmargin-left: 8px;\n\t\t}\n\t}\n}\n\n.UploadedProxiesListTableModal.UploadedProxiesListTableModal {\n\tmax-width: 1340px;\n}\n\n.multiAddedProxyModal {\n\tmax-width: 1200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"proxyLoaderModal": `AddingProxiesContext__proxyLoaderModal-IL8`,
	"proxyLoader": `AddingProxiesContext__proxyLoader-IZQ`,
	"proxyLoaderText": `AddingProxiesContext__proxyLoaderText-uEs`,
	"UploadedProxiesListTableModal": `AddingProxiesContext__UploadedProxiesListTableModal-jlu`,
	"multiAddedProxyModal": `AddingProxiesContext__multiAddedProxyModal-Srv`
};
export default ___CSS_LOADER_EXPORT___;
