// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Transactions__transactions-HAq{margin-top:24px}.Transactions__transactions-HAq .Transactions__idField-WId{width:220px}.Transactions__transactions-HAq .Transactions__caseField-uv1{width:220px}.Transactions__transactions-HAq .Transactions__counterpartyField-jlZ{width:220px}.Transactions__transactions-HAq .Transactions__transactionField-sc5{width:220px}.Transactions__transactions-HAq [class^=DatePicker__datePickerWrapper]{right:auto !important;transform:translateX(-277px)}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/Transactions/Index.module.scss"],"names":[],"mappings":"AAAA,gCACE,eAAA,CAEA,2DACE,WAAA,CAGF,6DACE,WAAA,CAGF,qEACE,WAAA,CAGF,oEACE,WAAA,CAKF,uEACE,qBAAA,CACA,4BAAA","sourcesContent":[".transactions {\n  margin-top: 24px;\n\n  .idField {\n    width: 220px;\n  }\n\n  .caseField {\n    width: 220px;\n  }\n\n  .counterpartyField {\n    width: 220px;\n  }\n\n  .transactionField {\n    width: 220px;\n  }\n\n  // TODO:\n  // BUG fix PAY-18264 – remove after UI-kit fix\n  [class^=\"DatePicker__datePickerWrapper\"] {\n    right: auto !important;\n    transform: translateX(-277px);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactions": `Transactions__transactions-HAq`,
	"idField": `Transactions__idField-WId`,
	"caseField": `Transactions__caseField-uv1`,
	"counterpartyField": `Transactions__counterpartyField-jlZ`,
	"transactionField": `Transactions__transactionField-sc5`
};
export default ___CSS_LOADER_EXPORT___;
