import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useTranslation } from "pay-kit";
import ArchivedWallets from "modules/Wallets/ArchivedWallets";
import React, {useEffect} from "react";
import ArchivedWalletContextProvider, { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext";
import WalletCounterpartyContextProvider from "modules/Wallets/contexts/WalletCounterpartyContext/WalletCounterpartyContext";

const ArchivedWalletsPage: React.FC = () => {
	const {t} = useTranslation();
	const { hasRole } = useAuthContext();

	useEffect(() => {
		document.title = t("Archived wallets");
		hasRole(Roles.ARCHIVED_WALLETS_VIEW, { redirectToErrorPage: true });
	}, [t, hasRole]);

	return (
		<ArchivedWalletContextProvider>
			<WalletCounterpartyContextProvider ctx={ArchivedWalletContext} walletTypes="archived">
				<ArchivedWallets/>
			</WalletCounterpartyContextProvider>
		</ArchivedWalletContextProvider>
	);
};

export default ArchivedWalletsPage;
