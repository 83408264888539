import { fetchOldApi } from "api/fetch";
import { EXTERNAL_DASHBOARD_REDIRECTOR_PATH } from "./constants";

declare global {
	interface Window {
		readonly config: {
			readonly apiUrl: string;
		};
	}
}

export const getAPIURL = () => window.config.apiUrl;

const buildRedirectURL = (referral_id: string) => `${getAPIURL()}/counterparty/login?referral_id=${referral_id}`;

export const buildRedirectorURL = (link: string, domain: string, id: string) => {
	const urlWithParams = new URL(`${window.location.origin}/${EXTERNAL_DASHBOARD_REDIRECTOR_PATH}`);
	urlWithParams.searchParams.append("link", link);
	urlWithParams.searchParams.append("domain", domain);
	urlWithParams.searchParams.append("id", id);

	return urlWithParams.toString();
};

const getRefId = async (link: string, counterparty_id: string) =>
	fetchOldApi<{ referral_id: string }>({
		url: link,
		method: `POST`,
		params: {
			counterparty_id,
		},
	}).then((resp) => {
		if (resp.status === "ok") {
			return resp?.referral_id;
		} else {
			throw new Error("Unexpected response");
		}
	});

export const redirect = async (link: string, id: string) => {
	const refId = await getRefId(link, id);
	const redirectURL = buildRedirectURL(refId);
	window.open(redirectURL, "_self");
};
