// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Requisites__requisitesWrapper-klZ{margin-top:24px}.Requisites__requisitesWrapper-klZ .Requisites__addBtn-vhS{margin-bottom:24px}.Requisites__requisitesWrapper-klZ .Requisites__idField-nNH{width:220px}.Requisites__requisitesWrapper-klZ .Requisites__fraudCasesField-q46{width:220px}.Requisites__requisitesWrapper-klZ .Requisites__typeField-Lk3{width:220px}.Requisites__requisitesWrapper-klZ .Requisites__valueField-Fey{width:220px}.Requisites__requisitesWrapper-klZ [class^=DatePicker__datePickerWrapper]{right:auto !important;transform:translateX(-277px)}`, "",{"version":3,"sources":["webpack://./src/modules/BlackList/Requisites/Index.module.scss"],"names":[],"mappings":"AAAA,mCACE,eAAA,CAEA,2DACE,kBAAA,CAGF,4DACE,WAAA,CAGF,oEACE,WAAA,CAGF,8DACE,WAAA,CAGF,+DACE,WAAA,CAKF,0EACE,qBAAA,CACA,4BAAA","sourcesContent":[".requisitesWrapper {\n  margin-top: 24px;\n\n  .addBtn {\n    margin-bottom: 24px;\n  }\n\n  .idField {\n    width: 220px;\n  }\n\n  .fraudCasesField {\n    width: 220px;\n  }\n\n  .typeField {\n    width: 220px;\n  }\n\n  .valueField {\n    width: 220px;\n  }\n\n  // TODO:\n  // BUG fix PAY-18264 – remove after fixing in UI-kit\n  [class^=\"DatePicker__datePickerWrapper\"] {\n    right: auto !important;\n    transform: translateX(-277px);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requisitesWrapper": `Requisites__requisitesWrapper-klZ`,
	"addBtn": `Requisites__addBtn-vhS`,
	"idField": `Requisites__idField-nNH`,
	"fraudCasesField": `Requisites__fraudCasesField-q46`,
	"typeField": `Requisites__typeField-Lk3`,
	"valueField": `Requisites__valueField-Fey`
};
export default ___CSS_LOADER_EXPORT___;
