// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddCounterpartyModal__addCounterpartyForm-o71{width:460px}.AddCounterpartyModal__addCounterpartyForm-o71 .AddCounterpartyModal__actions-_N0{display:flex;justify-content:end}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/modals/Counterparties/AddCounterpartyModal/AddCounterpartyModal.module.scss"],"names":[],"mappings":"AAAA,+CACI,WAAA,CAEA,kFACI,YAAA,CACA,mBAAA","sourcesContent":[".addCounterpartyForm {\n    width: 460px;\n\n    .actions {\n        display: flex;\n        justify-content: end;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addCounterpartyForm": `AddCounterpartyModal__addCounterpartyForm-o71`,
	"actions": `AddCounterpartyModal__actions-_N0`
};
export default ___CSS_LOADER_EXPORT___;
