import API from "api";
import { WalletStatus, WalletStatusType } from "api/walletGroup";
import Filter from "components/Filter";
import { WalletContext } from "modules/Wallets/contexts/WalletContext";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { useContext, useEffect, useMemo, useState } from "react";

import styles from "./Filter.module.scss";
import { useCounterparty } from "modules/Counterparty/hooks/useCounterparty";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";

type WalletsFilterProps = {
	ctx?: any;
	walletsStatus: WalletStatusType;
};

const WalletsFilter = (props: WalletsFilterProps) => {
	const { walletTypes } = useContext(WalletTypesContext);
	const { filter, setFilter } = useContext(props.ctx || WalletContext);

	const { hasRole } = useContext(AuthContext);
	const privateViewAccess = hasRole(Roles.WALLET_NUMBER);
	const counterpartyAccess = hasRole(Roles.COUNTERPARTY_LIST);

	const [statuses, setStatuses] = useState<readonly WalletStatus[]>([]);

	const { counterparties, isListLoading, fetchCounterparties } = useCounterparty({
		group: null,
	});

	useEffect(() => {
		API.wallet.getPossibleWalletStatuses(props.walletsStatus, "ru").then((resp) => {
			if (resp.status === "success") {
				setStatuses(resp.data);
			}
		});
	}, [props.walletsStatus]);

	const fields = useMemo(() => {
		const arr: any[] = [
			{
				name: `hash_id`,
				placeholder: "Хеш",
				component: "textInput",
				className: styles.field,
			},
			{
				name: `type`,
				placeholder: "Тип",
				component: "select",
				options: [
					{
						value: ``,
						label: `Все`,
					},
					...walletTypes.map((walletType) => ({
						value: walletType.code,
						label: walletType.name,
					})),
				],
				className: styles.field,
			},
		];

		if (privateViewAccess) {
			arr.push(
				...[
					{
						name: `identifier`,
						placeholder: "Номер",
						component: "textInput",
						className: styles.field,
					},
					{
						name: `username`,
						placeholder: "Логин",
						component: "textInput",
						className: styles.field,
					},
					{
						name: `email_username`,
						placeholder: "Email",
						component: "textInput",
						className: styles.field,
					},
				]
			);
		}

		arr.push(
			...[
				{
					name: `display_identifier`,
					placeholder: "Реквизит платежа",
					component: "textInput",
					className: styles.field,
				},
				{
					name: `state`,
					placeholder: "Статус",
					component: "select",
					options: [
						{
							label: `Все`,
							value: ``,
						},
						...statuses.map((status) => ({
							label: status.text,
							value: status.code,
						})),
					],
					className: styles.field,
				},
			]
		);

		if (counterpartyAccess) {
			arr.push({
				name: `counterparty_uuid`,
				placeholder: "Контрагент",
				component: "select",
				options: [
					{
						value: ``,
						label: `Все`,
					},
					...(counterparties || []).map((counterparty) => ({
						value: counterparty.id,
						label: counterparty.identifier,
					})),
				],
				className: styles.field,
			});
		}

		return arr;
	}, [privateViewAccess, walletTypes.length, counterparties?.length, statuses.length]);

	return <Filter values={filter} fields={fields} onApply={setFilter} classNames={{ wrapper: styles.filter }} />;
};

export default WalletsFilter;
