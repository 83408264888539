import { WalletType } from "api/walletGroup";
import debounce from "lodash/debounce";


/**
 * Трансформирует ответ от апи в список опций для селекта
 *
 * @param wallets Список кошельков от апи
 * @returns Форматированный список опций
 */
export const getWalletsNumbersOptions = (wallets: Array<WalletType>): Array<{ value: string; label: string }> =>
	wallets?.map((wallet) => ({
		value: `${wallet.hash_id}`,
		label: wallet.identifier,
	}));

/**
 * Трансформирует ответ от апи в список опций для селекта
 *
 * @param wallets Список кошельков от апи
 * @returns Форматированный список опций
 */
export const getWalletsIdentifierOptions = (wallets: Array<WalletType>): Array<{ value: string; label: string }> =>
	wallets?.map((wallet) => ({
		value: `${wallet.identifier}`,
		label: wallet.identifier,
	}));

/**
 * Позволяет сделать асинхронный дебаунс
 *
 * При работе с обычным debounce, Async React Select не отображает опции
 * Поэтому нужна вот такая обертка
 * https://github.com/lodash/lodash/issues/4815
 *
 * @param func Коллбэк
 * @param wait Количество мс для дебаунса
 * @returns функцию, оборачивающую переданный коллбэк
 */
export function asyncDebounce(func, wait) {
	const debounced = debounce(async (resolve, reject, bindSelf, args) => {
		try {
			const result = await func.bind(bindSelf)(...args);
			resolve(result);
		} catch (error) {
			reject(error);
		}
	}, wait);

	// This is the function that will be bound by the caller, so it must contain the `function` keyword.
	function returnFunc(...args) {
		return new Promise((resolve, reject) => {
			debounced(resolve, reject, this, args);
		});
	}

	return returnFunc;
}
