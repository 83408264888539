// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditCounterpartyModal__limits-PnB{margin-top:18px}.EditCounterpartyModal__limits-PnB h4{margin-bottom:8px}.EditCounterpartyModal__limits-PnB>div{margin-bottom:12px}.EditCounterpartyModal__notAllowed-jIi{cursor:not-allowed}.EditCounterpartyModal__disabled-pPB{pointer-events:none}.EditCounterpartyModal__childrenWrapper-yFs{margin-bottom:12px}.EditCounterpartyModal__actions-U2x{display:flex;justify-content:end}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/modals/Counterparties/EditCounterpartyModal/EditCounterpartyModal.module.scss"],"names":[],"mappings":"AAAA,mCACI,eAAA,CAEA,sCACI,iBAAA,CAGJ,uCACI,kBAAA,CAIR,uCACI,kBAAA,CAGJ,qCACI,mBAAA,CAGJ,4CACI,kBAAA,CAGJ,oCACI,YAAA,CACA,mBAAA","sourcesContent":[".limits {\n    margin-top: 18px;\n\n    h4 {\n        margin-bottom: 8px;\n    }\n\n    & > div {\n        margin-bottom: 12px;\n    }\n}\n\n.notAllowed {\n    cursor: not-allowed;\n}\n\n.disabled {\n    pointer-events: none;\n}\n\n.childrenWrapper {\n    margin-bottom: 12px;\n}\n\n.actions {\n    display: flex;\n    justify-content: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"limits": `EditCounterpartyModal__limits-PnB`,
	"notAllowed": `EditCounterpartyModal__notAllowed-jIi`,
	"disabled": `EditCounterpartyModal__disabled-pPB`,
	"childrenWrapper": `EditCounterpartyModal__childrenWrapper-yFs`,
	"actions": `EditCounterpartyModal__actions-U2x`
};
export default ___CSS_LOADER_EXPORT___;
