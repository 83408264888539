import { Panel } from "pay-kit";
import { Outlet } from "react-router-dom";

export default function WalletsPage() {
	return (
		<Panel title="Кошельки">
			<Outlet />
		</Panel>
	);
}
