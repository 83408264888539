// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filter__field-CDs{flex:1}.Filter__walletField-MY2{min-width:180px}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/Filter/Filter.module.scss"],"names":[],"mappings":"AAAA,mBACC,MAAA,CAKD,yBACC,eAAA","sourcesContent":[".field {\n\tflex: 1;\n\t// width: 100%;\n\t// max-width: 170px;\n}\n\n.walletField {\n\tmin-width: 180px;\n}\n\n.select {\n\t// min-width: 180px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `Filter__field-CDs`,
	"walletField": `Filter__walletField-MY2`
};
export default ___CSS_LOADER_EXPORT___;
