import { ProxiesType } from "api/proxiesGroup";
import IDCol from "modules/Proxies/components/IDCol";
import ProxyActions from "modules/Proxies/components/ProxiesTableColumns/components/ProxyActions";
import ProxyStatus from "modules/Proxies/components/ProxiesTableColumns/components/ProxyStatus";
import WalletAmountColTitle from "modules/Proxies/components/WalletAmountColTitle";

import styles from "./proxiesTableColumns.module.scss";
import WalletAmount from "../WalletAmount";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";

export const useProxiesTableColumns = () => {
	const { hasRole } = useAuthContext();

	const idCol = {
		title: "Индекс",
		dataIndex: "id",
		key: "id",
		render: ({ id }: ProxiesType) => <IDCol id={id} />,
	};
	
	const countryCol = {
		title: "Страна",
		dataIndex: "country_code",
		key: "country_code",
	};
	
	const ipCol = {
		title: "IP",
		dataIndex: "ip",
		key: "ip",
	};
	
	const portCol = {
		title: "Порт",
		dataIndex: "port",
		key: "port",
	};
	
	const loginCol = {
		title: "Логин",
		dataIndex: "username",
		key: "username",
	};
	
	const walletAmountCol = {
		title: <WalletAmountColTitle />,
		dataIndex: "wallets",
		key: "wallets",
		render: ({ wallets, ip, port, id, type }: ProxiesType & { wallets: string[] }) => {
			return <WalletAmount type={type} id={id} wallets={wallets} ip={ip} port={port} />;
		},
		isVisible: hasRole(Roles.WALLET_LIST) && hasRole(Roles.PROXY_UPDATE),
	};
	
	const typeCol = {
		title: "Тип",
		dataIndex: "type",
		key: "type",
	};
	
	const lastCheckDateCol = {
		title: "Дата проверки",
		dataIndex: "last_checked_at",
		key: "last_checked_at",
		render: ({ last_checked_at }: ProxiesType) =>
			last_checked_at ? last_checked_at : <div className={styles.emptyPlaceholder}>–</div>,
	};
	
	const statusCol = {
		title: "Статус",
		dataIndex: "status",
		key: "status",
		render: ({ status, id }: ProxiesType) => <ProxyStatus id={id} status={status} />,
	};
	
	const actionsCol = {
		title: "Действия",
		dataIndex: "in_system",
		key: "in_system",
		render: ({ id, port, ip }: ProxiesType) => <ProxyActions id={id} ip={ip} port={port} />,
	};

	const proxiesTableColumns = [
		idCol,
		countryCol,
		ipCol,
		portCol,
		loginCol,
		walletAmountCol,
		typeCol,
		lastCheckDateCol,
		statusCol,
		actionsCol,
	];

	return {proxiesTableColumns: proxiesTableColumns.filter(col => col.isVisible || col.isVisible === undefined)};
};

