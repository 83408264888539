import { fetchApi } from "./fetch";

const currenciesGroup = {
	getCurrenciesList: (code: string) => fetchApi<CurrenciesListItem[]>({ url: "currencies/list", method: "GET" }), // code filter doesn't work
	createNew: (params: CreateNewCurrencyType) => fetchApi({ url: `currencies/`, params, method: "POST" }),
	update: (params: CreateNewCurrencyType) => fetchApi({ url: `currencies/`, params, method: "PUT" }),
	updateRates: (codes: string[]) => fetchApi({ url: "currencies/init-rate-update/", params: { codes }, method: "GET" }),
	remove: (codes: string[]) => fetchApi({ url: `currencies/`, params: { codes }, method: "DELETE" }),
};

export default currenciesGroup;

export enum SymbolPositionENUM {
	LEFT = 1,
	RIGHT = 2,
}

export type CurrenciesListItem = {
	code: string;
	symbol: string;
	name: string;
	is_crypto: boolean;
	symbol_position: SymbolPositionENUM;
	rate: {
		rate: number;
		source: number;
		created_at: string;
		updated_at: string;
	};
	created_at: string;
	updated_at: string;
};

export type CreateNewCurrencyType = {
	code: string;
	symbol: string;
	name: string;
	is_crypto: boolean;
	symbol_position: SymbolPositionENUM;
};
