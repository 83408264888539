const prepareFormDataForRequest = (form: Partial<any>, type: "edit" | "create" = "create") => {
	const moveToWalletSettingsAndDelete = (data: Partial<any>, prop: string, del:boolean) => {
		if (data[prop]) {
			if (!data.wallet_settings) {
				data.wallet_settings = {};
			}

			data.wallet_settings[prop] = data[prop];

			if (del) {
				delete data[prop];
			}
		}
	}

	const data = JSON.parse(JSON.stringify(form));

	if (form.qr_data) {
		if (!data.wallet_settings) {
			data.wallet_settings = {};
		}

		data.wallet_settings.qr_data = form.qr_data;
		delete data.qr_data;
	}

	if (data.wallet_type) {
		data.wallet_type_code = data.wallet_type
		delete data.wallet_type;
	}
	
	if (data.proxies) {
		delete data.proxies;
	}

	try {
		if (data.upi_addresses && data.upi_addresses.length > 0 && !!data.upi_addresses[0].upi_address) {
			data.upi_addresses = data.upi_addresses.map((a: { readonly upi_address: string }) => a.upi_address);
		}
	} catch (e) {
		console.error(e);
	}

	moveToWalletSettingsAndDelete(data, "account_type", true);
	moveToWalletSettingsAndDelete(data, "parser_type", true);
	moveToWalletSettingsAndDelete(data, "ifsc_code", true);
	moveToWalletSettingsAndDelete(data, "account_name", true);
	moveToWalletSettingsAndDelete(data, "merchant_private_key", true);
	moveToWalletSettingsAndDelete(data, "callback_source", true);
	moveToWalletSettingsAndDelete(data, "callback_url", true);
	moveToWalletSettingsAndDelete(data, "phone_number", true);
	moveToWalletSettingsAndDelete(data, "public_key", true);
	moveToWalletSettingsAndDelete(data, "bankcode", true);
	moveToWalletSettingsAndDelete(data, "phrase", true);
	moveToWalletSettingsAndDelete(data, "api_private_key", true);
	moveToWalletSettingsAndDelete(data, "api_public_key", true);
	moveToWalletSettingsAndDelete(data, "project_id", true);
	moveToWalletSettingsAndDelete(data, "webhook_id", true);
	moveToWalletSettingsAndDelete(data, "sms_source", true);
	moveToWalletSettingsAndDelete(data, "bank_name", true);
	moveToWalletSettingsAndDelete(data, "merchant_id", true);
	moveToWalletSettingsAndDelete(data, "port", true);
	moveToWalletSettingsAndDelete(data, "upi_addresses", true);
	moveToWalletSettingsAndDelete(data, "integration_type", true);
	moveToWalletSettingsAndDelete(data, "codephrases", true);
	moveToWalletSettingsAndDelete(data, "email_sender", true);
	moveToWalletSettingsAndDelete(data, "mnc", true);
	moveToWalletSettingsAndDelete(data, "network_operator_name", true);
	moveToWalletSettingsAndDelete(data, "android_id", true);
	moveToWalletSettingsAndDelete(data, "android_board", true);
	moveToWalletSettingsAndDelete(data, "android_hardware", true);
	moveToWalletSettingsAndDelete(data, "android_serial", true);
	moveToWalletSettingsAndDelete(data, "android_manufacturer", true);
	moveToWalletSettingsAndDelete(data, "android_api_version", true);
	moveToWalletSettingsAndDelete(data, "wifi_bssid", true);
	moveToWalletSettingsAndDelete(data, "wifi_ssid", true);
	moveToWalletSettingsAndDelete(data, "wifi_frequency", true);
	moveToWalletSettingsAndDelete(data, "latitude", true);
	moveToWalletSettingsAndDelete(data, "longitude", true);
	moveToWalletSettingsAndDelete(data, "parsing_object", true);
	moveToWalletSettingsAndDelete(data, "token", true);

	if (data.wallet_off_limit) {
		data.wallet_off_limit = data.wallet_off_limit.toString();
	}

	return ({ ...data })
}

export default prepareFormDataForRequest;