// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewCounterpartyModal__tabs-IrS{display:inline-flex;width:unset}.ViewCounterpartyModal__tabs-IrS .ViewCounterpartyModal__badge-FRc{display:inline-block;margin-left:.5ch;color:#a2a9b0}.ViewCounterpartyModal__items-tSS{display:flex;flex-direction:column;width:100%;margin-top:24px;gap:16px}.ViewCounterpartyModal__actions-MSB{margin-top:32px;display:flex;justify-content:end}.ViewCounterpartyModal__actions-MSB button [class^=PlusIcon]{margin-right:8px}.ViewCounterpartyModal__emptyPlaceholder-rV3{text-align:center;padding:20px 0;width:100%;color:#ccc}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/modals/Counterparties/ViewCounterpartyModal/ViewCounterpartyModal.module.scss"],"names":[],"mappings":"AAAA,iCACI,mBAAA,CACA,WAAA,CAEA,mEACI,oBAAA,CACA,gBAAA,CACA,aAAA,CAIR,kCACI,YAAA,CACA,qBAAA,CACA,UAAA,CACA,eAAA,CACA,QAAA,CAGJ,oCACI,eAAA,CACA,YAAA,CACA,mBAAA,CAEA,6DACI,gBAAA,CAIR,6CACI,iBAAA,CACA,cAAA,CACA,UAAA,CACA,UAAA","sourcesContent":[".tabs {\n    display: inline-flex;\n    width: unset;\n\n    .badge {\n        display: inline-block;\n        margin-left: 0.5ch;\n        color: #A2A9B0;\n    }\n}\n\n.items {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    margin-top: 24px;\n    gap: 16px;\n}\n\n.actions {\n    margin-top: 32px;\n    display: flex;\n    justify-content: end;\n\n    button [class^=\"PlusIcon\"] {\n        margin-right: 8px;\n    }\n}\n\n.emptyPlaceholder {\n    text-align: center;\n    padding: 20px 0;\n    width: 100%;\n    color: #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `ViewCounterpartyModal__tabs-IrS`,
	"badge": `ViewCounterpartyModal__badge-FRc`,
	"items": `ViewCounterpartyModal__items-tSS`,
	"actions": `ViewCounterpartyModal__actions-MSB`,
	"emptyPlaceholder": `ViewCounterpartyModal__emptyPlaceholder-rV3`
};
export default ___CSS_LOADER_EXPORT___;
