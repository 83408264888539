type ExternalErrorType = {
	message: string;
	code: number;
	target: string;
};

const parseAPIResponseErrorsFormat = (externalErrors: readonly ExternalErrorType[]) => {
	const errors: Record<string, string> = {};

    try {
        externalErrors.forEach(({ target, message }) => {
            errors[target] = message;
        });
    } catch (err) {
        console.error("An unexpected field format error occurred when attempting to create/edit a wallet")
    }

	return errors;
};

export default parseAPIResponseErrorsFormat;
