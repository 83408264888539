import { TableSelectedRowIds } from "@paykassma/pay-kit/lib/elements/Table/types";
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import Relaunch from "assets/icons/relaunch.svg";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { Button } from "pay-kit";
import { Dispatch, FC } from "react";

import styles from "./tableManipulateButtonsGroup.module.scss";

const TableManipulateButtonsGroup: FC<TableManipulateButtonsGroupType> = ({ idList, setProxyIDs }) => {
	const { setIsDeleteProxiesModal, setIDsForDelete, checkProxies } = useProxyContext();

	const OnDelete = () => {
		const proxies = idList;
		setIsDeleteProxiesModal(true);
		setIDsForDelete({ proxies, isIPlist: true });
		setProxyIDs([]);
	};

	return (
		<div className={styles.buttonsGroupWrapper}>
			<Button classname={styles.relaunch} onClick={() => checkProxies(idList)}>
				<Relaunch /> Проверить прокси
			</Button>

			<Button data-test-id="VZywQeiLsE0EVYGZVEwlx" classname={styles.deleteButton} onClick={OnDelete}>
				<TrashIcon activeClass={styles.deleteIcon} /> Удалить
			</Button>
		</div>
	);
};

export default TableManipulateButtonsGroup;

type TableManipulateButtonsGroupType = {
	readonly idList: readonly string[];
	readonly setProxyIDs: Dispatch<TableSelectedRowIds>;
};
