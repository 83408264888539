import styles from "./StatusBadges.module.scss";
import { Badge, Tooltip } from "pay-kit";
import { ComponentProps } from "react";
import { WalletState } from "modules/Wallets/components/modals/ChangeWalletModal/utils/enums";

type StatusBadgesProps = {
	readonly state: WalletState[];
	readonly preferredTooltipSide: "top" | "bottom" | "left" | "right";
};

const StatusBadges = ({ state, preferredTooltipSide }: StatusBadgesProps) => {
	const variantMapping: Record<WalletState, ComponentProps<typeof Badge>["variant"]> = {
		[WalletState.ALLOWED_BALANCE_EXCEEDED]: `orange`,
		[WalletState.AUTHORISATION_ERROR]: `red`,
		[WalletState.INTEGRATION_ERROR]: `orange`,
		[WalletState.PROBABLY_BANNED]: `red`,
		[WalletState.PROXY_NOT_WORKING]: `red`,
		[WalletState.ERROR_IN_TRANSACTION_PARSING]: `red`,
		[WalletState.PARSER_ERROR]: `red`,
		[WalletState.NO_COOKIES]: `red`,
		[WalletState.PENDING_SMS_CONFIRMATION]: `blue`,
		[WalletState.UPDATE_COOKIES]: `orange`,
		[WalletState.ACCOUNT_DATA_ERROR]: `red`,
		[WalletState.UNKNOWN_ERROR]: `red`,
		[WalletState.DATA_ACCESS_ERROR]: `red`,
		[WalletState.PK_CONNECTION_ERROR]: `red`,
		[WalletState.DAILY_TURNOVER_LIMIT_EXCEEDED]: `orange`,
		[WalletState.DAILY_TRANSACTION_LIMIT_EXCEEDED]: `orange`,
		[WalletState.MONTHLY_TURNOVER_LIMIT_EXCEEDED]: `orange`,
	};

	const textMapping: Record<WalletState, string> = {
		[WalletState.ALLOWED_BALANCE_EXCEEDED]: `Баланс превышен`,
		[WalletState.AUTHORISATION_ERROR]: `Ошибка авторизации`,
		[WalletState.INTEGRATION_ERROR]: `Ошибка интеграции`,
		[WalletState.PROBABLY_BANNED]: `Возможно забанен`,
		[WalletState.PROXY_NOT_WORKING]: `Нерабочий прокси`,
		[WalletState.ERROR_IN_TRANSACTION_PARSING]: `Ошибка парсинга транзакций`,
		[WalletState.PARSER_ERROR]: `Ошибка парсера`,
		[WalletState.NO_COOKIES]: `Куки нет`,
		[WalletState.PENDING_SMS_CONFIRMATION]: `Ожидает СМС подтверждение`,
		[WalletState.UPDATE_COOKIES]: `Обновите куки`,
		[WalletState.ACCOUNT_DATA_ERROR]: `Ошибка данных аккаунта`,
		[WalletState.UNKNOWN_ERROR]: `Неизвестная ошибка`,
		[WalletState.DATA_ACCESS_ERROR]: `Ошибка доступа к данным`,
		[WalletState.PK_CONNECTION_ERROR]: `Ошибка соединения с PK`,
		[WalletState.DAILY_TURNOVER_LIMIT_EXCEEDED]: `Суточный лимит по сумме превышен`,
		[WalletState.DAILY_TRANSACTION_LIMIT_EXCEEDED]: `Суточный лимит по транзакциям превышен`,
		[WalletState.MONTHLY_TURNOVER_LIMIT_EXCEEDED]: `Месячный лимит по сумме превышен`,
	};

	return (
		<div className={styles.wrapper}>
			{state?.map((status) => {
				const text = textMapping[status];

				return (
					<Tooltip tip={text} key={status} preferredSide={preferredTooltipSide}>
						<div>
							<Badge variant={variantMapping[status]}>{text}</Badge>
						</div>
					</Tooltip>
				);
			})}
		</div>
	);
};

export default StatusBadges;
