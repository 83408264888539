import { LogListData } from "api/walletsDebuggingGroup";
import DynamicMultilineText from "components/DynamicMultilineText";
import PaymentSystemLogo from "components/PaymentSystemLogo";
import WalletIdentifier from "modules/LogsList/components/WalletIdentifier";

import styles from "./logsListColumns.module.scss";

const WalletType = {
	title: "Тип кошелька",
	dataIndex: "wallet_type",
	key: "wallet_type",
	render: ({ wallet_type_code }: LogListData) => <PaymentSystemLogo id={wallet_type_code} dataTestId="wallet_type" />
};

const WalletID = {
	title: "Номер кошелька",
	dataIndex: "wallet_identifier",
	key: "wallet_identifier",
	render: ({ wallet_identifier, wallet_hash_id }: LogListData) =>
		<WalletIdentifier wallet_identifier={wallet_identifier} wallet_id={wallet_hash_id} dataTestId="wallet_identifier" />
};

const ErrorType = {
	title: "Тип ошибки",
	dataIndex: "error_type",
	key: "error_type",
	render: ({ error_type }: LogListData) =>
		<span className={styles.errorType} data-test-id="error_type">{error_type}</span>
};

const Category = {
	title: "Категория",
	dataIndex: "category",
	key: "category",
	dataTestId: "category"
};

const Message = {
	title: "Сообщение",
	dataIndex: "message",
	key: "message",
	render: ({ message }: LogListData) =>
		<DynamicMultilineText
			text={message}
			className={styles.messageWrapper}
			toggleButtonValue={{ hideText: "Свернуть" }}
			dataTestId="message"
		/>
};

const Date = {
	title: "Дата создания",
	dataIndex: "created_at",
	key: "created_at",
	render: ({ created_at }: LogListData) => {
		const [date, time] = created_at.split(" ");
		return (
			<div data-test-id="created_at">
				<div>{date}</div>
				<div className={styles.creationTime}>{time}</div>
			</div>
		);
	}
};

export const LogsListColumns = [
	WalletType,
	WalletID,
	ErrorType,
	Category,
	Message,
	Date
];
