import { PayKitForm } from "@paykassma/pay-kit";
import { StandardResponse } from "api/types";
import { WalletListItem } from "api/walletGroup";
import { ModalProps } from "utils/types";

type DisableWalletModalProps = ModalProps & {
	readonly id: WalletListItem[`id`],
	readonly actions: {
		readonly setSmsCode: (form: any) => Promise<StandardResponse<void>>
	}
}

const SetSMSModal = ({actions, closeModal}: DisableWalletModalProps) => {
    const handleSubmit = (form: any) => {
        actions.setSmsCode(form).then((resp) => {
            if (resp.status === "success") {
                window.pushAlert({
                    type: "success",
                    title: "Успех",
                    description: "Телефон успешно подтвержден",
                });
                closeModal && closeModal();
            } else {
                window.pushAlert({
                    type: "error",
                    title: "Ошибка",
                    description: resp.error_message,
                })
            }
        })
    }

    const fields = [
        {
            name: "sms_code",
            label: "Введите код",
            type: "TextInput",
        },
        {
            type: "SubmitButton",
            label: "Подтвердить",
            onSubmit: handleSubmit,
        },
    ]
    return (
        <PayKitForm.Builder
            //@ts-ignore
            schema={fields}
        />
    )
}

export default SetSMSModal;