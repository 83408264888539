// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddProxy__proxyLoader-tqe{width:100%;display:flex;justify-content:center;align-items:center;overflow:hidden;font-weight:400;font-size:14px;color:#697077}.AddProxy__proxyLoader-tqe .AddProxy__proxyLoaderText-Sv4{margin-left:8px}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/AddProxy/addProxy.module.scss"],"names":[],"mappings":"AAAA,2BACC,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CACA,cAAA,CACA,aAAA,CAEA,0DACC,eAAA","sourcesContent":[".proxyLoader {\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\toverflow: hidden;\n\tfont-weight: 400;\n\tfont-size: 14px;\n\tcolor: #697077;\n\n\t.proxyLoaderText {\n\t\tmargin-left: 8px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"proxyLoader": `AddProxy__proxyLoader-tqe`,
	"proxyLoaderText": `AddProxy__proxyLoaderText-Sv4`
};
export default ___CSS_LOADER_EXPORT___;
