
import React, {useEffect} from "react";
import HiddenFields from "modules/Hidden/Fields";
import HiddenSettingsContextProvider from "contexts/HiddenFieldsContext";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";

const ActiveWalletsPage: React.FC = () => {
	const { hasRole } = useAuthContext();

	hasRole(Roles.HIDDEN_SETTINGS_UPDATE, { redirectToErrorPage: true });
	useEffect(() => {
		document.title = "Скрытые поля";
	}, []);

	return (
		<HiddenSettingsContextProvider>
			<HiddenFields/>
		</HiddenSettingsContextProvider>
	);
};

export default ActiveWalletsPage;