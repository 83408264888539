import RoutedTabs from "components/RoutedTabs";
import { useAuthContext } from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";

const WalletsTabNav = () => {
	const { hasRole } = useAuthContext();
	const tabs = [
		{
			label: "Рабочие",
			value: "/wallets",
			isVisible: hasRole(Roles.WALLET_LIST),
		},
		{
			label: "Отключенные",
			value: "/wallets/disabled",
			isVisible: hasRole(Roles.WALLETS_DISABLED),
		},
		{
			label: "Архивные",
			value: "/wallets/archived",
			isVisible: hasRole(Roles.ARCHIVED_WALLETS_VIEW),
		},
	];

	return (
		<RoutedTabs tabs={tabs.filter((tab) => tab.isVisible === true || tab.isVisible === undefined)} />
	);
}


export default WalletsTabNav;