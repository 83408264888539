import Api from "api";
import { StandardResponse } from "api/types";
import { LoadLogListType } from "api/walletsDebuggingGroup";
import { useState } from "react";
import { adaptFilters } from "utils/adaptFilters";
import { errorsMap } from "utils/enums";


const useLoadLogList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [data, setData] = useState<StandardResponse<LoadLogListType[]> | null>(null);

	const load = (params: Record<string, string>) => {
		setError(null);
		setIsLoading(true);

		Api.walletsDebugging.loadLogList(adaptFilters(params)).then(res => {
			if (res.status === "success") {
				setData(res);
			} else {
				// throw new Error("Unexpected response in loadLogList!");
				throw new Error(errorsMap.anyResponse);
			}
		}).catch(err => {
			console.log(err);
			setError(err);
		}).finally(() => {
			setIsLoading(false);
		});
	};


	return {
		load,
		data,
		isLoading,
		error
	};
};

export default useLoadLogList;