import { formStateType } from "modules/Proxies/components/AddProxyModal";
import { MultiAddingProxiesType, ProxiesContextType } from "modules/Proxies/contexts/AddingProxiesContext";
import { portPattern } from "modules/Proxies/data";
import { ProxyWalletSelectType } from "modules/Proxies/hooks/useAvailableWallets";

export const getUniqueWalletOptions: GetUniqueWalletOptionsType = (formState, currentValue, dynamicSelectOptions) => {
	// getting already chosen options
	const selectedTypes = formState?.manual_creating_form.wallets?.map(
		({ type }) => type)
		.filter((type) => type !== currentValue);

	return dynamicSelectOptions.filter((type) => !selectedTypes?.includes(Number(type.value)));
};

export const formWallets: FormWalletsType = (formData, options) => formData.wallets?.map((proxy: {
	readonly type: string | number
}) => {
	const option = options.find(option => option.value === proxy.type);

	return ({
		id: option?.value,
		parser_type: option?.parser_type
	});
});

export const constructMultiAddingProxiesList: ConstructMultiAddingProxiesListType = (value) => {
	const splitAtWhitespace = value.trim().split("\n");
	return splitAtWhitespace.map(proxy => {
		const inputValue = proxy.trim();
		const [ip_host, port, username, password, type, country_code] = inputValue.split(":");

		const loginAndPasswordPattern = /^\S{1,100}$/;
		const typePattern = /^socks[45]$|^https?$/;
		const countryPattern = /^[A-Z]{2}$/;
		const MAX_PORT_VALUE = 65535;

		const isFormatValid = (
			ip_host.length <= 100 &&
			portPattern.test(`${+port}`) &&
			(parseInt(port) <= MAX_PORT_VALUE) &&
			loginAndPasswordPattern.test(username) &&
			loginAndPasswordPattern.test(password) &&
			typePattern.test(type) &&
			countryPattern.test(country_code)
		);

		return ({ proxy: { ip_host, port, username, password, type, country_code }, isFormatValid, inputValue });
	});
};

export const multiAddingProxiesHelper: MultiAddingProxiesHelperType = (proxyData) => {

	return proxyData.state?.map((elem) => {
		if (elem.id === proxyData.id && proxyData.params) return ({
			...elem,
			[proxyData.params.key]: proxyData.params.value
		});
		if (elem.id === proxyData.id && proxyData.reservedState) return proxyData.reservedState;

		return elem;
	}) || [];
};

type GetUniqueWalletOptionsType = (
	formState: formStateType,
	currentValue: number,
	dynamicSelectOptions: readonly ProxyWalletSelectType[]
) => readonly ProxyWalletSelectType[]


type WalletsType = {
	readonly id: string | number | undefined,
	readonly parser_type: string | null | undefined
}
type FormWalletsType = (formData: formStateType["manual_creating_form"], options: readonly ProxyWalletSelectType[]) => readonly WalletsType[] | undefined

type MultiAddingProxiesHelperType = (proxyData: {
		readonly state: readonly MultiAddingProxiesType [] | null,
		readonly id: string,
		readonly params?: {
			readonly key: keyof MultiAddingProxiesType,
			readonly value: MultiAddingProxiesType[keyof MultiAddingProxiesType]
		},
		readonly reservedState?: MultiAddingProxiesType
	}
) => readonly MultiAddingProxiesType []

export type ConstructMultiAddingProxiesListType = (value: string) => ProxiesContextType["proxiesList"]
