import { WalletType } from "api/walletGroup";

export const isP2PWalletSelected = (walletTypes: ReadonlyArray<WalletType>, selectedWT?: string) => {
	if (!selectedWT) return false;

	const finded = walletTypes.find((wt) => wt.code === selectedWT);

	if (finded?.payment_type === "p2p" || finded?.payment_type === "P2P") return true;
	if (finded?.type === "p2p" || finded?.type === "P2P") return true;

	return false;
};
