import AddProxy from "modules/Proxies/components/AddProxyModal";
import UploadedProxiesListTable from "modules/Proxies/components/UploadedProxiesListTable";
import { Loader, ScrollBox } from "pay-kit";
import { FC } from "react";

import styles from "./addProxy.module.scss";

const ProxyModalContentManager: FC<ProxyModalContentManagerType> = ({
	isProxyAddingLoaderLoading,
	isUploadedProxiesListLoading
}) => {

	if (isProxyAddingLoaderLoading) return (
		<div className={styles.proxyLoader}>
			<Loader />
			<span className={styles.proxyLoaderText}>
				Идет проверка...
			</span>
		</div>);

	if (isUploadedProxiesListLoading) return (
		<ScrollBox className={styles.UploadedProxiesListTableScroll}>
			<UploadedProxiesListTable />
		</ScrollBox>
	);

	return <AddProxy />;
};

export default ProxyModalContentManager;

type ProxyModalContentManagerType = {
	readonly isProxyAddingLoaderLoading: boolean
	readonly isUploadedProxiesListLoading: boolean
}
