export const proxyTypes = [`socks4`, `socks5`, `https`, `http`] as const;

export const portPattern = /^\d{1,5}$/;

export const checkedStatuses = {
	WORKING: "WORKING",
	NOT_WORKING: "NOT_WORKING",
	NOT_CHECKED: "NOT_CHECKED"
} as const;

export const IPHOSTpattern = /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})$|^(?:[a-zA-Z\d-]+\.)*[a-zA-Z\d-]+\.[a-zA-Z]{2,}$/;

export const errorMessageIPHOST = `Поле должно принимать вид или IP-адреса: от 0.0.0.0 до 255.255.255.255, или web-адреса хоста.`;
export const errorMessagePort = `Port должен состоять только из цифр и его значение не должно превышать 65535`;

export const isIPHOSTValid: IsValidType = (value) => !IPHOSTpattern.test(value) ? errorMessageIPHOST : undefined;
export const isPortValid: IsValidType = (value) => (value && !isNaN(+value) && +value >= 0 && +value <= 65535) ? undefined: errorMessagePort;

export const isRequired: IsValidType = (value) => !value ? "Обязательное поле" : undefined;
export const isIPHOSTRequired = (value: string) => (
	!value ? "Обязательное поле" : undefined
);

type IsValidType = (value: string) => string | undefined