import Filter from "components/Filter";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { useEffect } from "react";
import styles from "./Filter.module.scss";
import useGetWalletTypesList from "modules/DefaultSettings/hooks/useGetWalletTypesList";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useLazyLoadWallets } from "utils/hooks/useLazyLoadWallets";

const ProxyFilters = () => {
	const { countryList, filters, setFilters } = useProxyContext();
	const { hasRole } = useAuthContext();
	const { isLoadingWallets, walletsIdentifierOptions, onMenuScrollToBottom, loadOptionsWithIdentifiers } = useLazyLoadWallets({ removed: 0 });

	const { list: walletTypesList, isLoading, load: loadWalletTypes } = useGetWalletTypesList();

	useEffect(() => {
		loadWalletTypes();
	}, []);

	const countryOptions = countryList?.map((elem) => ({ label: elem.code, value: elem.code })) || [];
	const walletTypesOptions = walletTypesList?.map((elem) => ({ label: elem.name, value: elem.code })) || [];

	return (
		<Filter
			values={filters}
			fields={[
				{
					name: `type`,
					placeholder: "Тип прокси",
					component: "select",
					options: [
						{
							value: ``,
							label: `Все`,
						},
						{
							value: `http`,
							label: `HTTP`,
						},
						{
							value: `https`,
							label: `HTTPS`,
						},
						{
							value: `socks4`,
							label: `SOCKS4`,
						},
						{
							value: `socks5`,
							label: `SOCKS5`,
						},
					],
					// className: styles.field,
				},
				{
					name: `status`,
					placeholder: "Статус",
					component: "select",
					options: [
						{
							label: `Все`,
							value: ``,
						},
						{
							label: `Не работает`,
							value: `0`,
						},
						{
							label: `Работает`,
							value: `1`,
						},
					],
					className: styles.field,
				},
				{
					name: `country_code`,
					placeholder: "Страна",
					component: "select",
					options: [
						{
							value: ``,
							label: `Все`,
						},
						...countryOptions,
					],
					className: styles.field,
				},
				{
					name: `wallet_identifier`,
					component: "lazySelect",
					hide: !hasRole(Roles.WALLET_LIST),
					placeholder: "Кошелек",
					lazyProps: {
						loadOptions: loadOptionsWithIdentifiers,
						onMenuScrollToBottom,
						defaultOptions: walletsIdentifierOptions,
						isLoading: isLoadingWallets,
					},
					className: styles.walletField,
				},
				{
					name: `wallet_type`,
					placeholder: "Тип кошелька",
					component: "select",
					options: [{ value: "", label: "Все" }, ...walletTypesOptions],
					isLoading: isLoading,
				},
				{
					name: `ip`,
					placeholder: "IP",
					component: "textInput",
					className: styles.field,
				},
				{
					name: `port`,
					placeholder: "Порт",
					component: "textInput",
					className: styles.field,
				},
				{
					name: `username`,
					placeholder: "Логин",
					component: "textInput",
					className: styles.field,
				},
			]}
			onApply={setFilters}
		/>
	);
};

export default ProxyFilters;
