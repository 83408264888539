import { PayKitForm, Loader } from "@paykassma/pay-kit";
import { WalletListItem } from "api/walletGroup";
import { useCounterparty } from "modules/Counterparty/hooks/useCounterparty";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { useContext, useState } from "react";
import { isP2PWalletSelected } from "utils/isP2PWalletSelected";
import API from "api";
import { Counterparty } from "api/counterpartyGroup";
import styles from "./AddCounterpartyModal.module.scss";

type AddCounterpartyModalProps = {
	wallet?: WalletListItem;
	onSuccess: (wallet_id: WalletListItem["hash_id"], counterpartyId: Counterparty["id"]) => void;
};

const AddCounterpartyModal = ({ onSuccess, wallet }: AddCounterpartyModalProps) => {
	const { walletTypes } = useContext(WalletTypesContext);
	const { isListLoading, counterparties } = useCounterparty({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const counterpartyUuids = wallet?.counterparties.map((c) => c.counterparty_uuid);

	const handleSubmit = (form: any) => {
		setIsLoading(true);

		API.wallet
			.attachCounterparty(wallet?.hash_id as WalletListItem["hash_id"], form.counterparty)
			.then((resp) => {
				if (resp.status === "success") {
					window.pushAlert({
						type: "success",
						description: "Контрагент добавлен",
					});

					onSuccess(wallet?.hash_id as WalletListItem["hash_id"], form.counterparty);
				} else {
					window.pushAlert({
						type: "error",
						title: "Ошибка при добавлении контрагента",
						description: resp.error_message,
					});
				}
			})
			.finally(() => setIsLoading(false));
	};

	if (isListLoading) return <Loader />;

	const schema = [
		{
			name: "counterparty",
			type: "Select",
			label: "Контрагент",
			options: counterparties
				?.map((counterparty) => ({
					value: counterparty.id,
					label: counterparty.identifier,
				}))
				.filter((option) => !counterpartyUuids?.includes(option.value)),
		},
		{
			name: "parsing_start_at",
			type: "DatePicker",
			label: "Дата начала парсинга",
			dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
			blockPredicate: (date) => date < new Date(wallet.created_at),
			existsIf: ({ wallet_type }: any) => isP2PWalletSelected(walletTypes, wallet_type),
			withTime: true,
		},
		{
			type: "Group",
			render: (children: React.ReactElement) => <div className={styles.actions}>{children}</div>,
			elements: [
				{
					type: "SubmitButton",
					label: "Далее",
					onSubmit: handleSubmit,
					isLoading,
				},
			],
		},
	];

	return (
		<div className={styles.addCounterpartyForm}>
			<PayKitForm.Builder schema={schema} />
		</div>
	);
};

export default AddCounterpartyModal;
