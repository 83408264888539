import API from "api";
import React, { useEffect, useState } from "react";
import { WalletListItem, WalletsFilterForm } from "api/walletGroup";
import { OldResponse } from "api/types";
import { Modal } from "pay-kit";
import { RecoverWalletModal } from "modules/Wallets/ArchivedWallets/components/modals/RecoverWalletModal";
import { MassRecoverWalletModal } from "modules/Wallets/ArchivedWallets/components/modals/MassRecoverWalletModal";
import { DeleteWalletModal } from "modules/Wallets/ArchivedWallets/components/modals/DeleteWalletModal";
import { MassDeleteWalletModal } from "modules/Wallets/ArchivedWallets/components/modals/MassDeleteWalletModal";
import CommentModal from "modules/Wallets/components/modals/CommentModal";
import { GetChangeLogListType } from "api/changeLogGroup";
import EditProxies from "modules/Wallets/components/modals/EditProxies";
import styles from "./ArchivedWallet.module.scss";
import type { EditProxiesModalData } from "modules/Wallets/contexts/WalletContext/WalletContext";

type PureEditProxiesModalData = Omit<EditProxiesModalData, "open">;

type ArchivedWalletContextType = {
	readonly wallets: readonly WalletListItem[];
	readonly getWallets: (params?: Record<any, any>) => void;
	readonly isLoading: boolean;
	readonly selectedIds: readonly (string | number)[];
	readonly setSelectedIds: React.Dispatch<React.SetStateAction<readonly (string | number)[]>>;
	readonly filter: WalletsFilterForm;
	readonly setFilter: React.Dispatch<React.SetStateAction<WalletsFilterForm>>;
	readonly openRecoverWalletModal: (id: WalletListItem[`hash_id`]) => void;
	readonly openDeleteWalletModal: (id: WalletListItem[`hash_id`]) => void;
	readonly openMassRecoverWalletModal: () => void;
	readonly openMassDeleteWalletModal: () => void;
	readonly openCommentModal: (id: WalletListItem[`hash_id`], comment: string | null) => void;
	readonly openEditProxiesModal: (data: PureEditProxiesModalData) => void;
	readonly paginationInfo: GetChangeLogListType["paginate"];
	readonly limit: number;
	readonly setLimit: (limit: number) => void;
	readonly page: number;
	readonly setCurrentPage: (page: number) => void;
	readonly refreshWalletByHashId: (id: WalletListItem[`hash_id`], onSuccess?: (data: WalletListItem) => void) => void;
};

const initialState: ArchivedWalletContextType = {} as ArchivedWalletContextType;

type ModalData = {
	readonly open: boolean;
};

type WalletModalData = ModalData & {
	readonly hash_id: WalletListItem[`hash_id`];
};

type CommentModalData = ModalData & {
	readonly hash_id: WalletListItem[`hash_id`];
	readonly comment: string | null;
};

export const ArchivedWalletContext = React.createContext<ArchivedWalletContextType>(initialState);
ArchivedWalletContext.displayName = "ArchivedWalletContext";

export default function ArchivedWalletContextProvider(props: any) {
	const [paginationInfo, setPaginationInfo] = useState<GetChangeLogListType["paginate"]>(
		{} as GetChangeLogListType["paginate"]
	);
	const [wallets, setWallets] = useState<readonly WalletListItem[]>([]);
	const [selectedIds, setSelectedIds] = useState<readonly (string | number)[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [filter, setFilter] = useState<WalletsFilterForm>({});
	const [limit, setLimit] = useState<number>(20);
	const [page, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		setCurrentPage(1);
	}, [filter, limit]);

	useEffect(() => {
		getWallets();
	}, [filter, limit, page]);

	const [recoverModalData, setRecoverModalData] = useState<WalletModalData>({
		open: false,
		id: 0,
	});

	const [massRecoverModalData, setMassRecoverModalData] = useState<boolean>(false);

	const [deleteModalData, setDeleteModalData] = useState<WalletModalData>({
		open: false,
		id: 0,
	});

	const [massDeleteModalData, setMassDeleteModalData] = useState<boolean>(false);

	const [commentModalData, setCommentModalData] = useState<CommentModalData>({
		open: false,
		hash_id: null,
		comment: null,
	});

	const [editProxiesModalData, setEditProxiesModalData] = useState<EditProxiesModalData>({
		open: false,
		id: ``,
		identifier: ``,
		proxies: [],
	});

	const getWallets = (params = {}): Promise<OldResponse<WalletListItem[]>> => {
		setLoading(true);
		const walletsFilters = {
			removed: 1,
			limit,
			offset: (page - 1) * limit,
			...filter,
		};

		return API.wallet
			.getList({ ...walletsFilters, ...params })
			.then((resp) => {
				if (resp.status === "success") {
					setWallets(resp.data);
					setPaginationInfo(resp.paginate);
				}
				return resp;
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const refreshWalletByHashId = (
		walletHashId: WalletListItem["hash_id"],
		onSuccess?: (data: WalletListItem) => void
	) => {
		setLoading(true);

		API.wallet
			.getItem(walletHashId)
			.then((res) => {
				if (res?.status === "success") {
					onSuccess && onSuccess(res.data);

					setWallets((oldWallets: readonly WalletListItem[]) => {
						return oldWallets.map((wallet) => {
							if (wallet.hash_id === walletHashId) {
								return res.data;
							}

							return wallet;
						});
					});
					return;
				} else {
					throw new Error("Unexpected response");
				}
			})
			.catch((err) => {
				console.error(err);
				window.pushAlert({ description: `Не удалось получить данные кошелька ${walletHashId}`, type: "error" });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const recoverWallet = (
		id: WalletListItem[`hash_id`],
		body: { parsing_start_at?: string },
		fetchWallets: boolean = true
	): Promise<OldResponse<void>> => {
		return API.wallet.recoverWallet(id, body).then(async (resp) => {
			if (resp.status === "success" && fetchWallets) {
				await setTimeout(() => {
					getWallets();
				}, 1000);
			}

			return resp;
		});
	};

	const deleteWallet = (hash_id: WalletListItem[`hash_id`]) => {
		return API.wallet.deleteWallet(hash_id).then(async (resp) => {
			if (resp.status === "success") {
				await setTimeout(() => {
					getWallets();
				}, 1000);
			}

			return resp;
		});
	};

	const setComment = (form: any) => {
		return API.wallet.updateSetting({ hash_id: commentModalData.hash_id, ...form }).then((resp) => {
			if (resp.status === "success") {
				getWallets();
			}

			return resp;
		});
	};

	const attachProxies = (proxy_uuids: string[], wallet_id: string) => {
		return API.wallet.attachProxies(proxy_uuids, wallet_id);
	};

	const detachProxies = (proxy_uuids: string[], wallet_id: string) => {
		return API.wallet.detachProxies(proxy_uuids, wallet_id);
	};

	const openDeleteWalletModal = (id: WalletListItem[`hash_id`]) => {
		setDeleteModalData({
			open: true,
			id,
		});
	};

	const closeDeleteWalletModal = () => {
		setDeleteModalData({
			open: false,
			id: 0,
		});
	};

	const openRecoverWalletModal = (id: WalletListItem[`hash_id`]) => {
		setRecoverModalData({
			open: true,
			id,
		});
	};

	const closeRecoverWalletModal = () => {
		setRecoverModalData({
			open: false,
			id: 0,
		});
	};

	const openMassRecoverWalletModal = () => {
		setMassRecoverModalData(true);
	};

	const closeMassRecoverWalletModal = () => {
		setMassRecoverModalData(false);
	};
	const openMassDeleteWalletModal = () => {
		setMassDeleteModalData(true);
	};

	const closeMassDeleteWalletModal = () => {
		setMassDeleteModalData(false);
	};

	const openCommentModal = (hash_id: WalletListItem[`hash_id`], comment: string | null) => {
		setCommentModalData({
			open: true,
			hash_id,
			comment,
		});
	};

	const closeCommentModal = () => {
		setCommentModalData({
			open: false,
			id: 0,
			comment: null,
		});
	};

	const openEditProxiesModal = (data: PureEditProxiesModalData) => {
		setEditProxiesModalData({
			...data,
			open: true,
		});
	};

	const closeEditProxiesModal = () => {
		setEditProxiesModalData({
			open: false,
			proxies: [],
			id: ``,
			identifier: ``,
		});
	};

	const value = {
		wallets,
		getWallets,
		isLoading,
		selectedIds,
		setSelectedIds,
		openRecoverWalletModal,
		openMassRecoverWalletModal,
		openDeleteWalletModal,
		openMassDeleteWalletModal,
		openCommentModal,
		openEditProxiesModal,
		filter,
		setFilter,
		paginationInfo,
		setWallets,
		limit,
		setLimit,
		page,
		setCurrentPage,
		refreshWalletByHashId,
	};

	return (
		<ArchivedWalletContext.Provider value={value}>
			{props.children}
			<Modal title="Удаление кошелька" isOpen={deleteModalData.open} onClose={closeDeleteWalletModal}>
				<DeleteWalletModal
					closeModal={closeDeleteWalletModal}
					id={deleteModalData.id}
					actions={{
						deleteWallet,
					}}
				/>
			</Modal>
			<Modal title="Удаление кошельков" isOpen={massDeleteModalData} onClose={closeMassDeleteWalletModal}>
				<MassDeleteWalletModal
					closeModal={closeMassDeleteWalletModal}
					isOpen={massDeleteModalData}
					ids={selectedIds as WalletListItem[`hash_id`][]}
					actions={{
						clearSelectedIds: () => setSelectedIds([]),
						deleteWallet,
						getWallets,
					}}
				/>
			</Modal>
			<Modal title="Восстановление кошелька" isOpen={recoverModalData.open} onClose={closeRecoverWalletModal}>
				<RecoverWalletModal
					closeModal={closeRecoverWalletModal}
					id={recoverModalData.id}
					actions={{
						recoverWallet,
					}}
				/>
			</Modal>
			<Modal title="Восстановление кошельков" isOpen={massRecoverModalData} onClose={closeMassRecoverWalletModal}>
				<MassRecoverWalletModal
					closeModal={closeMassRecoverWalletModal}
					isOpen={massRecoverModalData}
					ids={selectedIds as WalletListItem[`id`][]}
					actions={{
						clearSelectedIds: () => setSelectedIds([]),
						recoverWallet,
						getWallets,
					}}
				/>
			</Modal>
			<Modal title="Комментарий" isOpen={commentModalData.open} onClose={closeCommentModal}>
				<CommentModal
					closeModal={closeCommentModal}
					actions={{
						setComment,
					}}
					comment={commentModalData.comment}
				/>
			</Modal>
			<Modal
				title="Настройка прокси"
				isOpen={editProxiesModalData.open}
				onClose={closeEditProxiesModal}
				className={styles.editProxiesModal}
			>
				{editProxiesModalData.open && (
					<EditProxies
						close={closeEditProxiesModal}
						attach={attachProxies}
						detach={detachProxies}
						data={editProxiesModalData}
						ctx={ArchivedWalletContext}
					/>
				)}
			</Modal>
		</ArchivedWalletContext.Provider>
	);
}
