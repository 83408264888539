import { OldResponse, Response, StandardResponse } from "api/types";
import axios, { AxiosPromise, AxiosRequestConfig, Method } from "axios";
import { LoginData } from "contexts/AuthContext/utils/types";
import { getToken, PAYKASSMA_REFRESH_TOKEN_NAME, tokenUpdater } from "helpers/tokenHelper";
import { PAYKASSMA_ERROR_MSG } from "layout/pages/ErrorPage";
import { v4 as uuidv4 } from "uuid";

export const PAYKASSMA_REDIRECT_URL = `PAYKASSMA_REDIRECT_URL`;

const getBaseApiConfig = <Res = unknown, Payload = unknown>({
	url,
	headers = {},
	config = { withToken: true },
	method = "GET",
	params,
}: {
	url: string;
	headers?: Record<string, string>;
	config?: {
		withToken?: boolean;
		data?: Payload;
	} & Record<string, unknown>;
	method?: Method;
	params?: Record<string, string | string[] | boolean>;
}): AxiosRequestConfig<Response<Res>> => {
	let authHeaders = {};

	if (config.withToken) {
		authHeaders = {
			Authorization: `Bearer ${getToken()}`,
		};
	}

	const _config: AxiosRequestConfig = {
		url: url,
		//@ts-ignore
		baseURL: window.config.apiUrl,
		method: method,
		headers: {
			...authHeaders,
			["x-current-language"]: "ru", // TODO: Use app settingsGroup
			...headers,
		},
		...config,
	};

	if (params) {
		if (method === "GET") {
			_config.params = params;
		} else {
			_config.data = params;
		}
	}

	return _config;
};

export function fetchOldApi<Res = unknown, Payload = unknown>({
	url,
	headers = {},
	config = { withToken: true },
	method = "GET",
	params,
}: {
	url: string;
	headers?: Record<string, string>;
	config?: {
		withToken?: boolean;
		data?: Payload;
	} & Record<string, unknown>;
	method?: Method;
	params?: Record<string, string>;
}): Promise<OldResponse<Res>> {
	// const _config: AxiosRequestConfig<Response<Res>> = getBaseApiConfig<Res>(url, params, method, headers, config);
	const _config: AxiosRequestConfig<Response<Res>> = getBaseApiConfig<Res>({
		url,
		config,
		headers,
		method,
		params,
	});

	return errorHandlerMiddleware(_config, axios(_config)) as Promise<OldResponse<Res>>;
}

export function fetchApi<Res = unknown, Payload = unknown>({
	url,
	headers = {},
	config = { withToken: true },
	method = "GET",
	params,
}: {
	url: string;
	headers?: Record<string, string>;
	config?: {
		withToken?: boolean;
		data?: Payload;
	} & Record<string, unknown>;
	method?: Method;
	params?: Record<string, boolean | string | string[]>;
}): Promise<StandardResponse<Res>> {
	// const _config: AxiosRequestConfig<Response<Res>> = getBaseApiConfig(
	// 	url,
	// 	params,
	// 	method,
	// 	{
	// 		...headers,
	// 		"Request-Version": "1.000.0",
	// 		"Request-Id": uuidv4(),
	// 	},
	// 	config as { withToken: boolean }
	// );
	const _config: AxiosRequestConfig<Response<Res>> = getBaseApiConfig({
		url,
		params,
		method,
		headers: {
			...headers,
			"x-version": "1.000.0",
			"Request-Id": uuidv4(),
		},
		config,
	});

	return errorHandlerMiddleware(_config, axios(_config)) as Promise<StandardResponse<T>>;
}

const refreshMiddleware = (): Promise<any> => {
	const refresh_token = localStorage.getItem(PAYKASSMA_REFRESH_TOKEN_NAME) || ``;

	const config: AxiosRequestConfig<{ refresh_token: string }> = {
		url: "auth/refresh",
		//@ts-ignore
		baseURL: window.config.apiUrl,
		method: "POST",
		data: {
			refresh_token,
		},
		headers: {
			Authorization: `Bearer ${getToken()}`,
			"x-version": "1.000.0",
			"Request-Id": uuidv4(),
		},
	};

	// Заменил тип. Стандартные не подходят под то, что присылает бек в ответе.
	const refreshRequest: AxiosPromise<LoginData & { status: "ok" | "success" }> = axios(config);

	const redirectToLogin = () => {
		localStorage.setItem(PAYKASSMA_REDIRECT_URL, window.location.pathname);
		tokenUpdater("", "");
		window.location.href = "/login";
	};

	return refreshRequest
		.then((resp) => {
			if (resp.data.status === "success" || resp.data.status === "ok") {
				const { token, refresh_token } = resp.data;

				tokenUpdater(token, refresh_token);

				return token;
			} else {
				redirectToLogin();
			}
		})
		.catch((e) => {
			const { data } = e.response;
			const message = data.error_message;

			window.pushAlert({
				title: message,
				type: "error",
			});

			redirectToLogin();

			return e;
		});
};

let promisesToRefresh: Promise<any>[] = [];

const checkToken = async <T>(config: AxiosRequestConfig<Response<T>>) => {
	let token = config?.headers?.[`Authorization`]?.toString()?.replace(`Bearer `, ``);

	const currToken = getToken();
	if (token === currToken) {
		token = await refreshMiddleware();
	} else {
		token = currToken;
	}
	const newConfig = {
		...config,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return await errorHandlerMiddleware(newConfig, axios(newConfig));
};

const errorHandlerMiddleware = async <T>(
	config: AxiosRequestConfig<Response<T>>,
	promise: AxiosPromise<Response<T>>
): Promise<Response<T>> => {
	const isOldResponse = (resp: Response<T>): resp is OldResponse<T> => {
		return (resp as StandardResponse<T>).code === undefined;
	};

	const refreshToken = () => {
		if (promisesToRefresh.length > 0) {
			return Promise.all(promisesToRefresh).then(() => {
				promisesToRefresh = [];
				return checkToken(config);
			});
		} else {
			const promise = checkToken(config);
			promisesToRefresh.push(promise);

			return promise;
		}
	};

	try {
		const resp = await promise;
		const data = resp.data;
		if (!isOldResponse(data) && data.status === "error" && data.code === 50003) {
			return refreshToken();
		}
		//TODO: Проверить тип
		// const content: Response<T> = resp.data;

		if (!isOldResponse(data) && data.status === "error") {
			//TODO: Прикрутить обработку нового формата, когда появятся такие роуты
		}

		return resp.data;
		// @ts-ignore
	} catch (e: AxiosError<Response<T>>) {
		const status = e.response ? e.response.status : "error";
		if (status === 401) {
			return refreshToken();
		} else if (status === "error") {
			throw e.response?.data;
		} else if (status === 422) {
			return e.response?.data;
		} else if (status === 404) {
			window.pushAlert({
				title: "Ошибка",
				type: "error",
				description: `Роут ${config.url} не найден`,
			});
		} else if (status >= 500) {
			localStorage.setItem(PAYKASSMA_ERROR_MSG, `${config.url} - ошибка ${status}`);
			//window.location.replace("/error")
		}

		return e.response?.data;
	}
};
