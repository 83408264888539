/* eslint-disable react/prop-types */
import { Button, Tooltip } from "@paykassma/pay-kit";
import { PenIcon } from "components/Icons/PenIcon";
import { Counterparty } from "api/walletGroup";
import VisibileIcon from "./icons/visible.svg";
import InvisibleIcon from "./icons/invisible.svg";
import Priority0 from "./icons/priority/0.svg";
import Priority25 from "./icons/priority/25.svg";
import Priority50 from "./icons/priority/50.svg";
import Priority75 from "./icons/priority/75.svg";
import Priority100 from "./icons/priority/100.svg";
import styles from "./CounterpartyItem.module.scss";
import StatusBadges from "modules/Wallets/components/StatusBadges";
import { priorityMapping } from "../../EditCounterpartyModal";

type CounterpartyItemProps = {
	readonly onEdit?: () => void;
	readonly onDelete: () => void;
	readonly identifier: string;
	readonly priority: Counterparty["counterparty_wallet_settings"]["priority"];
	readonly status: Counterparty["counterparty_wallet_settings"]["status"];
	readonly isVisible?: boolean;
	readonly displayVisibilityStatus?: boolean;
};

const priorityIconsMap = {
	0: <Priority0 className={styles.priority} />,
	25: <Priority25 className={styles.priority} />,
	50: <Priority50 className={styles.priority} />,
	75: <Priority75 className={styles.priority} />,
	100: <Priority100 className={styles.priority} />,
};

const CounterpartyItem: React.FC<CounterpartyItemProps> = ({
	identifier,
	onEdit,
	priority,
	status,
	isVisible,
	displayVisibilityStatus,
}) => {
	return (
		<div className={styles.row}>
			<div className={styles.firstLine}>
				{identifier}

				<Tooltip tip={`${priorityMapping[priority]} приоритет`} preferredSide="bottom" forcePreferred>
					{priorityIconsMap[priority]}
				</Tooltip>

				{displayVisibilityStatus && (
					<Tooltip tip={isVisible ? "Видимость включена" : "Видимость выключена"} preferredSide="bottom" forcePreferred>
						{isVisible ? (
							<VisibileIcon className={styles.visibility} />
						) : (
							<InvisibleIcon className={styles.visibility} />
						)}
					</Tooltip>
				)}

				{typeof onEdit === "function" && (
					<Button variant="text-secondary" onClick={onEdit} classname={styles.editButton}>
						<PenIcon activeClass={styles.penIcon} />
					</Button>
				)}
			</div>

			<div className={styles.status}>
				<StatusBadges state={status} preferredTooltipSide="bottom" />
			</div>
		</div>
	);
};

export default CounterpartyItem;
