// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Money__Money-V5Y{font-weight:400;font-size:14px;line-height:20px}.Money__Money-V5Y.Money__ingoing-sQs{color:#24a148}.Money__Money-V5Y.Money__outgoing-MWS{color:#dc3545}`, "",{"version":3,"sources":["webpack://./src/components/Money/Money.module.scss"],"names":[],"mappings":"AAAA,kBACI,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,qCAEI,aAAA,CAGJ,sCAEI,aAAA","sourcesContent":[".Money {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n\n    &.ingoing {\n        /* Green/50 */\n        color: #24a148;\n    }\n\n    &.outgoing {\n        /* Red/60 */\n        color: #dc3545;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Money": `Money__Money-V5Y`,
	"ingoing": `Money__ingoing-sQs`,
	"outgoing": `Money__outgoing-MWS`
};
export default ___CSS_LOADER_EXPORT___;
