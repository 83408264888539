const ProxyIcon = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 14.5C4.40252 14.5 1.5 11.5975 1.5 8C1.5 4.40252 4.40252 1.5 8 1.5C11.5975 1.5 14.5 4.40252 14.5 8C14.5 11.5975 11.5975 14.5 8 14.5ZM6.9 2.60927C5.23423 2.94532 3.84498 4.03033 3.09661 5.49996H5.50696C5.79214 4.47561 6.25649 3.49411 6.9 2.60927ZM2.5 8C2.5 7.47965 2.57168 6.97653 2.70573 6.49996H5.28737C5.12622 7.49231 5.12622 8.50761 5.28736 9.49996H2.70571C2.57167 9.02341 2.5 8.52032 2.5 8ZM3.09656 10.5C3.84493 11.9696 5.23421 13.0547 6.90003 13.3907C6.25649 12.5059 5.79213 11.5243 5.50695 10.5H3.09656ZM6.5501 10.5C6.85346 11.461 7.33676 12.3732 8 13.1786C8.66324 12.3732 9.14654 11.461 9.4499 10.5H6.5501ZM10.4931 10.5C10.2079 11.5243 9.74352 12.5059 9.09999 13.3907C10.7658 13.0547 12.1551 11.9696 12.9034 10.5H10.4931ZM13.2943 9.49996H10.7126C10.8738 8.50761 10.8738 7.49231 10.7126 6.49996H13.2943C13.4283 6.97653 13.5 7.47965 13.5 8C13.5 8.52032 13.4283 9.02341 13.2943 9.49996ZM9.69785 9.49996H6.30215C6.12129 8.5097 6.12129 7.49022 6.30216 6.49996H9.69784C9.87871 7.49022 9.87872 8.5097 9.69785 9.49996ZM10.493 5.49996H12.9034C12.155 4.03033 10.7658 2.94532 9.10001 2.60927C9.74352 3.49411 10.2079 4.47561 10.493 5.49996ZM8 2.82136C7.33678 3.6267 6.85348 4.5389 6.55011 5.49996H9.44989C9.14652 4.5389 8.66323 3.6267 8 2.82136Z"
			fill="#697077"
		/>
	</svg>
);

export default ProxyIcon;