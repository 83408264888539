import styles from "./Actions.module.scss";
import { WalletListItem } from "api/walletGroup";
import React, { useContext } from "react";
import EditIcon from "modules/Wallets/ActiveWallets/components/Actions/icons/EditIcon";
// import DebugIcon from "modules/Wallets/ActiveWallets/components/Actions/icons/DebugIcon";
import {WalletContext} from "modules/Wallets/contexts/WalletContext";
import {useNavigate} from "react-router-dom";
import ProxyIcon from "modules/Wallets/ActiveWallets/components/Actions/icons/ProxyIcon";
import {Button} from "pay-kit";
import { ParserType, WalletState } from 'modules/Wallets/components/modals/ChangeWalletModal/utils/enums';
import AuthContext from 'contexts/AuthContext';
import { Roles } from 'contexts/AuthContext/utils/enums';
import { WalletTypes } from 'utils/enums';

type ActionsProps = {
	readonly id: WalletListItem[`id`];
	readonly identifier: WalletListItem[`identifier`];
	readonly type: WalletListItem[`type`];
	readonly currency: WalletListItem[`currency`][`code`];
	readonly state: WalletState[];
	readonly proxies: WalletListItem["proxies"];
	readonly sms_source: WalletListItem["wallet_settings"]["sms_source"];
	readonly parser_type: WalletListItem["wallet_settings"]["parser_type"];
};

const Actions = ({ id, identifier, type, currency, proxies, sms_source, parser_type, state }: ActionsProps) => {
	//TODO: Завести в ui-kit серую кнопку и юзать тут кнопки из кита
	const { hasRole } = useContext(AuthContext);

	const updateAccess = hasRole(Roles.WALLET_UPDATE);
	const proxyAccess = hasRole(Roles.PROXY_LIST);
	const disableAccess = hasRole(Roles.WALLET_SET_ACTIVE);
	const logAccess = hasRole(Roles.LOGS);
	const { openEditWalletModal, openSmsWalletModal, openEditProxiesModal } = useContext(WalletContext);

	const navigate = useNavigate();

	const handleEdit = (e: React.MouseEvent) => {
		e.stopPropagation();
		openEditWalletModal(id);
	};

	//TODO: Поменять ссылку когда появится страница
	const handleDebug = (e: React.MouseEvent) => {
		e.stopPropagation();
		navigate(`/change-logs?object_type=wallet&object_id=${id}`);
	};

	const handleLogDebug = (e: React.MouseEvent) => {
		e.stopPropagation();
		navigate(`/logs?wallet_hash_id=${id}`);
	};

	const handleNavigate = () => {
		localStorage.setItem(
			`pluginConstructor`,
			JSON.stringify({
				w_id: id,
				wallet_type: type,
				currency_code: currency,
				label: `label`,
			})
		);
		navigate("/payment-systems/plugin");
	};

	const handleSMS = () => {
		openSmsWalletModal(id);
	};

	const handleProxyEdit = () => {
		openEditProxiesModal({
			id,
			identifier,
			proxies,
		});
	};

	//status.includes(WalletState.PENDING_SMS_CONFIRMATION)
	return (
		<div className={styles.wrapper}>
			{updateAccess && (
				<Button onClick={handleEdit} variant="secondary">
					<EditIcon />
					<div className={styles.label}>Редактировать</div>
				</Button>
			)}
			{updateAccess &&
				type === WalletTypes.PAY_TM &&
				((state || []).includes(WalletState.PENDING_SMS_CONFIRMATION) || parser_type === ParserType.MOBILE_API) &&
				sms_source == "manual_input" && (
					<Button onClick={handleSMS} variant="color" color="orange" textColor="white">
						<div className={styles.label}>Ввести СМС</div>
					</Button>
				)}
			{updateAccess && proxyAccess && (
				<Button onClick={handleProxyEdit} variant="secondary">
					<ProxyIcon />
					<div className={styles.label}>Прокси</div>
				</Button>
			)}
			{/* {logAccess && (
				<Button onClick={handleLogDebug} variant="secondary">
					<DebugIcon />
					<div className={styles.label}>Отладка</div>
				</Button>
			)} */}
		</div>
	);
};

export default Actions;
