import { OptionType } from "components/Filter/Field";

export const adaptFilters = (src: Record<string, string | OptionType>): Record<string, string> => {
  const adaptedFilters: Record<string, string> = {};

  Object.keys(src).forEach((key) => {
    const value = src[key];

    if (typeof value === "string") {
      adaptedFilters[key] = value;
    } else if (value && typeof value === "object" && "value" in value) {
      adaptedFilters[key] = value.value;
    }
  });

  return adaptedFilters;
};