import { WalletsEditData } from "modules/Proxies/contexts/ProxyContext";
import { EditWalletsForm } from "./components/form/EditWalletsForm";
import styles from "./editWalletsModal.module.scss";

type Props = {
	data: WalletsEditData;
	attach: (uuids: any[], id: any) => Promise<StandardResponse<any>>;
	detach: (uuids: any[], id: any) => Promise<StandardResponse<any>>;
	close: () => void;
};

export const EditWallets = ({ data, close, attach, detach }: Props) => {
	return (
		<div>
			<div className={styles.header}>
				<div className={styles.info}>
					<div>IP:</div>
					<div className={styles.infoValue}>{data.ip}</div>
				</div>
				<div className={styles.info}>
					<div>Порт:</div>
					<div className={styles.infoValue}>{data.port}</div>
				</div>
			</div>
			<EditWalletsForm data={data} attach={attach} detach={detach} close={close} />
		</div>
	);
};
