import styles from "./Actions.module.scss";
import { WalletListItem } from "api/walletGroup";
import React, { useContext } from "react";
import { Button } from "pay-kit";
import { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext";
import RecoverIcon from "modules/Wallets/ArchivedWallets/components/Actions/icons/RecoverIcon";
import ProxyIcon from "modules/Wallets/ActiveWallets/components/Actions/icons/ProxyIcon";
import AuthContext from 'contexts/AuthContext';
import { Roles } from 'contexts/AuthContext/utils/enums';

type ActionsProps = {
	readonly id: WalletListItem[`id`];
	readonly identifier: WalletListItem[`identifier`];
	readonly proxies: WalletListItem["proxies"];
};

const Actions = ({ id, identifier, proxies }: ActionsProps) => {
	const { openRecoverWalletModal, openDeleteWalletModal, openEditProxiesModal } = useContext(ArchivedWalletContext);
	const { hasRole } = useContext(AuthContext);

	const hardDeleteAccess = hasRole(Roles.WALLET_HARD_DELETE);
	const archiveAccess = hasRole(Roles.WALLET_DELETE);
	const proxyAccess = hasRole(Roles.PROXY_LIST);
	const updateAccess = hasRole(Roles.WALLET_UPDATE);

	const handleRecover = (e: React.MouseEvent) => {
		e.stopPropagation();
		openRecoverWalletModal(id);
	};

	const handleDelete = (e: React.MouseEvent) => {
		e.stopPropagation();
		openDeleteWalletModal(id);
	};

	const handleProxyEdit = () => {
		openEditProxiesModal({
			id,
			identifier,
			proxies,
		});
	};

	return (
		<div className={styles.wrapper}>
			{archiveAccess && (
				<Button onClick={handleRecover}>
					<RecoverIcon />
					<div className={styles.label}>Восстановить</div>
				</Button>
			)}
			{updateAccess && proxyAccess && (
				<Button onClick={handleProxyEdit} variant="secondary">
					<ProxyIcon />
					<div className={styles.label}>
						Прокси
					</div>
				</Button>
			)}
			{hardDeleteAccess && (
				<Button variant="delete" onClick={handleDelete}>
					Удалить
				</Button>
			)}
		</div>
	);
};

export default Actions;
