import { WalletsEditData } from "modules/Proxies/contexts/ProxyContext";
import { Form } from "./components/form/EditWalletsForm";

/**
 * Класс для работы с коллекциями hash id кошельков.
 * Сравнивает кошельки с формы и кошельками с бекенда.
 */
export class WalletsIdsCollection {
	/**
	 * Id кошельков, выбранных на форме.
	 * Это состояние селекта "ПОСЛЕ".
	 */
	private formWalletsIds: Array<string>;

	/**
	 * Id кошельков, пришедшие с бекенда.
	 *  Это состояние "ДО"
	 */
	private initialWallets: Array<string>;

	constructor(form: Form, dataForWalletsEdit: WalletsEditData) {
		this.formWalletsIds = this.prepareFormWalletsIds(form);
		this.initialWallets = this.prepareInitialWalletsIds(dataForWalletsEdit);
	}

	/**
	 * И которых нет в изначальном списке.
	 * Поиск кошельков, которые есть в форме.
	 *
	 * @returns Добавленные кошельки пользователем в форме
	 */
	public findTheAddedWallets(): Array<string> {
		const addedWallets = this.formWalletsIds.filter((wallet_id) => !this.initialWallets.includes(wallet_id));

		return addedWallets;
	}

	/**
	 * Поиск кошельков, которые есть в изначальном списке
	 * И которых нет в форме
	 *
	 * @returns Удаленные кошельки пользователем в форме
	 */
	public findDeletedWallets(): Array<string> {
		const deletedWallets = this.initialWallets.filter((initialId) => !this.formWalletsIds.includes(initialId));

		return deletedWallets;
	}

	private prepareFormWalletsIds(form: Form) {
		return form.wallet_hash_ids.map((wallet) => wallet.value);
	}

	private prepareInitialWalletsIds(data: WalletsEditData): Array<string> {
		return data.wallets.map((el) => el.hash_id);
	}
}

export const createIdsCollection = (form: Form, dataForWalletsEdit: WalletsEditData): WalletsIdsCollection => {
	return new WalletsIdsCollection(form, dataForWalletsEdit);
};

export const filterSelectedOptions = (
	walletsNumbersOptions: Array<{ value: string; label: string }>,
	selectedValues: string[]
) => {
	return walletsNumbersOptions.filter((option) => !selectedValues.includes(option.value));
};
