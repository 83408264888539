import CaretDown from "assets/icons/caret.svg";
import { Button } from "pay-kit";
import { Dispatch, FC, SetStateAction } from "react";

import styles from "./caret.module.scss";

type ToggleButtonType = {
	readonly setShowAll: Dispatch<SetStateAction<boolean>>
	readonly showAll: boolean
	readonly customClass?: string
	readonly value?: {
		readonly hideText?: string
		readonly openText?: string
	}
}
const ToggleButton: FC<ToggleButtonType> = ({
	setShowAll,
	showAll,
	customClass,
	value
}) => {

	const caretPosClass = showAll ? styles.caretDOWN : styles.caretUP;
	const openText = value?.openText || `Показать всё`;
	const hideText = value?.hideText || `Скрыть`;
	const buttonText = showAll ? hideText : openText;

	const handleOnClick = () => setShowAll((prevState) => !prevState);

	return (
		<div className={[styles.caretWrapper, customClass].join(" ")}>
			<Button
				data-test-id="L6r09bLYBq6vLlbgeiHmN"
				onClick={handleOnClick}
				classname={styles.showAllButton}
			>
				<span className={[styles.caret, caretPosClass].join(" ")}>
					<CaretDown />
				</span> {buttonText}
			</Button>
		</div>
	);
};

export default ToggleButton;
