import { DeleteProxyType } from "api/proxiesGroup";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { Button } from "pay-kit";
import { Dispatch, FC, SetStateAction } from "react";

import styles from "./deleteProxiesModal.module.scss";

const DeleteProxies: FC<DeleteProxiesType> = ({ IDsForDelete }) => {
	const { deleteProxy, proxies, setIsDeleteProxiesModal } = useProxyContext();

	const OnDelete = () => {
		const { proxies } = IDsForDelete;
		const uuids = proxies.map((proxy) => proxy.uuid ? proxy.uuid : proxy);

		deleteProxy({ uuids });
		setIsDeleteProxiesModal(false);
	};

	const OnCancel = () => setIsDeleteProxiesModal(false);

	const renderProxies = () => {
		const filteredProxies = proxies.filter(({ id }) => IDsForDelete && IDsForDelete.proxies.includes(id));

		return (
			<ul className={styles.proxiesList}>
				{
					filteredProxies.map(({ ip }, i) =>
						<li className={styles.proxyItem} key={i}>
							{ip}
						</li>
					)
				}
			</ul>
		);
	};

	return (
		<div className={styles.deleteProxiesWrapper}>
			<div className={styles.deleteConfirmText}>
				Вы уверены, что хотите удалить прокси?
			</div>

			{IDsForDelete && IDsForDelete.isIPlist && renderProxies()}

			<div className={styles.manipulateProxiesButtons}>
				<Button data-test-id="mWkBFJ4PN1gf5Wz0XdEJF"
					classname={styles.cancel}
					onClick={OnCancel}
				>
					Отмена
				</Button>

				<Button data-test-id="1lo26Tl4EpW2pOy3wUyeA"
					classname={styles.confirm}
					onClick={OnDelete}
					disabled={!Object.keys(IDsForDelete).length}
				>
					Удалить
				</Button>
			</div>
		</div>
	);
};

export default DeleteProxies;


type DeleteProxiesType = {
	readonly IDsForDelete: (DeleteProxyType & { readonly isIPlist?: boolean })
	readonly setIsDeleteProxiesModal: Dispatch<SetStateAction<boolean>>
}
