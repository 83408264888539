import { DeleteModal } from "components/Modals/DeleteModal/DeleteModal";
import { ModalProps } from "utils/types";
import { WalletListItem } from "api/walletGroup";
import { StandardResponse } from "api/types";

type ClearCookieModalProps = ModalProps & {
	readonly id: WalletListItem[`id`];
	readonly actions: {
		readonly clearCookie: () => Promise<StandardResponse<void>>;
	};
};

export const ClearCookieModal = ({ closeModal, id, actions }: ClearCookieModalProps) => {
	return (
		<DeleteModal
			closeModal={closeModal}
			text={
				<>
					Вы действительно хотите сбросить куки для кошелька <b>{id}</b>?
				</>
			}
			deleteLabel="Сбросить"
			actions={{
				onDelete: () => {
					return actions.clearCookie().then((resp) => {
						if (resp.status === "success") {
							window.pushAlert({
								type: "success",
								title: "Успех",
								description: `Куки кошелька были успешно сброшены`,
							});
						} else {
							window.pushAlert({
								type: "error",
								title: "Ошибка",
								description: resp.error_message,
							});
						}
					});
				},
			}}
		/>
	);
};
