import { RefreshData, TokenData, UserData } from "contexts/AuthContext/utils/types";

import { fetchApi, fetchOldApi } from "./fetch";

type UserMeResponse = {
	user: UserData;
};

const authGroup = {
	login: (login: string, password: string) =>
		fetchOldApi<TokenData>({
			url: "auth/login",
			config: { data: { name: login, password, withToken: false } },
			method: "POST",
		}),
	me: () => fetchOldApi<UserMeResponse>({ url: "auth/me" }),
	refresh: () => fetchApi<RefreshData>({ url: "auth/refresh", method: "POST" }),
	logout: (refreshToken: string) =>
		fetchApi<void>({ url: "auth/logout", config: { data: { refresh_token: refreshToken } }, method: "POST" }),
};

export default authGroup;
