import PageTitle from "components/PageTitle";
import { useCounterparty } from "modules/Counterparty/hooks/useCounterparty";
import RelaunchButton from "components/RelaunchButton";
import ThreeDotes from "assets/icons/threeDotes.svg";
import SearchWithSquare from "assets/icons/searchWithSquare.svg";
import EditPen from "assets/icons/edit_16x16.svg";
import Trash from "assets/icons/trash_16x16.svg";
import { Table, Tooltip } from "@paykassma/pay-kit";
import DropdownButton from "components/DropdownButton";
import DeleteModal from "../components/DeleteModal";
import EditModal from "../components/EditModal";
import { TableColumnType } from "@paykassma/pay-kit/lib/elements/Table/types";
import { useSearchParams } from "react-router-dom";
import Filters from "../components/Filters";
import { useIndexPage } from "./useIndexPage";
import styles from "./IndexPage.module.scss";
import { getGroupByKey } from "../utils/mapGroups";
import { Counterparty } from "api/counterpartyGroup";
import { ErrorBoundary } from "react-error-boundary";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { buildRedirectorURL } from './ExternalDashboardRedirector/utils';

const columns: Array<TableColumnType> = [
	{
		title: "ID",
		key: "id",
		dataIndex: "id",
	},
	{
		title: "IP-Адрес",
		key: "ipAddress",
		dataIndex: "ipAddress",
	},
	{
		title: "Домен",
		key: "domain",
		dataIndex: "domain",
	},
	{
		title: "Группа",
		key: "group",
		dataIndex: "group",
	},
	{
		title: "Действия",
		key: "actions",
		dataIndex: "actions",
		className: styles.actions,
	},
];

export const IndexPage = () => {
	const [searchParams] = useSearchParams();
	const { hasRole } = useAuthContext();
	const { counterparties, isListLoading, fetchCounterparties } = useCounterparty({
		group: searchParams.get("group"),
	});
	const { openedModal, selectedElement, setOpenedModal, setSelectedElement, handleCloseModal } = useIndexPage();

	const mapDataForTable = (counterparties?: Array<Counterparty>) => {
		if (!counterparties) return [];

		return counterparties.map((el: Counterparty) => {
			return {
				id: el.identifier,
				ipAddress: el.domain_ip,
				domain: (
					<a
						href={buildRedirectorURL(el.link, el.domain, el.id)}
						target="_blank"
						rel="noreferrer"
					>
						{el.domain}
					</a>
				),
				group: getGroupByKey(el.group),
				actions: (
					<div className={styles.statistics}>
						<Tooltip preferredSide="bottom" tip="Количество активных парсеров">
							<div className={styles.info}>
								<SearchWithSquare />
								<span>{el.count.scrapers}</span>
							</div>
						</Tooltip>
						{hasRole(Roles.COUNTERPARTY_MANAGE) && (
							<DropdownButton
								withChevrone={false}
								className={styles.dropdownBtn}
								dropdownClassName={styles.dropdownOption}
								options={[
									{
										id: 0,
										onClick: () => {
											setOpenedModal("edit");
											setSelectedElement(el);
										},
										title: (
											<div className={styles.button}>
												<EditPen /> <span>Редактировать</span>
											</div>
										),
									},
									{
										id: 1,
										onClick: () => {
											setOpenedModal("delete");
											setSelectedElement(el);
										},
										title: (
											<div className={styles.button}>
												<Trash />
												<span>Удалить</span>
											</div>
										),
									},
								]}
							>
								<ThreeDotes />
							</DropdownButton>
						)}
					</div>
				),
			};
		});
	};

	return (
		<>
			{selectedElement?.id && (
				<DeleteModal isOpen={openedModal === "delete"} onClose={handleCloseModal} idForDelete={selectedElement?.id} />
			)}

			{selectedElement && (
				<EditModal isOpen={openedModal === "edit"} selectedElement={selectedElement} onClose={handleCloseModal} />
			)}

			<PageTitle title="Контрагенты" />

			<Filters />

			<RelaunchButton onClick={fetchCounterparties} btnProps={{ variant: "text-primary", isFullWidth: false }}>
				Обновить
			</RelaunchButton>
			<ErrorBoundary fallback={<div>Error</div>}>
				<Table columns={columns} rowKey="id" isLoading={isListLoading} data={mapDataForTable(counterparties)} />
			</ErrorBoundary>
		</>
	);
};
