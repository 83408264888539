// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogsListColumns__errorType-a5W{color:red}.LogsListColumns__messageWrapper-QRd{width:386px}.LogsListColumns__time-h6l{margin-top:4px;color:#697077}`, "",{"version":3,"sources":["webpack://./src/modules/LogsList/components/LogsListColumns/logsListColumns.module.scss"],"names":[],"mappings":"AAAA,gCACC,SAAA,CAGD,qCACC,WAAA,CAGD,2BACC,cAAA,CACA,aAAA","sourcesContent":[".errorType {\n\tcolor: red;\n}\n\n.messageWrapper {\n\twidth: 386px;\n}\n\n.time {\n\tmargin-top: 4px;\n\tcolor: #697077;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorType": `LogsListColumns__errorType-a5W`,
	"messageWrapper": `LogsListColumns__messageWrapper-QRd`,
	"time": `LogsListColumns__time-h6l`
};
export default ___CSS_LOADER_EXPORT___;
