// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditableComment__editableComment-msQ{word-break:break-all;flex-direction:column;align-items:initial}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/SideModalContent/EditableComment/editableCommentContainer.module.scss"],"names":[],"mappings":"AAAA,sCACC,oBAAA,CACA,qBAAA,CACA,mBAAA","sourcesContent":[".editableComment {\n\tword-break: break-all;\n\tflex-direction: column;\n\talign-items: initial;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editableComment": `EditableComment__editableComment-msQ`
};
export default ___CSS_LOADER_EXPORT___;
