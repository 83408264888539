import { Tooltip } from "pay-kit";
import CopyTextButton from "components/CopyTextButton";
import styles from "./EllipsisTooltip.module.scss";

type EllipsisTooltipPropsType = {
	readonly text: string;
	readonly canCopy?: boolean;
	readonly maxLines?: number;
	readonly maxCharLength?: number;
	readonly tooltipProps?: {
		readonly preferredSide?: "bottom" | "top" | "left" | "right";
		readonly forcePreferred?: boolean;
	};
	readonly dataTestId?: string;
	readonly onClick?: () => void;
};

const EllipsisTooltip = ({
	text,
	canCopy,
	maxLines,
	maxCharLength,
	onClick,
	tooltipProps,
	dataTestId,
}: EllipsisTooltipPropsType) => {
	if (text === "" || text === undefined) {
		return null;
	}

	const classNames = [styles.ellipsisContent, onClick ? styles.interactive : "", maxLines ? styles.clipped : ""];

	const vClampStyles =
		(maxLines && {
			LineClamp: maxLines,
			WebkitLineClamp: maxLines,
		}) ||
		undefined;

	const hClampStyles = maxCharLength ? { maxWidth: `${maxCharLength}ch` } : undefined;

	return (
		<div className={styles.wrapper}>
			<Tooltip tip={text} preferredSide="bottom" {...tooltipProps}>
				<div
					className={classNames.join(" ")}
					onClick={onClick}
					data-test-id={dataTestId}
					style={{ ...vClampStyles, ...hClampStyles }}
				>
					{text}
				</div>
			</Tooltip>
			{canCopy && <CopyTextButton text={text} />}
		</div>
	);
};

export default EllipsisTooltip;
