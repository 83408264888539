// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletCounterpartyContext__listCounterpartyModal-q6Z{width:560px}.WalletCounterpartyContext__listCounterpartyModal-q6Z>div[class^=Modal__header]{border-bottom:unset}.WalletCounterpartyContext__listCounterpartyModal-q6Z>div[class^=Modal__content]{margin-top:0}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/contexts/WalletCounterpartyContext/WalletCounterpartyContext.module.scss"],"names":[],"mappings":"AAAA,sDACC,WAAA,CACA,gFACC,mBAAA,CAGD,iFACC,YAAA","sourcesContent":[".listCounterpartyModal {\n\twidth: 560px;\n\t> div[class^=\"Modal__header\"] {\n\t\tborder-bottom: unset;\n\t}\n\n\t> div[class^=\"Modal__content\"] {\n\t\tmargin-top: 0;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listCounterpartyModal": `WalletCounterpartyContext__listCounterpartyModal-q6Z`
};
export default ___CSS_LOADER_EXPORT___;
