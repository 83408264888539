// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN{display:flex;gap:8px;margin-left:-16px}.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN .TableManipulateButtonsGroup__relaunch-d1r{padding-left:8px;color:#fff}.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN .TableManipulateButtonsGroup__relaunch-d1r>svg{width:32px;color:#fff}.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN .TableManipulateButtonsGroup__relaunch-d1r:hover{background:#00539a}.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN .TableManipulateButtonsGroup__deleteButton-yKj{gap:8px}.TableManipulateButtonsGroup__buttonsGroupWrapper-IWN .TableManipulateButtonsGroup__deleteButton-yKj .TableManipulateButtonsGroup__deleteIcon-PVe{width:20px;color:#fff}`, "",{"version":3,"sources":["webpack://./src/modules/Proxies/components/TableManipulateButtonsGroup/tableManipulateButtonsGroup.module.scss"],"names":[],"mappings":"AAAA,sDACI,YAAA,CACA,OAAA,CACH,iBAAA,CAEG,iGACI,gBAAA,CACA,UAAA,CAEA,qGACI,UAAA,CACT,UAAA,CAIC,uGACI,kBAAA,CAGJ,qGACI,OAAA,CAEA,kJACI,UAAA,CACA,UAAA","sourcesContent":[".buttonsGroupWrapper {\n    display: flex;\n    gap: 8px;\n\tmargin-left: -16px;\n\n    .relaunch {\n        padding-left: 8px;\n        color: #FFFFFF;\n\n        >svg {\n            width: 32px;\n\t\t\tcolor: #fff;\n        }\n    }\n\n    .relaunch:hover {\n        background: #00539a;\n    }\n\n    .deleteButton {\n        gap: 8px;\n\n        .deleteIcon {\n            width: 20px;\n            color: #FFFFFF;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsGroupWrapper": `TableManipulateButtonsGroup__buttonsGroupWrapper-IWN`,
	"relaunch": `TableManipulateButtonsGroup__relaunch-d1r`,
	"deleteButton": `TableManipulateButtonsGroup__deleteButton-yKj`,
	"deleteIcon": `TableManipulateButtonsGroup__deleteIcon-PVe`
};
export default ___CSS_LOADER_EXPORT___;
