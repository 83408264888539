import { ProxiesType } from "api/proxiesGroup";
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import styles from "../ProxiesTableColumns/proxiesTableColumns.module.scss";
import EditPen from "assets/icons/editPen.svg";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";

export type WalletAmountProps = {
	wallets: Array<{ hash_id: string, identifier: string, wallet_type_code: string }>;
	ip: ProxiesType["ip"];
	port: ProxiesType["port"];
	id: ProxiesType["id"];
	type: ProxiesType["type"];
};

const WalletAmount = (props: WalletAmountProps) => {
	const { openWalletEditModal } = useProxyContext();
	const { hasRole } = useAuthContext();

	const { wallets } = props;

	const handleClick = () => {
		openWalletEditModal(props);
	};

	if (wallets && wallets.length === 0) {
		return (
			<div className={styles.walletsAmount} onClick={handleClick}>
				<PlusIcon className={styles.walletsAmountPlus} />
			</div>
		);
	}

	return (
		<div className={styles.walletsAmount} onClick={handleClick}>
			<div>{wallets?.length || 0}</div>
			{hasRole(Roles.PROXY_UPDATE) && hasRole(Roles.WALLET_LIST) && (
				<div className={styles.walletsAmountIcon}>
					<EditPen />
				</div>
			)}
		</div>
	);
};

export default WalletAmount;
