import API from "api";
import { WalletListItem } from "api/walletGroup";
import EditableComment from "components/EditableComment";
import React, { useState } from "react";

import style from "./editableCommentContainer.module.scss";

interface IUpdateCommentProps {
	readonly hash_id: WalletListItem["hash_id"];
	readonly comment: WalletListItem["comment"];
	readonly onSuccess: (comment: string | null) => void;
}

const EditableCommentContainer: React.FC<IUpdateCommentProps> = (props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [comment, setComment] = useState<string | null>(props.comment);
	const [editingModeOn, setEditingModeOn] = useState<boolean>(false);

	const updateSettings = (comment: string | null) => {
		setIsLoading(true);

		API.wallet
			.updateSetting({ hash_id: props.hash_id, comment })
			.then((res) => {
				if (res.status === "success") {
					window.pushAlert({ description: "Комментарий успешно изменен", type: "success" });
					setEditingModeOn(false);
					props.onSuccess(comment);
					return;
				} else {
					throw new Error("Unexpected response in updateSettings");
				}
			})
			.catch((err) => {
				console.error(err);
				window.pushAlert({ description: "Не удалось изменить комментарий", type: "error" });
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<EditableComment
			data-test-id="useless-data-test-id"
			{...{
				className: style.editableComment,
				isLoading,
				editingModeOn,
				setEditingModeOn,
				initialState: props.comment,
				value: comment,
				maxLength: 60,
				onChange: setComment,
				onSubmit: updateSettings,
			}}
		/>
	);
};

export default EditableCommentContainer;
