import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import { useTranslation } from "pay-kit";
import WalletContextProvider, { WalletContext } from "modules/Wallets/contexts/WalletContext";
import WalletCounterpartyContextProvider from "modules/Wallets/contexts/WalletCounterpartyContext";
import ActiveWallets from "modules/Wallets/ActiveWallets";
import React, { useEffect } from "react";

const ActiveWalletsPage: React.FC = () => {
	const { t } = useTranslation();
	const { hasRole } = useAuthContext();

	useEffect(() => {
		document.title = t("Active wallets");

		hasRole(Roles.WALLET_LIST, { redirectToErrorPage: true });
	}, [hasRole, t]);

	return (
		<WalletContextProvider type="active">
			<WalletCounterpartyContextProvider ctx={WalletContext} walletTypes="active">
				<ActiveWallets />
			</WalletCounterpartyContextProvider>
		</WalletContextProvider>
	);
};

export default ActiveWalletsPage;
