import { PayKitForm } from "@paykassma/pay-kit";
import { StandardResponse } from "api/types";
import styles from './CommentModal.module.scss';

type CommentModalProps = {
    readonly comment: string | null,
    closeModal: () => void,
    actions: {
        setComment: (form: any) => Promise<StandardResponse<void>>
    }
}


const CommentModal = ({ comment, closeModal, actions }: CommentModalProps) => {
    const handleSubmit = (form: any) => {
        actions.setComment(form).then((resp) => {
            if (resp.status === "success") {
                window.pushAlert({
                    type: "success",
                    title: "Успех",
                    description: "Комментарий успешно сохранен",
                });
                closeModal();
            } else {
                window.pushAlert({
                    type: "error",
                    title: "Ошибка",
                    description: resp.error_message,
                });
            }
        });
    }

    const schema = [
        {
            name: "comment",
            label: "Комментарий",
            type: "Textarea",
            rows: 8
        },
        {
            type: "SubmitButton",
            label: "Сохранить",
            onSubmit: handleSubmit,
        }
    ];

    return (
        <div className={styles.wrapper}>
            <PayKitForm.Builder schema={schema} initialState={{
                comment,
            }}/>
        </div>
    )
}

export default CommentModal;