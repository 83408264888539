// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Comments__commentCell-fLF{cursor:pointer}.Comments__comment-mOJ{display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;line-clamp:2;overflow:hidden;text-overflow:ellipsis}.Comments__commentWrapper-KaD{display:flex;gap:4px;align-items:center;justify-content:flex-start}.Comments__commentWrapper-KaD .Comments__editButton-ziw{opacity:0;transition:opacity .3s}.Comments__commentWrapper-KaD:hover .Comments__editButton-ziw{opacity:1}.Comments__commentWrapper-KaD [class*=Button__Button]{min-width:auto;padding:0 4px;height:auto}.Comments__commentWrapper-KaD [class*=Button__Button] svg{width:16px}.Comments__penIcon-tPa{fill:#697077}`, "",{"version":3,"sources":["webpack://./src/modules/Wallets/components/Comments/Comments.module.scss"],"names":[],"mappings":"AAAA,2BACC,cAAA,CAGD,uBACC,mBAAA,CACA,2BAAA,CACA,oBAAA,CACA,YAAA,CACA,eAAA,CACA,sBAAA,CAGD,8BACC,YAAA,CACA,OAAA,CACA,kBAAA,CACA,0BAAA,CAEA,wDACC,SAAA,CACA,sBAAA,CAIA,8DACC,SAAA,CAKH,sDACC,cAAA,CACA,aAAA,CACA,WAAA,CAEA,0DACC,UAAA,CAIF,uBACC,YAAA","sourcesContent":[".commentCell {\n\tcursor: pointer;\n}\n\n.comment {\n\tdisplay: -webkit-box;\n\t-webkit-box-orient: vertical;\n\t-webkit-line-clamp: 2;\n\tline-clamp: 2;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n}\n\n.commentWrapper {\n\tdisplay: flex;\n\tgap: 4px;\n\talign-items: center;\n\tjustify-content: flex-start;\n\n\t.editButton {\n\t\topacity: 0;\n\t\ttransition: opacity 0.3s;\n\t}\n\n\t&:hover {\n\t\t.editButton {\n\t\t\topacity: 1;\n\t\t}\n\t}\n}\n\n.commentWrapper [class*=\"Button__Button\"] {\n\tmin-width: auto;\n\tpadding: 0 4px;\n\theight: auto;\n\n\tsvg {\n\t\twidth: 16px;\n\t}\n}\n\n.penIcon {\n\tfill: #697077;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentCell": `Comments__commentCell-fLF`,
	"comment": `Comments__comment-mOJ`,
	"commentWrapper": `Comments__commentWrapper-KaD`,
	"editButton": `Comments__editButton-ziw`,
	"penIcon": `Comments__penIcon-tPa`
};
export default ___CSS_LOADER_EXPORT___;
