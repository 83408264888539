import Api from "api";
import { CountryType } from "api/countryGroup";
import { DeleteProxyType, ProxiesType, UpdateProxyType } from "api/proxiesGroup";
import { WalletType } from "api/walletGroup";
import DeleteProxies from "modules/Proxies/components/DeleteProxiesModal";
import { ProxyCommonType } from "modules/Proxies/components/ProxyModalRows";
import UpdateProxy from "modules/Proxies/components/UpdateProxyModal";
import { proxyCheckingStates, reduce, setTimeoutPromise } from "modules/Proxies/contexts/helpers";
import { Modal } from "pay-kit";
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useReducer, useState } from "react";

import styles from "./proxyContext.module.scss";
// import EditWallets from "modules/Proxies/components/EditWallets";
import { EditWallets } from "modules/Proxies/components/EditWallets/EditWallets";
import { WalletAmountProps } from "modules/Proxies/components/WalletAmount";
import { OptionType } from "components/Filter/Field";
import { adaptFilters } from "utils/adaptFilters";

const ProxyContext = createContext({} as ProxyContextType);

export type WalletsEditData = WalletAmountProps & {
	open: boolean;
};

const ProxyContextProvider = ({ children }: { readonly children: JSX.Element }) => {
	const [paginationInfo, setPaginationInfo] = useState<any>({});
	const [filters, setFilters] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [proxies, setProxies] = useState<readonly ProxiesType[]>([]);
	const [countryList, setCountryList] = useState<readonly CountryType[] | null>(null);
	const [isGetCheckedLoading, setIsGetCheckedLoading] = useState(false);
	const [isCheckProxyLoading, setIsCheckProxyLoading] = useState(false);
	const [IDsForDelete, setIDsForDelete] = useState<IDsForDeleteType>({} as IDsForDeleteType);
	const [isDeleteProxiesModal, setIsDeleteProxiesModal] = useState(false);
	const [proxyItem, setProxyItem] = useState<ProxyCommonType | null>(null);
	const [walletsList, setWalletsList] = useState<readonly WalletType[] | null>(null);
	const [isWalletListLoading, setIsWalletListLoading] = useState<boolean>(false);
	const [proxyCheckingState, dispatch] = useReducer(reduce, {} as ReduceStateType, undefined);
	const [isUpdateProxyModal, setIsUpdateProxyModal] = useState(false);
	const [proxyID, setProxyID] = useState<number | undefined>(undefined);
	const [isProxyLoading, setIsProxyLoading] = useState(false);
	const [isProxyItemLoading, setIsProxyItemLoading] = useState(false);
	const [sortDirection, setSortDirection] = useState<"WALLETS_COUNT_DESC" | "WALLETS_COUNT_ASC" | undefined>(undefined);
	const [walletsEditData, setWalletsEditData] = useState<WalletsEditData>({
		open: false,
		wallets: [],
		ip: ``,
		port: 0,
		id: ``,
		type: ``,
	});

	const openWalletEditModal = (data: Omit<WalletsEditData, "open">) => {
		setWalletsEditData({ ...data, open: true });
	};

	const closeWalletEditModal = () => {
		setWalletsEditData({
			open: false,
			wallets: [],
			ip: ``,
			port: 0,
			id: ``,
			type: ``,
		});
	};

	const getProxies = (params = {}) => {
		setIsProxyLoading(true);

		Api.proxy
			.getProxies({ ...params, ...adaptFilters(filters) })
			.then((res) => {
				if (res.status === "success") {
					setProxies(res.data);
					setPaginationInfo(res.paginate);
				} else {
					window.pushAlert({
						title: "Ошибка",
						type: "error",
						description: res.error_message,
					});
				}
			})
			.finally(() => {
				setIsProxyLoading(false);
				setIsUpdateProxyModal(false);
			});
	};

	useEffect(() => {
		setIsLoading(true);

		Api.country
			.getList()
			.then((res) => {
				if (res.status === "success") {
					setCountryList(res.data);
				} else {
					window.pushAlert({
						title: "Ошибка",
						type: "error",
						description: res.error_message,
					});
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const getWallets = () => {
		setIsWalletListLoading(true);
		setWalletsList([]);

		Api.wallet
			.getWallets()
			.then((res) => {
				if (res.status === "success") {
					setWalletsList(res.data);
				} else {
					window.pushAlert({
						title: "Ошибка",
						type: "error",
						description: res.error_message,
					});
				}
			})
			.finally(() => {
				setIsWalletListLoading(false);
			});
	};

	const getChecked = () => {
		setIsGetCheckedLoading(true);

		Api.proxy.getChecked().then((res) => {
			if (res.status === "success" && res.checked) {
				setProxies(res.proxies);
				setIsGetCheckedLoading(false);
				window.pushAlert({
					description: "Проверка прокси выполнена",
					type: "success",
				});
			}

			if (!res.status || res.status === "error" || !res.checked || !res) {
				setIsGetCheckedLoading(true);
				setTimeoutPromise({ milliseconds: 10000, delayedMethod: getChecked });

				window.pushAlert({
					description: "Проверка прокси выполняется",
					type: "warning",
				});
			}
		});
	};

	const initCheckAll = () => {
		setIsGetCheckedLoading(true);

		Api.proxy
			.initCheckAll()
			.then((res) => {
				if (res.status === "success") {
					getChecked();
				} else {
					window.pushAlert({
						title: "Ошибка",
						type: "error",
						description: res.error_message,
					});
				}
			})
			.finally(() => dispatch({ type: proxyCheckingStates.RESET }));
	};

	const checkProxy = (ids: string[]) => {
		setIsCheckProxyLoading(true);

		return Api.proxy
			.checkProxy(ids)
			.then((res) => {
				if (res.status === "success") {
					ids.forEach((uuid) => dispatch({ type: proxyCheckingStates.PENDING, payload: { uuid } }));
					checkResultCheckingByUuid();
				} else {
					window.pushAlert({
						title: "Ошибка",
						type: "error",
						description: res.error_message,
					});
				}
			})
			.finally(() => {
				setIsCheckProxyLoading(false);
			});
	};

	const checkResultCheckingByUuid = () => {
		return Api.proxy.resultCheckByUuid().then((res) => {
			if (res.status === "success") {
				if (res.data.status === false) {
					setTimeout(checkResultCheckingByUuid, 3000);
				} else {
					res.data?.proxies.forEach((proxy) => {
						if (proxy.status === true) {
							dispatch({ type: proxyCheckingStates.SUCCEEDED, payload: { uuid: proxy.uuid } });
							dispatch({ type: proxyCheckingStates.FINALLY, payload: { uuid: proxy.uuid } });
						} else {
							dispatch({ type: proxyCheckingStates.FAILED, payload: { uuid: proxy.uuid } });
							dispatch({ type: proxyCheckingStates.FINALLY, payload: { uuid: proxy.uuid } });
						}

						setProxies((proxies) => {
							const newProxies = proxies.map((prx) => {
								if (prx.id === proxy.uuid) {
									return { ...prx, status: proxy.status };
								}

								return prx;
							});

							return newProxies;
						});
					});
				}
			}
		});
	};

	const checkProxies = async (ids: string[]) => {
		checkProxy(ids);
	};

	const deleteProxy = (params: DeleteProxyType) => {
		setIsLoading(true);

		Api.proxy
			.deleteProxy(params)
			.then((res) => {
				if (res.status === "success") {
					window.pushAlert({
						type: "success",
						description: "Прокси удалён",
					});

					setProxies((prevState) => {
						return prevState.filter((elem) => !params.uuids.includes(elem.id));
					});
				}

				if (res.status === "error") {
					window.pushAlert({
						title: "Ошибка",
						type: "error",
						description: res.error_message,
					});
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const loadProxyItem = (id: number) => {
		setIsLoading(true);
		setIsProxyItemLoading(true);
		setProxyItem(null);

		Api.proxy
			.loadProxyItem(id)
			.then((res) => {
				if (res.status === "success") {
					setProxyItem(res.data);
				} else {
					window.pushAlert({
						title: "Ошибка",
						type: "error",
						description: res.error_message,
					});
				}
			})
			.finally(() => {
				setIsLoading(false);
				setIsProxyItemLoading(false);
			});
	};

	const attachWallets = (wallet_uuids: string[], proxy_id: string) => {
		return Api.proxy.attachWallets(wallet_uuids, proxy_id);
	};

	const detachWallets = (wallet_uuids: string[], proxy_id: string) => {
		return Api.proxy.detachWallets(wallet_uuids, proxy_id);
	};

	const updateProxy = (params: Partial<ProxyCommonType>, id: number) => {
		setIsProxyLoading(true);
		return Api.proxy
			.updateProxy(params as UpdateProxyType, id)
			.then((res) => {
				if (res.status === "success") {
					getProxies();

					window.pushAlert({
						description: `Данные успешно сохранены`,
						type: "success",
					});
				} else {
					window.pushAlert({
						title: "Ошибка",
						type: "error",
						description: res.error_message,
					});
				}
				return res;
			})
			.finally(() => {
				setIsProxyLoading(false);
			});
	};

	const sortedProxies = [...proxies].sort((a: ProxiesType, b: ProxiesType) => {
		if (sortDirection === "WALLETS_COUNT_ASC") {
			return a.wallets.length >= b.wallets.length ? 1 : -1;
		}

		if (sortDirection === "WALLETS_COUNT_DESC") {
			return a.wallets.length <= b.wallets.length ? 1 : -1;
		}

		return 0;
	});

	const contextValue = {
		getProxies,
		loadProxyItem,
		updateProxy,
		deleteProxy,
		checkProxy,
		getWallets,
		initCheckAll,
		checkProxies,
		walletsList,
		countryList,
		proxyCheckingState,
		proxyItem,
		setProxies,
		proxies: sortedProxies,
		setProxyID,
		proxyID,
		isProxyItemLoading,
		setIsProxyLoading,
		isProxyLoading,
		setIsUpdateProxyModal,
		setIsDeleteProxiesModal,
		setIDsForDelete,
		isWalletListLoading,
		isCheckProxyLoading,
		isGetCheckedLoading,
		isLoading,
		openWalletEditModal,
		filters,
		setFilters,
		paginationInfo,
		sortDirection,
		setSortDirection,
	};

	return (
		<ProxyContext.Provider value={contextValue}>
			{children}

			<Modal title="Удаление прокси" onClose={() => setIsDeleteProxiesModal(false)} isOpen={isDeleteProxiesModal}>
				<DeleteProxies IDsForDelete={IDsForDelete} setIsDeleteProxiesModal={setIsDeleteProxiesModal} />
			</Modal>

			<Modal
				className={styles.UpdateProxyModalWrapper}
				title="Редактирование прокси"
				onClose={() => setIsUpdateProxyModal(false)}
				isOpen={isUpdateProxyModal}
			>
				<UpdateProxy />
			</Modal>

			<Modal
				title="Кошельки"
				onClose={closeWalletEditModal}
				className={styles.editWalletsModal}
				isOpen={walletsEditData.open}
			>
				<EditWallets
					attach={attachWallets}
					detach={detachWallets}
					data={walletsEditData}
					close={closeWalletEditModal}
				/>
			</Modal>
		</ProxyContext.Provider>
	);
};

export default ProxyContextProvider;

export const useProxyContext = () => useContext(ProxyContext);

export type ProxyContextType = {
	readonly getProxies: (params?: Record<any, any>) => void;
	readonly loadProxyItem: (id: number) => void;
	readonly updateProxy: (params: Partial<ProxyCommonType>, id: number) => void;
	readonly deleteProxy: (id: DeleteProxyType) => void;
	readonly checkProxy: (id: number) => void;
	readonly getWallets: (params: boolean) => void;
	readonly initCheckAll: () => void;
	readonly checkProxies: (ids: readonly string[]) => void;
	readonly walletsList: readonly WalletType[] | null;
	readonly countryList: readonly CountryType[] | null;
	readonly proxyCheckingState: ReduceStateType | undefined;
	readonly proxyItem: ProxyCommonType | null;
	readonly setProxies: Dispatch<SetStateAction<readonly ProxiesType[]>>;
	readonly proxies: readonly ProxiesType[];
	readonly setProxyID: Dispatch<SetStateAction<number | undefined>>;
	readonly proxyID?: number;
	readonly isProxyItemLoading: boolean;
	readonly setIsProxyLoading: Dispatch<SetStateAction<boolean>>;
	readonly isProxyLoading: boolean;
	readonly setIsUpdateProxyModal: Dispatch<SetStateAction<boolean>>;
	readonly setIsDeleteProxiesModal: Dispatch<SetStateAction<boolean>>;
	readonly setIDsForDelete: Dispatch<SetStateAction<IDsForDeleteType>>;
	readonly isWalletListLoading: boolean;
	readonly isCheckProxyLoading: boolean;
	readonly isGetCheckedLoading: boolean;
	readonly isLoading: boolean;
	readonly openWalletEditModal: (data: Omit<WalletsEditData, "open">) => void;
	readonly filters: any;
	readonly setFilters: (filters: any) => void;
	readonly paginationInfo: any;
	readonly setSortDirection: (direction: "WALLETS_COUNT_DESC" | "WALLETS_COUNT_ASC" | undefined) => void;
	readonly sortDirection?: "WALLETS_COUNT_DESC" | "WALLETS_COUNT_ASC";
};

export type IDsForDeleteType = DeleteProxyType & { readonly isIPlist?: boolean };

export type ReduceStateType = {
	readonly [key: number]: {
		readonly workingStatus?: "SUCCEEDED" | "FAILED";
		readonly isLoading?: boolean;
	};
};
