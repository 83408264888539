import { ArchivedWalletContext } from "modules/Wallets/contexts/ArchivedWalletContext";
import { Table } from "pay-kit";
import React, { useContext, useState } from "react";
import ReloadButton from "components/ReloadButton";
import RightModal from "components/Modals/RightModal";
import SideModalContent from "modules/Wallets/components/SideModalContent";
import AuthContext from "contexts/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";
import archivedWalletsColumns, { subRow } from "./components/columns";
import WalletsFilter from "../components/Filter";
import TabNav from "../components/TabNav";
import styles from "./ArchivedWallets.module.scss";

const ArchivedWallets: React.FC = () => {
	const { wallets, isLoading, getWallets, paginationInfo, setCurrentPage, limit, setLimit } =
		useContext(ArchivedWalletContext);

	const { hasRole } = useContext(AuthContext);
	const privateAccess = hasRole(Roles.WALLET_NUMBER);
	const balanceAccess = hasRole(Roles.WALLET_BALANCE);
	const counterpartyAccess = hasRole(Roles.COUNTERPARTY_LIST);
	const [walletId, setWalletId] = useState<string | null>(null);

	const tableColumns = archivedWalletsColumns({
		privateAccess,
		balanceAccess,
		setWalletId,
		counterpartyAccess,
	});

	const paginator =
		(paginationInfo && {
			currentPage: paginationInfo?.offset / limit + 1,
			setCurrentPage: (page: number | string) => setCurrentPage(page as number),
			limit,
			setLimit: (newLimit: number) => setLimit(newLimit),
			lastAvailablePage: Math.ceil(paginationInfo?.total / limit) || 1,
			className: styles.paginator,
			bottomPaginatorProps: {
				className: styles.bottomPaginator,
			},
			prefixElement: <ReloadButton data-test-id="" isLoading={false} onClick={() => getWallets()} />,
		}) ||
		undefined;

	return (
		<>
			<div className={styles.topNav}>
				<TabNav />
			</div>

			<WalletsFilter ctx={ArchivedWalletContext} walletsStatus="archived" />

			<RightModal
				bodyClassName={styles.detailedViewWrapper}
				heading="Кошелёк"
				onShow={() => null}
				isVisible={walletId !== null}
				onClose={() => setWalletId(null)}
			>
				<SideModalContent walletId={walletId} />
			</RightModal>

			<Table
				className={[styles.table, styles.archievedWalletsTable].join(" ")}
				rowKey="hash_id"
				data={wallets}
				columns={tableColumns}
				isLoading={isLoading}
				skeleton={{ rowsCount: 10 }}
				subRowRender={subRow}
				paginator={paginator}
			/>
		</>
	);
};

export default ArchivedWallets;
