import { Button } from "pay-kit";
import { FC, ReactElement } from "react";

import styles from "./selectBuilderButton.module.scss";

const SelectBuilderButton: FC<SelectBuilderButtonType> = ({
	children,
	insertItem,
	error
}) => {

	return (
		<>
			<div className={styles.addButtonWrapper}>
				<div className={styles.walletText}>Кошелек</div>
				<Button
					data-test-id="add-wallet"
					variant="text-primary"
					onClick={() => insertItem({ type: undefined }, "insertAfter")}
				>
					+ Добавить
				</Button>
			</div>
			{error && <div className={styles.errorMessage}>{error}</div>}
			{children}
		</>
	);
};

export default SelectBuilderButton;

type SelectBuilderButtonType = {
	readonly children: ReactElement
	readonly error: string
	readonly insertItem: (value: { readonly type: undefined }, pos: string) => void;
}