import { createContext, Dispatch, ReactNode, useContext, useState } from "react";

const LogsListContext = createContext({} as LogsListContextType);
const LogsListContextProvider = ({ children }: { readonly children: ReactNode }) => {
	const [walletId, setWalletId] = useState<number | null>(null);

	const contextValue = {
		setWalletId,
		walletId
	};

	return (
		<LogsListContext.Provider value={contextValue}>
			{children}
		</LogsListContext.Provider>
	);
};

export default LogsListContextProvider;

export const useLogsListContext = () => useContext(LogsListContext);

export type LogsListContextType = {
	readonly setWalletId: Dispatch<number | null>
	readonly walletId: number | null
}