/* eslint-disable react/prop-types */
import styles from "./SelectProxy.module.scss";
import { useState } from "react";
import { TextInput, Button } from "pay-kit";
import ProxyListItem from "../ProxyListItem";
import type { ProxyType } from "../types";

type SelectProxyPropsType = React.FC<{
	readonly proxies: ProxyType[];
	readonly onSelect: (proxies: string[]) => void;
	readonly onCancel: () => void;
}>;

const SelectProxy: SelectProxyPropsType = ({ proxies, onSelect, onCancel }) => {
	const [selectedProxies, setSelectedProxies] = useState<string[]>([]);
	const [searchPhrase, setSearchPhrase] = useState<string>("");

	const columns = [
		{
			title: "Страна",
			dataIndex: "country_code",
			key: "country_code",
		},
		{
			title: "Тип",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "IP",
			dataIndex: "ip",
			key: "ip",
		},
	];

	const prepareSearchStrings = (str: string) => str.replace(/[^a-zA-Z0-9]/g, "").toLocaleLowerCase();

	const searchProxies = (proxies: ProxyType[], searchPhrase: string) =>
		proxies.filter((proxy) => {
			const proxyString = prepareSearchStrings(`${proxy.country_code}${proxy.type}${proxy.ip}${proxy.port}`);
			return proxyString.indexOf(prepareSearchStrings(searchPhrase)) !== -1;
		});

	const selectedCount = selectedProxies.length;

	const foundProxies = searchPhrase !== "" ? searchProxies(proxies, searchPhrase) : proxies;

	const renderFoundProxies = (
		<div className={styles.rows}>
			{foundProxies.map((proxy) => {
				const isSelected = selectedProxies.includes(proxy.id);

				const selectHandler = () =>
					setSelectedProxies((prevState) => {
						const alreadySelected = prevState.includes(proxy.id);

						if (alreadySelected) {
							return prevState.filter((id) => id !== proxy.id);
						} else {
							return [...prevState, proxy.id];
						}
					});

				return (
					<ProxyListItem
						key={proxy.id}
						data={proxy}
						isSelected={isSelected}
						onSelect={selectHandler}
						className={styles.proxyListItem}
					/>
				);
			})}
		</div>
	);

	return (
		<div className={styles.selectProxy}>
			<TextInput
				name="search-phrase"
				placeholder="Поиск"
				value={searchPhrase}
				onChange={(e) => setSearchPhrase(e.target.value)}
			/>
			<div className={styles.header}>
				{columns.map((col) => (
					<div key={col.key}>{col.title}</div>
				))}
				{selectedCount > 0 && (
					<div className={styles.selectActions}>
						Выбрано: {selectedCount}
						<button onClick={() => setSelectedProxies([])}>
							<CloseIcon />
						</button>
					</div>
				)}
			</div>

			{foundProxies.length > 0 && renderFoundProxies}

			{foundProxies.length === 0 && searchPhrase === "" && (
				<NoDataPlaceholder />
			)}

			{foundProxies.length === 0 && searchPhrase !== "" && (
				<NoSearchResultsPlaceholder onSearchReset={() => setSearchPhrase("")} />
			)}

			<div className={styles.actions}>
				<Button variant="secondary" onClick={onCancel}>
					Отмена
				</Button>
				<Button variant="primary" onClick={() => onSelect(selectedProxies)} disabled={selectedProxies.length === 0}>
					Выбрать
				</Button>
			</div>
		</div>
	);
};

const CloseIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.08859 6.21569L6.14645 6.14645C6.32001 5.97288 6.58944 5.9536 6.78431 6.08859L6.85355 6.14645L12 11.293L17.1464 6.14645C17.32 5.97288 17.5894 5.9536 17.7843 6.08859L17.8536 6.14645C18.0271 6.32001 18.0464 6.58944 17.9114 6.78431L17.8536 6.85355L12.707 12L17.8536 17.1464C18.0271 17.32 18.0464 17.5894 17.9114 17.7843L17.8536 17.8536C17.68 18.0271 17.4106 18.0464 17.2157 17.9114L17.1464 17.8536L12 12.707L6.85355 17.8536C6.67999 18.0271 6.41056 18.0464 6.21569 17.9114L6.14645 17.8536C5.97288 17.68 5.9536 17.4106 6.08859 17.2157L6.14645 17.1464L11.293 12L6.14645 6.85355C5.97288 6.67999 5.9536 6.41056 6.08859 6.21569Z"
			fill="#697077"
		/>
	</svg>
);

type NoSearchResultsPlaceholderType = React.FC<{ readonly onSearchReset: () => void }>;

const NoSearchResultsPlaceholder: NoSearchResultsPlaceholderType = ({ onSearchReset }) => (
	<div className={styles.noSearchResultsPlaceholder}>
		<p>По данному запросу не найдено ни одного прокси</p>
		<Button onClick={onSearchReset} variant="text-primary">
			Сбросить поиск
		</Button>
	</div>
);

const NoDataPlaceholder = () => (
	<div className={styles.noDataPlaceholder}>
		<p>Нет доступных прокси</p>
	</div>
);

export default SelectProxy;
