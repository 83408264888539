import { useState, useEffect } from "react";
import { WalletType } from "api/walletGroup";
import Api from "api";
import { asyncDebounce, getWalletsNumbersOptions, getWalletsIdentifierOptions } from "./helpers";
import { SuccessStandardResponse } from "api/types";

/**
 * Позволяет получить список кошельков для селекта в фильтрах
 *
 * TODO: По требованиям этого не нужно, но может потом понадобится:
 *
 * 1. Добавить проп с лимитом
 * 2. Добавить проп со оффсетом
 * 3. Сделать дженерик для данных
 * 4. Добавить проп для функции-фетчера
 * 5. Исправить нейминг на более общий
 * 6. Есть 2 функции loadOptions и loadOptionsWithIdentifiers. Они сейчас отличаются только возвращаемым форматом опций. Их можно в 1 объединить - но нужно быть осторожным с debounce.
 *
 * @returns {{
 *   isLoadingWallets: флаг состояния загрузки,
 *   walletsNumbersOptions: Опции селекта для поиска по hash_id,
 *   walletsIdentifierOptions: Опции селекта для поиска по identifier,
 *   loadOptions: загрузка опций по скроллу в селекте
 *   onMenuScrollToBottom: коллбэк для пропа селекта "onMenuScrollToBottom",
 * }}
 */
export const useLazyLoadWallets = (params: { removed: 0 | 1 }) => {
	const [wallets, setWallets] = useState<WalletType[]>([]);
	const [isLoadingWallets, setIsLoadingWallets] = useState(false);
	const [walletsOffset, setWalletsOffset] = useState(0);
	const [walletsLimit] = useState(10);
	const [paginationData, setPaginationData] = useState<SuccessStandardResponse<never>["paginate"]>({
		total: 0,
		limit: 0,
		offset: 0,
	});

	/**
	 * Загрузка кошельков c учетом пагинации и фильтров
	 */
	const loadWallets = async ({ offset, limit }: { limit: number; offset: number }) => {
		try {
			setIsLoadingWallets(true);
			const res = await Api.wallet.getWallets({ offset, limit, ...params });

			if (res.status === "success") {
				setPaginationData(res.paginate);
				setWallets((prev) => [...prev, ...res.data]);
			}
		} catch (e) {
			console.log(e);
			window.pushAlert({
				description: "Не удалось загрузить кошельки",
				type: "error",
			});
		} finally {
			setIsLoadingWallets(false);
		}
	};

	/**
	 * Загрузка опций для селекта.
	 * Используется в 2 кейсах:
	 * 1. Скролл внутри инпута
	 * 2. Пользователський ввод в селекте для поиска
	 *
	 * @param input Пользовательский ввод идентификатора кошелька.
	 * @returns Список опций для селекта
	 */
	const loadOptions = async (input: string) => {
		try {
			const res = await Api.wallet.getWallets({ identifier: input, limit: 10, offset: 0, ...params });

			if (res.status === "success") {
				setPaginationData(res.paginate);
				return getWalletsNumbersOptions(res.data);
			}
		} catch (e) {
			console.log(e);
			window.pushAlert({
				description: "Не удалось загрузить кошельки",
				type: "error",
			});
		}
	};

	/**
	 * Загрузка опций для селекта.
	 * Используется в 2 кейсах:
	 * 1. Скролл внутри инпута
	 * 2. Пользователський ввод в селекте для поиска
	 *
	 * @param input Пользовательский ввод идентификатора кошелька.
	 * @returns Список опций для селекта
	 */
	const loadOptionsWithIdentifiers = async (input: string) => {
		try {
			const res = await Api.wallet.getWallets({ identifier: input, limit: 10, offset: 0, ...params });

			if (res.status === "success") {
				setPaginationData(res.paginate);
				return getWalletsIdentifierOptions(res.data);
			}
		} catch (e) {
			console.log(e);
			window.pushAlert({
				description: "Не удалось загрузить кошельки",
				type: "error",
			});
		}
	};

	/**
	 * Коллбэк для селекта. Вызывается при скролле вниз в селекте
	 */
	const onMenuScrollToBottom = () => {
		if (paginationData?.total <= paginationData?.limit + paginationData?.offset) {
			return;
		}

		setWalletsOffset((prev) => prev + 10);
	};

	useEffect(() => {
		loadWallets({ limit: walletsLimit, offset: walletsOffset });
	}, [walletsOffset]);

	const debouncedLoadOptions = asyncDebounce(loadOptions, 500);

	return {
		isLoadingWallets,
		walletsNumbersOptions: getWalletsNumbersOptions(wallets),
		walletsIdentifierOptions: getWalletsIdentifierOptions(wallets),
		loadOptions: debouncedLoadOptions,
		loadOptionsWithIdentifiers,
		onMenuScrollToBottom,
	};
};
